<template class="login">
  <div class="login-screen">
    <el-main class="login-main">
      <div class="header-login">
        <img class="logo" :src="logo" alt="logo">
      </div>
      <div class="title">
        <p>Sign in to dashboard</p>
      </div>
      <div class="container-login">
        <template>
          <el-form
            label-position="top"
            :model="preLoginForm"
            status-icon
            :rules="rules"
            ref="preLoginForm"
            label-width="120px"
            class="demo-preLoginForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="Username" prop="email">
              <el-input type="email" v-model="preLoginForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input
                type="password"
                v-model="preLoginForm.password"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <p
              class="invalid-credentials"
              v-if="validPreLoginForm">Verify your username or password.
            </p>

            <el-form-item>
              <el-button
                class="btn-primary"
                :loading="loading"
                type="info"
                @click="preSignIn('preLoginForm')"
                @keypress="preSignIn('preLoginForm')"
              >
                Login
              </el-button>
            </el-form-item>
          </el-form>
        </template>
      </div>
      <a @click="goToRecoveryPassword" @keypress="goToRecoveryPassword">Forgot Password</a>
    </el-main>

    <verify-two-fa-modal
      v-if="showVerifyTwoFaModal"
      :showModal="showVerifyTwoFaModal"
      :data="dataLogin"
      title="2FA AUTHENTICATION"
    >
    </verify-two-fa-modal>

  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import logo from '@/assets/milo_icon.png';
import VerifyTwoFaModal from '@/components/Modals/VerifyTwoFaModal.vue';
import ApiSSO from '../../lib/ApiSSO';

export default {
  name: 'AuthView',
  components: {
    VerifyTwoFaModal,
  },
  data() {
    return {
      showVerifyTwoFaModal: false,
      dataLogin: {},
      logo,
      preLogin: false,
      loginId: '',
      loading: false,
      validPreLoginForm: false,
      validForm: false,
      preLoginForm: {
        email: '',
        password: '',
        applicationID: process.env.VUE_APP_ID_SSO,
      },
      rules: {
        email: [
          {
            type: 'email',
            required: true,
            message: 'Please enter your user email.',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Please enter your password.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setPermissions', 'setRefreshToken']),
    preSignIn(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.loginUser();
        }
        this.loading = false;
        return false;
      });
    },
    loginUser() {
      ApiSSO.post('/authenticate', this.preLoginForm).then((r) => {
        if (r) {
          if (r.success) {
            this.loading = false;
            const { accessToken, refreshToken, permissions } = r.data;
            const user = this.$jwt.decode(accessToken).data;

            if (user.confirmedTwoFA) {
              this.dataLogin = {
                user,
                permissions,
                accessToken,
                refreshToken,
              };

              this.showVerifyTwoFaModal = true;
            } else {
              this.setUser(user);
              this.setPermissions(permissions);
              this.setToken(accessToken);
              this.setRefreshToken(refreshToken);

              this.$router.push({ path: '/' });
            }
          } else {
            this.$message({
              message: r.message,
              type: 'warning',
            });
          }

          this.loading = false;
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
        this.validPreLoginForm = true;
      });
    },
    goToRecoveryPassword() {
      const url = btoa(process.env.VUE_APP_URL);
      window.location.href = `${process.env.VUE_APP_SSO_RECOVERY_PASSWORD}?url=${url}`;
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push({ path: '/' });
    }
  },
};
</script>
<style lang="scss" scoped>
.login-main {
  .header-login {
    .logo {
      width: 190px;
      height: auto;
    }
  }

  .title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #313131;
  }

  .container-login {
    el-form-item {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #313131;
      letter-spacing: -0.01em;
    }
  }

  a {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 0 auto;
    letter-spacing: -0.01em;
    color: #313131;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
