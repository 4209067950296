<template>
  <div class="widget-us-map">
    <div v-if="loading" v-loading="loading"></div>
    <template v-else>
      <div class="elements">
        <div class="content-tooltip">
          <h1>{{label}}</h1>
          <el-tooltip
            v-if="tooltipContent.length > 0"
            popper-class="content-tooltip-width"
            class="item"
            effect="dark"
            :content="tooltipContent"
            placement="bottom">
            <img :src="`${URLSITE}/img/circle-question.svg`" alt="tooltip" />
          </el-tooltip>
        </div>
        <div
          v-if="show_hide_filter_geographical_location && locations.length > 0"
          class="filter_location">
          <div class="label">
            <span>Change Map:</span>
          </div>
          <el-select
            clearable
            filterable
            size="mini"
            @change="handleLocation"
            v-model="codeLocation"
            placeholder="Select location"
          >
            <el-option
              v-for="item in locations ?? []"
              :key="item.id"
              :label="item.name ?? item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <GChart
        v-if="renderComponent && renderComponent2"
        v-loading="true"
        :settings="settings"
        :type="chartType"
        :data="chartData"
        :options="chartOptions"
      />
    </template>
  </div>
</template>

<script>
import Utils from '@/lib/Utils';
import ApiMILO from '@/lib/ApiMILO';
import { GChart } from 'vue-google-charts/legacy';

export default {
  name: 'WidgetUsMap',
  props: ['config', 'brandSettings', 'renderComponent2', 'initialFilterConfig'],
  components: {
    GChart,
  },
  data() {
    return {
      data: null,
      chartType: 'GeoChart',
      defaultResponse: [
        ['Country', 'Popularity'],
      ],
      settings: {
        packages: ['geochart'],
      },
      loading: true,
      apiConfig: {},
      automaticListenerFunction: null,
      externalListenerFunction: null,
      locations: [],
      codeLocation: '',
      region: 'US',
      renderComponent: true,
      URLSITE: process.env.VUE_APP_URL_SITE,
    };
  },
  computed: {
    tooltipContent() {
      return Utils.getSetting('general', 'tooltip', this.config.widget_settings_categories);
    },
    chartOptions() {
      const config = {
        displayMode: 'regions',
        resolution: this.region !== 'world' ? 'provinces' : 'auto',
        region: this.region ?? 'US',
        // region: 'world',
        height: this.height ?? 600,
        colorAxis: {
          colors: [],
        },
        backgroundColor: this.default_widget_background,
      };
      return config;
    },
    default_widget_background() {
      return Utils.getReportBrandSetting('default_widget_background', this.brandSettings) ?? 'transparent';
    },
    dataSource() {
      return Utils.getSetting('data', 'dataSource', this.config.widget_settings_categories);
    },
    label() {
      return Utils.getSetting('general', 'label', this.config.widget_settings_categories);
    },
    geographicalLocation() {
      let val = Utils.getSetting('general', 'show_geographical_location', this.config.widget_settings_categories).toUpperCase();
      if (val.length > 0) {
        if (val === 'WORLD') {
          val = val.toLowerCase();
        }
        return val;
      }
      return 'US';
    },
    show_hide_filter_geographical_location() {
      return Utils.getSetting('general', 'show/hide_filter_geographical_location', this.config.widget_settings_categories);
    },
    query_filter_geographical_location() {
      return Utils.getSetting('general', 'query_filter_geographical_location', this.config.widget_settings_categories);
    },
    height() {
      return Utils.getSetting('general', 'height', this.config.widget_settings_categories);
    },
    widgetColor() {
      const color = Utils.getSetting('data', 'widget_color', this.config.widget_settings_categories);

      if (!color) {
        const defaultColor = Utils.getReportBrandSetting('default_widget_color', this.brandSettings);
        return [defaultColor];
      }

      return color.split('||');
    },
    chartData() {
      if (!this.data) {
        return this.defaultResponse;
      }
      const { data } = this.data;

      if (!data) {
        return this.defaultResponse;
      }

      const key = Utils.getSetting('data', 'key (column)', this.config.widget_settings_categories);
      const value = Utils.getSetting('data', 'value (column)', this.config.widget_settings_categories);

      if (!key || !value) {
        return this.defaultResponse;
      }

      const result = data.map((row) => {
        const arr = [row[key], row[value]];

        return arr;
      });

      result.unshift([key, value]);

      return result;
    },
  },
  methods: {
    async getWidgetData() {
      try {
        const { id } = this.config;
        this.apiConfig.apiUrl = `/widgets/${id}/data?`;

        const apiUrl = Utils.queryStringGenerator(this.apiConfig);
        const result = await ApiMILO.get(apiUrl);

        const { success } = result;

        if (!success) {
          return this.defaultResponse;
        }

        return result;
      } catch (error) {
        console.error(error);
        return this.defaultResponse;
      } finally {
        this.loading = false;
      }
    },
    async getLocations() {
      try {
        const result = await ApiMILO.post(
          '/report/getLocationsFromQuery',
          {
            query: this.query_filter_geographical_location,
            dataSource: this.dataSource,
          },
        );

        return Object.values(result.data);
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async reloadData(conf) {
      if (conf.type === 'dropDown' || conf.type === 'check') {
        let fields = [];
        let s = '';
        if (conf.columns) {
          fields = conf.columns;
          if (Array.isArray(fields) && conf.value) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }
          this.apiConfig[conf.type] = s;
          this.data = await this.getWidgetData();
        }
      }

      if (conf.type === 'search') {
        const search = await Utils.getSetting('filters', 'search', this.config.widget_settings_categories);
        if (!search || search.length === 0) {
          console.warn('No valid search column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (search) {
          fields = search.split('||');
          if (Array.isArray(fields) && conf.value.length > 0) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }

          this.apiConfig.search = s;
          this.data = await this.getWidgetData();
        }
      }

      if (conf.type === 'dateRange') {
        const dateRange = Array.isArray(conf.value) ? conf.value.toString().replaceAll(',', '&&') : '';

        let dateRangeColumns = await Utils.getSetting('filters', 'date_range', this.config.widget_settings_categories);
        dateRangeColumns = conf?.columns ?? dateRangeColumns;
        if (!dateRangeColumns || dateRangeColumns.length === 0) {
          console.warn('No valid dateRange column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (dateRangeColumns) {
          if (!Array.isArray(dateRangeColumns)) {
            fields = dateRangeColumns.split('||');
          }
          if (conf?.columns) {
            fields = [...new Set([...fields, ...conf.columns])];
          } else {
            fields = [...new Set([...fields])];
          }

          if (Array.isArray(fields) && dateRange.length > 0) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${dateRange}${separator}`;

              return field;
            });
          }

          this.apiConfig.dateRange = s;
        }
        this.data = await this.getWidgetData();
      }

      if (conf.type === 'optIn') {
        const optIn = await Utils.getSetting('filters', 'opt_in', this.config.widget_settings_categories);
        if (!optIn || optIn.length === 0) {
          console.warn('No valid optIn column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (optIn) {
          fields = optIn.split('||');
          if (Array.isArray(fields) && conf.value) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }

          this.apiConfig.optIn = s;
          this.data = await this.getWidgetData();
        }
      }
    },
    async setupAutomaticRefresh() {
      const allow = Utils.getSetting('events', 'allow_automatic_refresh', this.config.widget_settings_categories);
      if (allow) {
        this.automaticListenerFunction = async () => {
          await this.getWidgetData();
        };
        this.$root.$on('reload-widget-data', this.automaticListenerFunction);
      }
    },
    async setupExternalEventListener() {
      const allow = Utils.getSetting('events', 'allow_external_refresh', this.config.widget_settings_categories);
      if (allow) {
        const self = this;
        this.externalListenerFunction = async function reload() {
          await self.getWidgetData();
        };
        this.$listen(window, 'reload-milo', this.externalListenerFunction);
      }
    },
    handleLocation(value) {
      if (!value) {
        this.region = this.geographicalLocation;
      } else {
        this.region = value;
      }
      this.forceRerender();
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
  },
  beforeDestroy() {
    try {
      if (this.automaticListenerFunction != null) {
        this.$root.$off('reload-widget-data', this.automaticListenerFunction);
      }
      this.$root.$off('applyFilters', this.reloadData);
    } catch (e) {
      console.error(e);
    }
  },
  async mounted() {
    this.region = this.geographicalLocation;
    this.apiConfig.orderBy = await Utils.getSetting('data', 'orderBy', this.config.widget_settings_categories);
    this.apiConfig.groupBy = await Utils.getSetting('data', 'groupBy', this.config.widget_settings_categories);

    if (this.initialFilterConfig) {
      this.reloadData(this.initialFilterConfig);
    } else {
      this.data = await this.getWidgetData();
    }
    this.$emit('dataRefresh', this.config.id);

    this.chartOptions.colorAxis.colors = this.widgetColor;
    this.locations = await this.getLocations();

    this.$root.$on('applyFilters', this.reloadData);
    this.setupAutomaticRefresh();
    this.setupExternalEventListener();
  },
};
</script>

<style lang="scss">
.widget-us-map{
  .elements {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .filter_location{
      display: flex;
      align-items: center;
      .label {
        width: 120px;
        span{
          font-size: 12px;
          font-weight: bold;
        }

      }
    }
  }
}
</style>
