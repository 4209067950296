import Vue from 'vue';
import VueRouter from 'vue-router';
import SSOApi from '@debrain/sso-api-integration';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/brands',
    name: 'Brands',
    component: () => import(/* webpackChunkName: "Brands" */ '../views/brands/BrandsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/brands/:brandId/reports',
    name: 'Reports',
    props: true,
    component: () => import(/* webpackChunkName: "Reports" */ '../views/reports/ReportsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports/:slug/edit',
    name: 'ReportEdit',
    props: true,
    component: () => import(/* webpackChunkName: "ReportsEdit" */ '../views/reports/ReportEdit.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports/:slug',
    name: 'ReportDetail',
    props: true,
    component: () => import(/* webpackChunkName: "ReportsEdit" */ '../views/reports/ReportDetail.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/brands/:brandId/dataSources',
    name: 'DataSources',
    component: () => import(/* webpackChunkName: "dataSourceView" */ '../views/dataSource/DataSourceView.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/brands/:brandId',
    name: 'BrandEdit',
    component: () => import(/* webpackChunkName: "BrandEdit" */ '../views/brands/BrandEdit.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "About" */ '../views/AboutView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Login" */ '../views/login/AuthView.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '../views/user/ProfileView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/token',
    name: 'Token',
    component: () => import(/* webpackChunkName: "Token" */ '../views/AuthToken.vue'),
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },

];

const router = new VueRouter({
  mode: store.getters.isEmbed ? 'abstract' : 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  const witheList = ['http://localhost:5501', 'https://helloprobability.io'];
  const { origin } = window.location;
  // console.log('🚀 ------------------------------------------------------------🚀');
  // console.log('🚀 ~ file: index.js:98 ~ router.beforeEach ~ origin:', origin);
  // console.log('🚀 ------------------------------------------------------------🚀');

  const requireAuth = witheList.includes(origin);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const session = store.getters.isLoggedIn;
    const embed = store.getters.isEmbed;

    if ((!session || session === null) && !embed && (requireAuth < 1)) {
      // next({
      //   path: '/login',
      //   query: { redirect: to.fullPath },
      // });
      SSOApi.configure({
        appId: process.env.VUE_APP_ID_SSO,
        apiKey: process.env.VUE_APP_API_KEY,
        baseSSOURL: process.env.VUE_APP_BASE_SSO_URL,
        baseSSOAPIURL: process.env.VUE_APP_API_SSO,
      });

      SSOApi.redirectToLoginURL({
        state: window.btoa(from.fullPath),
        redirecturl: process.env.VUE_APP_REDIRECT_URL,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
