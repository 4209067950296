<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="brand-modal">
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      append-to-body
      :before-close="handleClose">
      <div class="dialog-footer content-buttons">
        <el-button v-if="create"
                   class="btn-secondary"
                   :loading="loading"
                   type="info"
                   @click="createBrand('createBrandForm')"
                   @keypress="createBrand('createBrandForm')"
        >
          Create
        </el-button>
        <div v-if="settings" class="brand-modal-buttons">
          <el-button
            class="btn-secondary"
            :loading="loading"
            type="info"
            @click="settingBrand('settingsBrandForm')"
            @keypress="settingBrand('settingsBrandForm')"
            :disabled="loading || uploading"
          >
            Update
          </el-button>

          <el-button
            v-if="user && isValidPermission('DATASOURCE_LIST')"
            class="btn-secondary"
            type="info"
            @click="goToDataSources"
            @keypress="goToDataSources"
            :disabled="loading || uploading"
          >
            Configure Data Sources
          </el-button>
        </div>
      </div>
      <div :class="settings ? 'content-modal-brand scroll-gray' : ''">
        <template v-if="create">
          <el-form
            label-position="top"
            :model="createBrandForm"
            status-icon
            :rules="rules"
            ref="createBrandForm"
            label-width="120px"
            class="demo-ruleForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="Name" prop="name">
              <el-input
                maxlength="20"
                show-word-limit
                type="text"
                v-model="createBrandForm.name"
                autocomplete="off">
              </el-input>
            </el-form-item>
          </el-form>
        </template>
        <template v-if="settings">
          <el-form
            label-position="top"
            :model="settingsBrandForm"
            status-icon
            :rules="rulesSettings"
            ref="settingsBrandForm"
            label-width="120px"
            class="demo-ruleForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="Show/Hide" prop="visible">
              <el-switch
              active-color="#3FB5E5"
              inactive-color="#CBCBCB"
              :active-value=1
              :inactive-value=0
              v-model="settingsBrandForm.visible"></el-switch>
            </el-form-item>

            <el-form-item label="Is Template" prop="isTemplate">
              <el-switch
              active-color="#3FB5E5"
              inactive-color="#CBCBCB"
              :active-value=1
              :inactive-value=0
              v-model="settingsBrandForm.isTemplate"></el-switch>
            </el-form-item>

            <el-form-item label="Brand Name" prop="name">
              <el-input type="text" v-model="settingsBrandForm.name" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="Brand Logo" prop="logo">
              <custom-upload v-model="settingsBrandForm.logo"></custom-upload>
            </el-form-item>
            <div v-for="settingCategory in settingsBrandForm.setting_categories"
                 :key="settingCategory.uuid"
            >
              <el-divider
                content-position="center"
                class="mb-4 mt-4"
              >{{ settingCategory.name.toUpperCase() }}
              </el-divider>
              <el-form-item v-for="brandSetting in settingCategory.brand_settings"
                            :key="brandSetting.uuid"
                            :label="Utils.capitalizeString(brandSetting.name)"
                            :prop="brandSetting.name">

                <template>
                  <template v-if="brandSetting.input_type === 'upload'">
                    <custom-upload v-model="brandSetting.value"></custom-upload>
                  </template>

                  <div v-else-if="brandSetting.input_type === 'color_picker'"
                       class="color-picker"
                  >
                    <el-color-picker v-model="brandSetting.value"></el-color-picker>
                    <div class="color-text">
                      <span>{{ brandSetting.value }}</span>
                    </div>
                  </div>
                  <template
                    v-else-if="brandSetting.input_type === 'select'"
                  >
                    <el-select
                      clearable
                      filterable
                      v-model="brandSetting.value"
                      placeholder="Select"
                    >
                      <el-option
                        v-for="item in selectOptions[
                          brandSetting.name
                        ] ?? []"
                        :key="item.id"
                        :label="item.name ?? item.title"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </template>

                  <template v-else-if="brandSetting.input_type === 'text'">
                    <el-input
                      type="text"
                      v-model="brandSetting.value"
                      autocomplete="off"
                    ></el-input>
                  </template>
                </template>

              </el-form-item>
            </div>
          </el-form>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import CustomUpload from '@/components/Uploads/CustomUpload.vue';
import { mapState, mapGetters } from 'vuex';
import Utils from '@/lib/Utils';
import ApiMILO from '../../lib/ApiMILO';

export default {
  name: 'BrandModal',
  components: {
    CustomUpload,
  },
  props: ['showModal', 'title', 'create', 'settings', 'brandId'],
  data() {
    return {
      Utils,
      uploading: false,
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      createBrandForm: {
        name: '',
      },
      settingsBrandForm: {
        visible: false,
      },
      rules: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter brand name.',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 20,
            message: 'Should be max 20 characters',
            trigger: 'blur',
          },
        ],
      },
      rulesSettings: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter brand name.',
            trigger: 'blur',
          },
        ],
      },
      selectOptions: {},
      themes: [
        {
          id: 'probability',
          name: 'Probability',
        }, {
          id: 'default',
          name: 'Default',
        },
      ],
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
        this.$root.$emit('removeFiles');
      }
    },
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isValidPermission']),
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
      this.$root.$emit('removeFiles');
    },
    createBrand(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.sendCreate();
        }
        this.loading = false;
        return false;
      });
    },
    sendCreate() {
      ApiMILO.post('/brand', this.createBrandForm).then(async (r) => {
        if (r) {
          if (r.success) {
            this.loading = false;
            this.dialogVisible = false;
            this.resetForm('createBrandForm');

            // const parameters = {
            //   name: `Brand ${r.data.name}`,
            //   identifier: `BRAND_${r.data.id}`,
            //   applicationID: this.user.applicationId,
            //   father: 'BRAND',
            //   userId: this.user.id,
            // };

            const { permission } = r.data;
            await Utils.afterCreatePermission(permission.data);

            this.$root.$emit('reloadBrands');
          }

          if (r.status === 422) {
            this.$message({
              message: r.message,
              type: 'warning',
            });
          }
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getBrand() {
      ApiMILO.getOne(`/brand/${this.brandId}`).then((r) => {
        if (r) {
          if (r.success) {
            this.settingsBrandForm = r.data;
          }
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },
    async handleDeleteImage(path) {
      const url = '/upload/remove';
      await ApiMILO.post(url, { path });
    },
    async settingBrand(formName) {
      this.loading = true;

      try {
        this.loading = true;
        const valid = await this.$refs[formName].validate();
        if (valid) {
          await this.sendSettings();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async sendSettings() {
      try {
        this.loading = true;
        const r = await ApiMILO.put(`/brand/${this.brandId}`, this.settingsBrandForm);
        if (r.success) {
          this.dialogVisible = false;
          this.resetForm('settingsBrandForm');
          this.$root.$emit('reloadBrands');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    goToDataSources() {
      const brandID = this.brandId;
      this.$router.push({ path: `/brands/${brandID}/dataSources` });
    },
  },
  mounted() {
    if (this.brandId) {
      this.getBrand();
      this.$set(this.selectOptions, 'theme', this.themes);
    }
  },
};
</script>

<style lang="scss">

// .brand-modal {

//   max-width: 585px;

//   .el-dialog__title {
//     color: #214BEB !important;
//     font-size: 20px !important;
//     font-weight: bold !important;
//   }

//   .el-dialog__body {
//     padding: 30px 55px;
//   }

// }
.el-dialog{
  padding: 40px 50px;
  width: 80% !important;
  border-radius: 8px;
  height: auto;
  margin-top: 8vh !important;

  @media (min-width: 720px){
    width: 40% !important;
  }
  .el-dialog__header{
    padding-top: 0;
    .el-dialog__title{
      font-family: 'Work Sans' !important;
    }
  }

  .el-dialog__body{
    padding: 0;
  }
}
.content-modal-brand{
  height: 500px;
  overflow-y: scroll;
  padding: 0 40px;
  @media (max-width: 424px) {
    height: 400px;
  }

  .demo-ruleForm{
    .el-form-item {
      font-family: 'Work Sans' !important;
      margin-bottom: 0;
    }

    .el-form-item__label{
      padding-bottom: 0;
    }

    .el-divider__text{
      font-family: 'Work Sans' !important;
    }
  }
}
.brand-modal-buttons {
  display: flex;
  align-content: center;
  justify-content: center;
  @media (max-width: 424px) {
    flex-direction: column;
    button {
      margin-bottom: 10px;
    }
  }
}
.content-buttons {
  margin-bottom: 20px;
}

.el-select-dropdown.el-popper {
  z-index: 9999 !important;
}
</style>
