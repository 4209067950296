<template>
  <div class="normal-table">
    <el-table
      v-loading="loading"
      :data="data"
      :row-class-name="tableRowClassName"
      :default-sort="{ prop: 'id', order: 'descending' }"
      @sort-change="orderby"
    >
      <el-table-column v-if="firstColumn.show">
        <template>
          <i class="first-icon-table mdi" :class="firstColumn.icon"></i>
        </template>
      </el-table-column>
      <template v-for="(column, key) in tableColumns">
        <el-table-column
          v-if="column.format"
          :key="`column-${key}`"
          :prop="column.prop"
          :label="column.label"
          :sortable="column.sortable"
          width="150"
        >
          <template slot-scope="scope">
            <span>{{ format(column.format, scope.row.createdAt) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="`column2-${key}`"
          :prop="column.prop"
          :label="column.label"
          :sortable="column.sortable"
          width="150"
        >
        </el-table-column>
      </template>
    </el-table>

    <template v-if="!loading && this.$attrs?.columnsTotal.length > 0">
      <el-table :show-header="false" :data="tableTotals" style="width: 100%">
        <template v-for="(column, key) in tableColumns">
          <el-table-column
            :key="`columnt-${key}`"
            :prop="column.prop"
            :label="column.prop"
          >
          </el-table-column>
        </template>
      </el-table>
    </template>
    <!--
    <template v-if="!loading && this.$attrs?.columnsAverage.length > 0">
      <el-table :show-header="false" :data="tableAverage" style="width: 100%">
        <template v-for="(column, key) in tableColumns">
          <el-table-column
            :key="`columna-${key}`"
            :prop="column.prop"
            :label="column.prop"
          >
          </el-table-column>
        </template>
      </el-table>
    </template>
    -->
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'NormalTable',
  components: {},
  props: ['data', 'tableColumns', 'loading', 'firstColumn'],
  data() {
    return {
      tableTotals: [],
      tableAverage: [],
    };
  },
  methods: {
    getTotals() {
      const sums = {};
      const data = [];

      if (this.$attrs?.columnsTotal) {
        this.tableColumns.forEach((column, index) => {
          if (index === 0) {
            sums[column.prop] = 'TOTAL';
            return;
          }

          // eslint-disable-next-line max-len
          // const columnObj = this.$attrs?.columnsTotal.find((el) => column.prop === Object.keys(el)[0]);
          // const exists = hasOwnProperty;

          this.$attrs?.columnsTotal.forEach((el, i2) => {
            if (column.prop === Object.keys(el)[0]) {
              sums[column.prop] = this.$attrs?.columnsTotal[i2][column.prop]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          });
        });
        data.push(sums);
      }
      return data;
    },
    getAverages() {
      const sums = {};
      const data = [];

      if (this.$attrs?.columnsAverage) {
        this.tableColumns.forEach((column, index) => {
          if (index === 0) {
            sums[column.prop] = 'AVERAGE';
            return;
          }

          this.$attrs?.columnsAverage.forEach((el, i2) => {
            if (column.prop === Object.keys(el)[0]) {
              sums[column.prop] = this.$attrs?.columnsAverage[i2][column.prop]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          });
        });
        data.push(sums);
      }

      return data;
    },
    tableRowClassName({ rowIndex }) {
      /* eslint-disable */
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    handleView(index, row) {
      // console.log(index, row);
      this.$root.$emit("viewRow", row);
    },
    handleEdit(index, row) {
      this.$root.$emit("editRow", { id: row.id, type: this.$route.name });
    },
    handleHide(index, row) {
      // console.log(index, row);
      this.$confirm(
        "This will permanently hide the datasource. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          closeOnClickModal: false,
        }
      )
        .then(() => {
          this.$root.$emit("hideRow", row.id);
        })
        .catch(() => {});
    },
    handleDelete(index, row) {
      // console.log(index, row);
      this.$confirm(
        "This will permanently delete the datasource. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          closeOnClickModal: false,
        }
      )
        .then(() => {
          this.$root.$emit("deleteRow", row.id);
        })
        .catch(() => {});
    },
    format(type, item) {
      if (type === "fromNow") {
        return moment(item).fromNow();
      }
    },
    orderby(val) {
      this.$emit("sortChange", val);
    },
  },
  watch: {
    data(newValue) {
      if(!newValue) return;
      if(this.$attrs?.columnsTotal || this.$attrs?.columnsAverage) {
        const tableTotals = this.getTotals();
        // const tableAverage = this.getAverages();
        this.tableTotals = tableTotals;
        // this.tableAverage = tableAverage;
      }
    },
  },
  mounted(){
    if(this.$attrs?.columnsTotal.length > 0 || this.$attrs?.columnsAverage.length > 0) {
        const tableTotals = this.getTotals();
        const tableAverage = this.getAverages();
        this.tableTotals = tableTotals;
        this.tableAverage = tableAverage;
      }
  }
};
</script>
<style lang="scss">
.normal-table {
}
</style>
