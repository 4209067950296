import axios from 'axios';
import store from '@/store';

export default {
  async setBase() {
    axios.defaults.headers.common.token = process.env.VUE_APP_API_KEY_SSO;
    axios.defaults.baseURL = process.env.VUE_APP_API_SSO;
  },
  async getToken() {
    if (store.getters.isLoggedIn && store.getters.isRefreshToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${store.state.token}`;
      axios.defaults.headers.common.Permissions = null;
    } else {
      axios.defaults.headers.common.Authorization = null;
      axios.defaults.headers.common.Permissions = null;
    }
  },
  // CRUD methods----------------------------------------------------------------------
  async get(path) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .get(path);
      const {
        data,
      } = response || {};

      const result = {
        success: data.success,
        data: data ? ({ ...data.data.data } || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },
  async get2(path) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .get(path);
      const {
        data,
      } = response || {};

      const result = {
        success: data.success,
        data: data ? ({ ...data.data.rows } || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },
  async post(path, payload) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .post(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: data.success,
        data: data ? ({ ...data.data } || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.message ?? e.message,
      };
    }
  },

  async put(path, payload) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .put(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async patch(path, payload) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .patch(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async delete(path) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .delete(path);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : true,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },
};
