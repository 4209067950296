<template>
  <div class="principal-bar">
    <div class="title">
      <h1>{{title}}</h1>
    </div>
    <div class="button">
      <el-button
      v-for="btn in buttonsList"
      :key="btn.uuid"
      class="fw-bold"
      :class="btn?.primary ? 'btn-primary' : 'btn-secondary'"
      @click="action(btn)"
      size="medium"><i :class="`mdi ${btn.icon}`"></i> {{ btn.text }}</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PrincipalBar',
  props: ['title', 'routeName', 'buttonsList'],
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    action(conf) {
      if (conf.actionType === 'showModalCreate') {
        this.$root.$emit('openModalCreate');
      }
      if (conf.actionType === 'showModalCreateFromTemplate') {
        this.$root.$emit('openModalCreateFromTemplate');
      }
      if (conf.actionType === 'goToRoute') {
        this.$router.push({ name: conf.routeName });
      }
      if (conf.actionType === 'goToRouteBlank') {
        window.open(conf.routeName, '_blank').focus();
      }
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss">
  .principal-bar {
    display: flex;
    align-items: center;
    grid-template-columns: 1fr ;
    grid-row: auto;
    align-items: center;
    justify-content: start;

    @media (min-width: 720px){
    grid-template-columns: 1fr;
  }

    .title {
      h1{
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: #3FB5E5;
        margin: 0;
      }
    }

    .button{
      display: grid;
      row-gap: 5px;

      @media (min-width: 720px){
        justify-self: end;
        grid-template-rows: auto;
        grid-template-columns: auto auto;
        column-gap: 5px;
      }
    }
  }
</style>
