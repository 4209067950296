import chroma from 'chroma-js';
import store from '@/store';
import jwtDecode from 'jwt-decode';
// import ApiSSO from './ApiSSO';

export default {

  getSetting(category, setting, settingArray) {
    const categoryFound = settingArray?.find(
      (cat) => cat.name.toLowerCase().trim() === category.toLowerCase().trim(),
    );

    if (!categoryFound) {
      return null;
    }

    const arraySettings = categoryFound?.widget_settings ?? categoryFound?.report_settings;

    const settingFound = arraySettings.find(
      (set) => set.name.toLowerCase().trim() === setting.toLowerCase().trim(),
    );
    // if (category === 'embedding') {
    //   debugger;
    // }

    if (!settingFound) {
      return null;
    }

    if (settingFound.input_type === 'boolean') {
      return settingFound.value === 'true';
    }

    if (settingFound.input_type === 'integer') {
      const val = parseInt(settingFound.value, 10);
      if (Number.isInteger(val)) {
        settingFound.value = val;
      }
    }

    if (settingFound.input_type === 'select') {
      const val = parseInt(settingFound.value, 10);
      if (Number.isInteger(val)) {
        settingFound.value = val;
      }
    }

    return settingFound.value;
  },

  getSettingBrand(category, setting, settingArray) {
    const categoryFound = settingArray.find(
      (cat) => cat.name.toLowerCase().trim() === category.toLowerCase().trim(),
    );

    if (!categoryFound) {
      return null;
    }

    const arraySettings = categoryFound?.widget_settings ?? categoryFound?.brand_settings;

    const settingFound = arraySettings.find(
      (set) => set.name.toLowerCase().trim() === setting.toLowerCase().trim(),
    );
    // if (category === 'embedding') {
    //   debugger;
    // }

    if (!settingFound) {
      return null;
    }

    if (settingFound.input_type === 'boolean') {
      return settingFound.value === 'true';
    }

    if (settingFound.input_type === 'integer') {
      const val = parseInt(settingFound.value, 10);
      if (Number.isInteger(val)) {
        settingFound.value = val;
      }
    }

    // if (settingFound.input_type === 'select') {
    //   const val = parseInt(settingFound.value, 10);
    //   if (Number.isInteger(val)) {
    //     settingFound.value = val;
    //   }
    // }

    return settingFound.value;
  },

  getReportBrandSetting(setting, settingArray) {
    const settingFound = settingArray.find(
      (set) => set.name.toLowerCase().trim() === setting.toLowerCase().trim(),
    );

    if (!settingFound) {
      return null;
    }

    if (settingFound.input_type === 'boolean') {
      return settingFound.value === 'true';
    }

    if (settingFound.input_type === 'integer') {
      return +settingFound.value;
    }

    if (settingFound.input_type === 'select') {
      const val = parseInt(settingFound.value, 10);
      if (Number.isInteger(val)) {
        settingFound.value = val;
      }
    }

    return settingFound.value;
  },

  numberWithCommas(x, separator) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  },

  queryStringGenerator(args) {
    const config = { ...args };

    const objParams = {};

    if (config.page) {
      objParams.page = config.page;
    }

    if (config.pageSize) {
      objParams.page_size = config.pageSize;
    }

    if (config.search) {
      objParams.search = config.search;
    }

    if (config.groupBy) {
      objParams.group_by = config.groupBy;
    }

    if (config.orderBy) {
      objParams.order_by = config.orderBy;
    }
    if (config.columnsTotal) {
      objParams.columnsTotal = config.columnsTotal;
    }
    if (config.columnsAverage) {
      objParams.columnsAverage = config.columnsAverage;
    }

    if (config.dateRange) {
      objParams.date_range = config.dateRange;
    }

    if (config.optIn) {
      objParams.opt_in = config.optIn;
    }
    if (config.dropDown) {
      objParams.dropDown = config.dropDown;
    }
    if (config.check) {
      objParams.check = config.check;
    }

    const searchParams = new URLSearchParams(objParams);

    return `${config.apiUrl}${searchParams.toString()}`;
  },

  capitalizeString(data) {
    let str = data.replaceAll('_', ' ');
    str = str.toLowerCase();
    return str.split(' ').map((i) => {
      if (i.length > 2) {
        return i.charAt(0).toUpperCase() + i.slice(1);
      }

      return i;
    }).join(' ');
  },
  getScaleColor(color, steps) {
    const colorList = [];
    const colors = [];
    const userColor = color;
    const chromaColor = chroma(userColor);
    for (let i = 0; i < steps; i += 1) {
      colorList[i] = chromaColor.brighten(i * -0.5);
    }

    for (let j = 0; j < colorList.length; j += 1) {
      colors[j] = colorList[j];
    }
    return colors;
  },
  async afterCreatePermission(data) {
    const { accessToken, refreshToken, permissions } = data;
    const user = jwtDecode(accessToken).data;
    store.commit('setUser', user);
    store.commit('setToken', accessToken);
    store.commit('setPermissions', permissions);
    store.commit('setRefreshToken', refreshToken);
  },
  loadScript(url) {
    return new Promise((resolve, reject) => {
      try {
        const script = document.createElement('script');
        script.src = url;

        script.onload = () => {
          resolve(true);
        };

        // append and execute script
        document.documentElement.firstChild.appendChild(script);
      } catch (error) {
        reject(error);
      }
    });
  },
  loadCSS(url) {
    // Create new link Element
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    document.getElementsByTagName('HEAD')[0].appendChild(link);
  },
  searchForCss(searchClassName) {
    let resultBoolean = false;
    for (let i = 0; i < document.styleSheets.length; i += 1) {
      const styleSheet = document.styleSheets[i];
      try {
        for (let j = 0; j < styleSheet.cssRules.length; j += 1) {
          const rule = styleSheet.cssRules[j];
          // console.log(rule.selectorText)
          if (rule.selectorText
              && rule.selectorText === searchClassName
              // && rule.selectorText.includes(searchClassName)
          ) {
            console.log('1. found - ', rule.selectorText, ' ', i, '-', j);
            resultBoolean = true;
          }
        }
        if (styleSheet.imports) {
          for (let k = 0; k < styleSheet.imports.length; k += 1) {
            const imp = styleSheet.imports[k];
            for (let l = 0; l < imp.cssRules.length; l += 1) {
              const rule = imp.cssRules[l];
              if (
                rule.selectorText
                && rule.selectorText === searchClassName
                // && rule.selectorText.includes(searchClassName)
              ) {
                console.log('2. found - ', rule.selectorText, ' ', i, '-', k, '-', l);
                resultBoolean = true;
              }
            }
          }
        }
      } catch (err) {
        // TODO
      }
    }
    return resultBoolean;
  },
};
