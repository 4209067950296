<template>
  <div v-loading="loading" class="widget-multi-line">
    <div class="content-tooltip">
      <h1>{{label}}</h1>
      <el-tooltip
        v-if="tooltipContent.length > 0"
        popper-class="content-tooltip-width"
        class="item"
        effect="dark"
        :content="tooltipContent"
        placement="bottom">
        <img :src="`${URLSITE}/img/circle-question.svg`" alt="tooltip" />
      </el-tooltip>
    </div>

    <bar-chart v-if="isHorizontal && renderComponent"
    :stacked="true"
    :colors="widgetColor"
    :data="chartData" ></bar-chart>

    <column-chart v-else-if="renderComponent"
    :stacked="true"
    :colors="widgetColor"
    :data="chartData" ></column-chart>

  </div>
</template>

<script>
import Utils from '@/lib/Utils';
import ApiMILO from '@/lib/ApiMILO';

export default {
  name: 'WidgetStackedBars',
  props: ['config', 'brandSettings', 'renderComponent', 'initialFilterConfig'],
  data() {
    return {
      loading: true,
      data: null,
      apiConfig: {},
      automaticListenerFunction: null,
      externalListenerFunction: null,
      URLSITE: process.env.VUE_APP_URL_SITE,
    };
  },
  computed: {
    tooltipContent() {
      return Utils.getSetting('general', 'tooltip', this.config.widget_settings_categories);
    },
    isHorizontal() {
      return Utils.getSetting('general', 'show_horizontal', this.config.widget_settings_categories);
    },
    label() {
      return Utils.getSetting('general', 'label', this.config.widget_settings_categories);
    },
    widgetColor() {
      const color = Utils.getSetting('data', 'widget_color', this.config.widget_settings_categories);
      if (!color) {
        const defaultColor = [Utils.getReportBrandSetting('default_widget_color', this.brandSettings)];
        return defaultColor;
      }
      const colors = color.split('||');
      return colors;
    },
    chartData() {
      if (!this.data) {
        return [];
      }
      const { data } = this.data;

      if (!data) {
        return [];
      }

      const configSetup = JSON.parse(Utils.getSetting('data', 'multi_origin_setup', this.config.widget_settings_categories));

      const result = [];

      for (let index = 0; index < data.length; index += 1) {
        const series = data[index];
        const name = configSetup.find((val) => val.name === series.name);
        const { key, value } = name;

        if (key || value) {
          const obj = {};
          for (let index2 = 0; index2 < series.data.length; index2 += 1) {
            const element = series.data[index2];
            obj[element[key]] = element[value];
          }

          result.push({
            name: series.name,
            data: obj,
          });
        }
      }

      return result;
    },
  },
  methods: {
    async getWidgetData() {
      this.loading = true;
      const { id } = this.config;
      this.apiConfig.apiUrl = `/widgets/${id}/data?`;

      const apiUrl = Utils.queryStringGenerator(this.apiConfig);
      const result = await ApiMILO.get(apiUrl);

      const { success } = result;

      if (!success) {
        return [];
      }
      this.loading = false;
      return result;
    },
    async reloadData(conf) {
      if (conf.type === 'dropDown' || conf.type === 'check') {
        let fields = [];
        let s = '';
        if (conf.columns) {
          fields = conf.columns;
          if (Array.isArray(fields) && conf.value) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }
          this.apiConfig[conf.type] = s;
          this.data = await this.getWidgetData();
        }
      }

      if (conf.type === 'search') {
        const search = await Utils.getSetting('filters', 'search', this.config.widget_settings_categories);
        if (!search || search.length === 0) {
          console.warn('No valid search column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (search) {
          fields = search.split('||');
          if (Array.isArray(fields) && conf.value.length > 0) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }

          this.apiConfig.search = s;
          this.data = await this.getWidgetData();
        }
      }

      if (conf.type === 'dateRange') {
        const dateRange = Array.isArray(conf.value) ? conf.value.toString().replaceAll(',', '&&') : '';

        let dateRangeColumns = await Utils.getSetting('filters', 'date_range', this.config.widget_settings_categories);
        dateRangeColumns = conf?.columns ?? dateRangeColumns;
        if (!dateRangeColumns || dateRangeColumns.length === 0) {
          console.warn('No valid dateRange column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (dateRangeColumns) {
          if (!Array.isArray(dateRangeColumns)) {
            fields = dateRangeColumns.split('||');
          }
          if (conf?.columns) {
            fields = [...new Set([...fields, ...conf.columns])];
          } else {
            fields = [...new Set([...fields])];
          }

          if (Array.isArray(fields) && dateRange.length > 0) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${dateRange}${separator}`;

              return field;
            });
          }

          this.apiConfig.dateRange = s;
        }
        this.data = await this.getWidgetData();
      }

      if (conf.type === 'optIn') {
        const optIn = await Utils.getSetting('filters', 'opt_in', this.config.widget_settings_categories);
        if (!optIn || optIn.length === 0) {
          console.warn('No valid optIn column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (optIn) {
          fields = optIn.split('||');
          if (Array.isArray(fields) && conf.value) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }

          this.apiConfig.optIn = s;
          this.data = await this.getWidgetData();
        }
      }
    },
    async setupAutomaticRefresh() {
      const allow = Utils.getSetting('events', 'allow_automatic_refresh', this.config.widget_settings_categories);
      if (allow) {
        this.automaticListenerFunction = async () => {
          this.data = await this.getWidgetData();
        };
        this.$root.$on('reload-widget-data', this.automaticListenerFunction);
      }
    },
    async setupExternalEventListener() {
      const allow = Utils.getSetting('events', 'allow_external_refresh', this.config.widget_settings_categories);
      if (allow) {
        const self = this;
        this.externalListenerFunction = async function reload() {
          self.data = await self.getWidgetData();
        };
        this.$listen(window, 'reload-milo', this.externalListenerFunction);
      }
    },
  },
  beforeDestroy() {
    try {
      if (this.automaticListenerFunction != null) {
        this.$root.$off('reload-widget-data', this.automaticListenerFunction);
      }
      this.$root.$off('applyFilters', this.reloadData);
    } catch (e) {
      console.error(e);
    }
  },
  async mounted() {
    if (this.initialFilterConfig) {
      this.reloadData(this.initialFilterConfig);
    } else {
      this.data = await this.getWidgetData();
    }
    this.$emit('dataRefresh', this.config.id);
    this.$root.$on('applyFilters', this.reloadData);
    this.setupAutomaticRefresh();
    this.setupExternalEventListener();
  },
};
</script>
<style lang="scss" scoped>
.widget-multi-line {
  h1 {
    margin-bottom: 20px !important;
  }
}
</style>
