<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="custom-upload">
    <el-upload
      class="avatar-uploader"
      :class="value !== '' && value !== null ? 'avatar' : ''"
      :auto-upload="true"
      :action="uploadURL"
      drag
      :headers="uploadHeaders"
      :show-file-list="false"
      :on-success="handleAvatarSuccess">
      <img v-if="value"
           :src="value"
           class="avatar"
           alt="logo">
      <i v-else class="mdi mdi-camera avatar-uploader-icon"></i>
      <p v-if="edit && (value === '' || value === null)">
        Clip to upload or drag and drop
        PNG, JPG or GIF. (max 800 x 600px)
      </p>

      <el-button
        v-if="value !== '' && value !== null"
        @click.stop="deleteImage"
        class="delete-image"
        size="mini"
        plain
        type="danger">
          <i class="mdi mdi-delete"></i>
      </el-button>

    </el-upload>
  </div>
</template>

<script>

import ApiMILO from '../../lib/ApiMILO';

export default {
  name: 'CustomUpload',
  props: ['value', 'removeFiles', 'edit'],
  components: {},
  data() {
    return {
      oldFiles: [],
      srcFile: this.value,
      uploadURL: `${process.env.VUE_APP_API_MILO}/upload/upload-single`,
      uploadHeaders: {
        token: process.env.VUE_APP_API_KEY_MILO,
      },
    };
  },
  watch: {
    value(newVal) {
      this.srcFile = newVal;
    },
    srcFile(newVal, oldValue) {
      if (newVal !== oldValue) {
        this.$emit('input', newVal);
      }
    },
  },
  computed: {},
  methods: {
    async deleteImage() {
      this.oldFiles.push(this.srcFile);

      const url = '/upload/remove';
      try {
        this.oldFiles.forEach(async (filePath) => {
          await ApiMILO.post(url, { filePath });
        });
        this.oldFiles = [];
        this.srcFile = '';
      } catch (error) {
        console.log('🚀 -----------------------------------------------------------🚀');
        console.log('🚀 ~ file: CustomUpload.vue:80 ~ deleteImage ~ error', error);
        console.log('🚀 -----------------------------------------------------------🚀');
      }
    },
    async handleDeleteImage() {
      const url = '/upload/remove';
      this.oldFiles.forEach(async (filePath) => {
        await ApiMILO.post(url, { filePath });
      });

      this.oldFiles = [];
    },
    handleAvatarSuccess(res, file) {
      // this.encodeImageFileAsURL(file.raw);
      if (this.srcFile) {
        this.oldFiles.push(this.srcFile);
      }
      const src = `${process.env.VUE_APP_API_MILO}${file.response.data.destination}`;
      this.srcFile = src;
      this.$emit('input', this.srcFile);
      // debugger;
    },
  },
  mounted() {
    this.$root.$on('removeFiles', this.handleDeleteImage);
  },
};
</script>

<style lang="scss">
.custom-upload {
  display: flex;
  justify-content: center;

  .el-upload-dragger{
    border: none;
    width: 100%;
    background-color: transparent;
    position: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    p{
      display: none;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .avatar {
    width: 100%;
    height: 178px;
    display: block;
    object-fit: cover;
  }
  .delete-image{
    position: absolute;
    right: 0;
    margin: 12px;
    padding: 0;
    width: 22px;
    height: 22px;
    top: 0;
  }
}
</style>
