<template>
  <div class="widget-doughnut" v-loading="loading">
    <div class="content-tooltip">
      <h1>{{label}}</h1>
      <el-tooltip
        v-if="tooltipContent.length > 0"
        popper-class="content-tooltip-width"
        class="item"
        effect="dark"
        :content="tooltipContent"
        placement="bottom">
        <img :src="`${URLSITE}/img/circle-question.svg`" alt="tooltip" />
      </el-tooltip>
    </div>

    <template v-if="style_type_graph === 'chartkick'">
      <pie-chart
        v-if="chartData && renderComponent"
        :data="chartData"
        :suffix="suffix"
        :prefix="prefix"
        legend="left"
        :colors="widgetColor"
        :donut="true"
        :library="{backgroundColor: `${default_widget_background}`}"
        ></pie-chart>
    </template>
    <template v-if="style_type_graph === 'apexchart'">
      <apexchart
        v-if="chartData.series && renderComponent"
        :options="chartData.chartOptions"
        width="100%"
        height="80%"
        :series="chartData.series">
      </apexchart>
    </template>
  </div>
</template>

<script>
import Utils from '@/lib/Utils';
import ApiMILO from '@/lib/ApiMILO';

export default {
  name: 'WidgetDoughnut',
  props: ['config', 'brandSettings', 'renderComponent', 'initialFilterConfig'],
  data() {
    return {
      options: {},
      series: [],
      chartOptions: {
        dataLabels: {
          style: {
            colors: ['#FFFFFF'],
          },
        },
        labels: [],
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '50%',
            },
          },
        },
        tooltip: {
          theme: false,
          fillSeriesColor: false,
        },
        legend: {
          position: 'left',
          height: '100%',
          horizontalAlign: 'center',
          markers: {
            width: 30,
            height: 20,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 8,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -5,
            offsetY: 5,
          },
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 1,
          dashArray: 0,
        },
        // responsive: [{
        //   breakpoint: 480,
        //   options: {
        //     chart: {
        //       width: 200,
        //     },
        //     legend: {
        //       position: 'bottom',
        //     },
        //   },
        // }],
      },
      loading: false,
      data: null,
      apiConfig: {},
      automaticListenerFunction: null,
      externalListenerFunction: null,
      URLSITE: process.env.VUE_APP_URL_SITE ?? process.env.VUE_APP_URL,
    };
  },
  computed: {
    tooltipContent() {
      return Utils.getSetting('general', 'tooltip', this.config.widget_settings_categories);
    },
    label() {
      return Utils.getSetting('general', 'label', this.config.widget_settings_categories);
    },
    suffix() {
      return Utils.getSetting('general', 'suffix', this.config.widget_settings_categories);
    },
    prefix() {
      return Utils.getSetting('general', 'prefix', this.config.widget_settings_categories);
    },
    style_type_graph() {
      return Utils.getSetting('general', 'style_type_graph', this.config.widget_settings_categories);
    },
    default_widget_background() {
      return Utils.getReportBrandSetting('default_widget_background', this.brandSettings) ?? 'transparent';
    },
    defaultColor() {
      return Utils.getSetting('data', 'widget_color', this.config.widget_settings_categories);
    },
    widgetColor() {
      const color = Utils.getSetting('data', 'widget_color', this.config.widget_settings_categories);

      const defaultColor = Utils.getReportBrandSetting('default_widget_color', this.brandSettings);
      const steps = this.data?.data?.length ?? 0;

      let colors = [];

      if (!color) {
        colors = Utils.getScaleColor(defaultColor, 7);
      } else {
        const arrColors = color.split('||');
        colors = Utils.getScaleColor(arrColors[0], 7);
        for (let index = 0; index < arrColors.length; index += 1) {
          if (index < steps) {
            colors[index] = arrColors[index];
          }
        }
      }

      return colors;
    },
    chartData() {
      if (!this.data) {
        return [];
      }
      const { data } = this.data;

      if (!data) {
        return [];
      }

      const key = Utils.getSetting('data', 'key (column)', this.config.widget_settings_categories);
      const value = Utils.getSetting('data', 'value (column)', this.config.widget_settings_categories);

      if (!key || !value) {
        return [];
      }

      let totalSum;

      try {
        totalSum = data.reduce((accumulator, currentValue) => {
          const sum = accumulator + Number(currentValue[value]);
          return sum;
        }, 0);
      } catch (error) {
        totalSum = null;
      }

      const labels = [];
      const series = [];

      const theme = {
        monochrome: {
          enabled: true,
          color: this.defaultColor,
          shadeTo: 'dark',
        },
      };
      if (this.style_type_graph === 'apexchart') {
        data.forEach((element) => {
          let percentage = '';
          if (totalSum) {
            percentage = `(${((element[value] * 100) / totalSum).toFixed(2)}%)`;
          }
          const numberWithCommas = element[value].toLocaleString();
          labels.push(`${element[key]}: ${numberWithCommas} ${percentage}`);
          series.push(element[value]);
        });

        return {
          chartOptions: { ...this.chartOptions, labels, theme },
          series,
        };
      }

      const result = data.map((row) => {
        let percentage = '';
        if (totalSum) {
          percentage = `(${((row[value] * 100) / totalSum).toFixed(2)}%)`;
        }
        const numberWithCommas = row[value].toLocaleString();

        const arr = [`${row[key]}: ${numberWithCommas} ${percentage}`, row[value]];
        // const arr = [`Label: ${row[key]} | Value: ${row[value]}`, row[value]];

        return arr;
      });

      return result;
    },
  },
  methods: {
    async getWidgetData() {
      this.loading = true;
      try {
        const { id } = this.config;
        this.apiConfig.apiUrl = `/widgets/${id}/data?`;

        const apiUrl = Utils.queryStringGenerator(this.apiConfig);
        const result = await ApiMILO.get(apiUrl);

        const { success } = result;

        if (!success) {
          return [];
        }

        return result;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async reloadData(conf) {
      if (conf.type === 'dropDown' || conf.type === 'check') {
        let fields = [];
        let s = '';
        if (conf.columns) {
          fields = conf.columns;
          if (Array.isArray(fields) && conf.value) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }
          this.apiConfig[conf.type] = s;
          this.data = await this.getWidgetData();
        }
      }

      if (conf.type === 'search') {
        const search = await Utils.getSetting('filters', 'search', this.config.widget_settings_categories);
        if (!search || search.length === 0) {
          console.warn('No valid search column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (search) {
          fields = search.split('||');
          if (Array.isArray(fields) && conf.value.length > 0) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }

          this.apiConfig.search = s;
          this.data = await this.getWidgetData();
        }
      }

      if (conf.type === 'dateRange') {
        const dateRange = Array.isArray(conf.value) ? conf.value.toString().replaceAll(',', '&&') : '';

        let dateRangeColumns = await Utils.getSetting('filters', 'date_range', this.config.widget_settings_categories);
        dateRangeColumns = conf?.columns ?? dateRangeColumns;
        if (!dateRangeColumns || dateRangeColumns.length === 0) {
          console.warn('No valid dateRange column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (dateRangeColumns) {
          if (!Array.isArray(dateRangeColumns)) {
            fields = dateRangeColumns.split('||');
          }
          if (conf?.columns) {
            fields = [...new Set([...fields, ...conf.columns])];
          } else {
            fields = [...new Set([...fields])];
          }

          if (Array.isArray(fields) && dateRange.length > 0) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${dateRange}${separator}`;

              return field;
            });
          }

          this.apiConfig.dateRange = s;
        }
        this.data = await this.getWidgetData();
      }

      if (conf.type === 'optIn') {
        const optIn = await Utils.getSetting('filters', 'opt_in', this.config.widget_settings_categories);
        if (!optIn || optIn.length === 0) {
          console.warn('No valid optIn column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (optIn) {
          fields = optIn.split('||');
          if (Array.isArray(fields) && conf.value) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }

          this.apiConfig.optIn = s;
          this.data = await this.getWidgetData();
        }
      }
    },
    async setupAutomaticRefresh() {
      const allow = Utils.getSetting('events', 'allow_automatic_refresh', this.config.widget_settings_categories);
      if (allow) {
        this.automaticListenerFunction = async () => {
          await this.getWidgetData();
        };
        this.$root.$on('reload-widget-data', this.automaticListenerFunction);
      }
    },
    async setupExternalEventListener() {
      const allow = Utils.getSetting('events', 'allow_external_refresh', this.config.widget_settings_categories);
      if (allow) {
        const self = this;
        this.externalListenerFunction = async function reload() {
          await self.getWidgetData();
        };
        this.$listen(window, 'reload-milo', this.externalListenerFunction);
      }
    },
    async handleResize(window) {
      if (window.width <= 1024) {
        this.chartOptions.legend.position = 'bottom';
        // await this.getWidgetData();
      } else {
        this.chartOptions.legend.position = 'left';
      }
    },
  },
  beforeDestroy() {
    try {
      if (this.automaticListenerFunction != null) {
        this.$root.$off('reload-widget-data', this.automaticListenerFunction);
      }
      this.$root.$off('applyFilters', this.reloadData);
    } catch (e) {
      console.error(e);
    }
  },
  async mounted() {
    this.handleResize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    this.$root.$on('handleResize', this.handleResize);

    this.apiConfig.orderBy = await Utils.getSetting('data', 'orderBy', this.config.widget_settings_categories);
    this.apiConfig.groupBy = await Utils.getSetting('data', 'groupBy', this.config.widget_settings_categories);

    if (this.initialFilterConfig) {
      this.reloadData(this.initialFilterConfig);
    } else {
      this.data = await this.getWidgetData();
    }
    this.$emit('dataRefresh', this.config.id);

    this.$root.$on('applyFilters', this.reloadData);
    this.setupAutomaticRefresh();
    this.setupExternalEventListener();
  },
};
</script>

<style lang="scss">
.widget-doughnut {
  min-height: 370px;

  .apexcharts-canvas{
    .apexcharts-legend {
      // background: red;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    svg {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;

      foreignObject {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
      }
    }
  }

}
.apexcharts-tooltip {
  background: #000000 !important;
  color: #FFFFFF !important;
}
</style>
