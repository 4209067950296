<template class="token container">
  <el-main
    v-loading.fullscreen.lock="loading"
    class="login-main"
    element-loading-text="Validating token..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgb(255, 255, 255)"
  >
    <div class="token-screen">
    </div>
  </el-main>
</template>

<script>
import { mapMutations } from 'vuex';
import SSOApi from '@debrain/sso-api-integration';

export default {
  name: 'AuthToken',
  props: ['params'],
  components: {
  },
  data() {
    return {
      loading: true,
    };
  },
  watch: {
  },
  computed: {
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setPermissions', 'setRefreshToken']),
    async validateToken() {
      try {
        const { data, success } = await SSOApi.exchangeUserToken(this.params.token);

        if (success) {
          const { accessToken, refreshToken, permissions } = data;

          const jwtData = this.$jwt.decode(accessToken);
          const user = jwtData.data;
          user.exp = jwtData.exp;

          this.setUser(user);
          this.setToken(accessToken);
          this.setPermissions(permissions);
          this.setRefreshToken(refreshToken);

          const state = this.params?.state ? atob(this.params.state) : '/';

          this.$router.push({ path: state });
        }
      } catch (e) {
        console.error(e);
        this.loading = true;
      } finally {
        this.loading = true;
      }
    },
  },
  async mounted() {
    SSOApi.configure({
      appId: process.env.VUE_APP_ID_SSO,
      apiKey: process.env.VUE_APP_API_KEY,
      baseSSOURL: process.env.VUE_APP_BASE_SSO_URL,
      baseSSOAPIURL: process.env.VUE_APP_API_SSO,
    });

    await this.validateToken();
  },
};
</script>
