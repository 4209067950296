<template>
  <div class="report-bar">
    <!-- <div class="container_banner" :class="alignBtn" :style="bannerUrl"> -->
      <!-- <div class="content-banner-report">
        <img :src="brandLogoUrl" alt="" class="logo_brand"/>
      </div>
      <div class="content-banner-text">
        <h2 class="title_report">{{ data?.title }}</h2>
      </div> -->

      <div class="showBtn" v-if="btnData?.show">
        <el-button
          :class="`${btnData?.classes} ${bgColor !== undefined ? 'color-brand' : 'btn-primary'}`"
          @click="action"
          plain
        ><i class="mdi" :class="btnData?.icon"></i> {{ btnData?.text }}
        </el-button>
      </div>

      <div class="showBtn" v-if="this.user && this.isValidPermission('REPORT_EDIT')">
        <el-button
          :class="`${btnData?.classes} ${bgColor !== undefined ? 'color-brand' : 'btn-primary'}`"
          @click="goToEdit"
          plain
        ><i class="mdi mdi-pencil"></i>
          Edit Report
        </el-button>
      </div>
      <div class="showBtn" v-if="this.user">
        <el-button
          :class="`${btnData?.classes} ${bgColor !== undefined ? 'color-brand' : 'btn-primary'}`"
          @click="triggerExportPDF"
          :loading="isExportingPDF"
          :disabled="isExportingPDF || !enablePDFExport"
          plain
        ><i class="mdi mdi-file-pdf-box"></i>
          Export PDF
        </el-button>
      </div>
    <!-- </div> -->

    <embed-code-modal
      v-if="showModal"
      :showModal="showModal"
      title="Embed Code Report"
      :data="data">
    </embed-code-modal>
  </div>
</template>

<script>

import EmbedCodeModal from '@/components/Modals/EmbedCodeModal.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ReportBar',
  props: ['bannerUrl', 'brandLogoUrl', 'data', 'btnData', 'bgColor', 'isExportingPDF', 'enablePDFExport'],
  components: {
    EmbedCodeModal,
  },
  data() {
    return {
      showModal: false,

      // example btnDAta
      // btnData: {
      //   show: true,
      //   classes: 'btn-primary',
      //   text: 'Copy Embed Code',
      //   icon: 'mdi-link-variant',
      //   actionType: '',
      // },

    };
  },
  computed: {
    alignBtn() {
      return this.btnData?.show ? 'alignBtn' : 'alignBtn';
    },
    ...mapState(['user']),
    ...mapGetters(['isValidPermission']),
  },
  methods: {
    action() {
      if (this.btnData.actionType === 'openModal') {
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    goToEdit() {
      sessionStorage.setItem('goBackUrl', window.location.pathname);
      window.open(`/reports/${this.data.slug}/edit`, '_blank').focus();
    },
    triggerExportPDF() {
      this.$emit('exportPDF');
    },
  },
  mounted() {
    this.$root.$on('closeModal', this.closeModal);
  },
};
</script>

<style lang="scss">
.report-bar {
  // padding-left: 10px;
  //   padding-right: 10px;
  // .container_banner {
  //   // margin-bottom: 40px;
  //   margin: 20px 0;
  //   height: auto;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;
  //   position: relative;
  //   padding: 40px;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  //   row-gap: 20px;
  //   column-gap: 20px;
  //   align-items: center;
  //   align-content: center;
  //   justify-content: center;
  //   &::before {
  //     content: '';
  //     position: absolute;
  //     top:0;
  //     bottom:0;
  //     left:0;
  //     right:0;
  //     background-color: rgba(black, 0.6);
  //   }

  //   h2{
  //     margin: 0;
  //     text-align: center;
  //   }

  //   @mixin respond-to ($from: 'small'){
  //     min-height: 254px;
  //   }
  // }
  gap: 5px;
  .content-banner-report {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .content-banner-text {
    height: 100%;
    display: grid;
    align-items: center;
    position: relative;
    z-index: 1;
    flex:1
  }

  .logo_brand {
    border-radius: 50%;
    width: 175px;
    height: 175px;
    object-fit: contain;
    background-color: white;
  }

  .title_report {
    font-weight: 700;
    font-size: 53px;
    color: #ffffff;

  }

  .showBtn {
   /*
    margin: 50px;
    position: relative;
    */
    z-index: 1;
    span {
      font-size: 16px;
    }

  }

  .alignBtn {
    display: flex;
    align-items: center;
  }
  .color-brand {
    background-color: v-bind(bgColor);
    color: #FFFFFF;
    border-color: var(--6a36f3c0-bgColor);
    border-width: 2px;
    &:hover{
      color: v-bind(bgColor);
      background-color: #FFFFFF;
      border-color: v-bind(bgColor);
      border-width: 2px;
    }
  }
}

//  .btn-milo:active, .btn-milo:focus,.btn-milo:hover{
//   border: 2px solid #25401B !important;
//   opacity: 0.9;
// }

// .btn-milo, .btn-milo span{
//   color:#25401B;
//   font-size: 18px;
// }
</style>
