<template>
  <div class="render-widget" :style="cssVars">
    <div class="no-card" v-if="widget.type === 'filters_bar'">
      <widget-filter-bar
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-filter-bar>
    </div>
    <div class="widget no-card-text" v-else-if="widget.type === 'label'">
      <widget-label
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-label>
    </div>
    <div class="widget no-card-text" v-else-if="widget.type === 'paragraph'">
      <widget-paragraph
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-paragraph>
    </div>
    <div class="widget" v-else-if="widget.type === 'scalar'">
      <widget-scalar
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-scalar>
    </div>
    <div class="widget min-height-416" v-else-if="widget.type === 'table'">
      <widget-table
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-table>
    </div>
    <div class="widget" v-else-if="widget.type === 'bar'">
      <widget-bar
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-bar>
    </div>
    <div class="widget" v-else-if="widget.type === 'line'">
      <widget-line
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-line>
    </div>
    <div class="widget" v-else-if="widget.type === 'multi_line'">
      <widget-multi-line
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-multi-line>
    </div>
    <div class="widget" v-else-if="widget.type === 'stacked_bars'">
      <widget-stacked-bars
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-stacked-bars>
    </div>
    <div class="widget" v-else-if="widget.type === 'doughnut'">
      <widget-doughnut
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-doughnut>
    </div>
    <div class="widget" v-else-if="widget.type === 'us_map'">
      <widget-us-map
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent2="renderComponent"
      ></widget-us-map>
    </div>
    <div class="widget" v-else-if="widget.type === 'cohort'">
      <widget-cohort
        :brandSettings="brandSettings"
        :config="widget"
        :initialFilterConfig="initialFilterConfig"
        @dataRefresh="handleDataRefresh"
        :renderComponent="renderComponent"
      ></widget-cohort>
    </div>
  </div>
</template>

<script>
import WidgetFilterBar from '@/components/RenderWidget/Widgets/WidgetFilterBar.vue';
import WidgetLabel from '@/components/RenderWidget/Widgets/WidgetLabel.vue';
import WidgetParagraph from '@/components/RenderWidget/Widgets/WidgetParagraph.vue';
import WidgetScalar from '@/components/RenderWidget/Widgets/WidgetScalar.vue';
import WidgetTable from '@/components/RenderWidget/Widgets/WidgetTable.vue';
import WidgetBar from '@/components/RenderWidget/Widgets/WidgetBar.vue';
import WidgetLine from '@/components/RenderWidget/Widgets/WidgetLine.vue';
import WidgetMultiLine from '@/components/RenderWidget/Widgets/WidgetMultiLine.vue';
import WidgetStackedBars from '@/components/RenderWidget/Widgets/WidgetStackedBars.vue';
import WidgetDoughnut from '@/components/RenderWidget/Widgets/WidgetDoughnut.vue';
import WidgetUsMap from '@/components/RenderWidget/Widgets/WidgetUsMap.vue';
import WidgetCohort from '@/components/RenderWidget/Widgets/WidgetCohort.vue';
import Utils from '@/lib/Utils';

export default {
  name: 'RenderWidget',
  props: ['widget', 'brandSettings', 'renderComponent', 'initialFilterConfig'],
  components: {
    WidgetFilterBar,
    WidgetLabel,
    WidgetParagraph,
    WidgetScalar,
    WidgetTable,
    WidgetBar,
    WidgetLine,
    WidgetMultiLine,
    WidgetDoughnut,
    WidgetUsMap,
    WidgetCohort,
    WidgetStackedBars,
  },
  data() {
    return {
      show: true,
      hasCompletedLoading: false,
    };
  },
  methods: {
    handleDataRefresh(id) {
      if (this.hasCompletedLoading) {
        return;
      }
      this.$emit('dataRefresh', id);
      this.hasCompletedLoading = true;
    },
  },
  computed: {
    cssVars() {
      return {
        '--default-widget-background': this.default_widget_background,
        '--default-widget-font-family': this.default_widget_font_family,
        '--default-color-icon-widget': this.default_color_icon_widget,
        '--default-color-font': this.color_font,
      };
    },
    color_font() {
      return Utils.getReportBrandSetting(
        'widget_title_font_color',
        this.brandSettings,
      );
    },
    default_color_icon_widget() {
      return '#3FB5E5';
    },
    default_widget_background() {
      return (
        Utils.getReportBrandSetting(
          'default_widget_background',
          this.brandSettings,
        ) ?? 'transparent'
      );
    },
    default_widget_font_family() {
      return (
        Utils.getReportBrandSetting('font_name', this.brandSettings)
        ?? 'Work Sans, Helvetica'
      );
    },
  },
  mounted() {
    this.$root.$on('widgetLabel', (data) => {
      console.log(data, 'data');
      this.show = data;
      console.log(this.show, 'show');
    });
  },
};
</script>

<style lang="scss">
.render-widget {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1350px;

  *:not(i) {
    font-family: var(--default-widget-font-family);
  }

  .no-card {
    border: none !important;
    box-shadow: none !important;
  }

  .no-card-text {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
  }

  .widget {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--default-widget-background);
    padding: 25px;
    border: 0.8px solid #dde4fb;
    box-shadow: 0px 4px 16px rgba(128, 112, 221, 0.05);
    border-radius: 4px;

    .icon {
      color: var(--default-color-font) !important;
      box-sizing: content-box;
    }

    h1 {
      color: var(--default-color-font) !important;
      font-weight: 400;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
    }

    button {
      span {
        color: var(--default-color-font);
        font-weight: 500;
      }
    }
  }
}
</style>
