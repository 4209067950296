<template>
  <div class="brand-edit">
    <div class="el-container content">
      <el-form
        v-loading="loading"
        label-position="top"
        :model="settingsBrandForm"
        status-icon
        :rules="rulesSettings"
        ref="settingsBrandForm"
        label-width="120px"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <div class="brand-edit-navbar">
          <breadcrumb-view :items="breadcrumb"></breadcrumb-view>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="General" name="general">
            <!-- <el-form-item label="Show/Hide" prop="visible">
              <el-switch
                @input="dirty = true"
                active-color="#3FB5E5"
                inactive-color="#CBCBCB"
                :active-value=1
                :inactive-value=0
                v-model="settingsBrandForm.visible"></el-switch>
            </el-form-item> -->

            <el-form-item label="Is Template" prop="isTemplate">
              <el-switch
                @input="dirty = true"
                active-color="#3FB5E5"
                inactive-color="#CBCBCB"
                :active-value=true
                :inactive-value=false
                v-model="settingsBrandForm.isTemplate"></el-switch>
            </el-form-item>

            <el-form-item label="Brand Name" prop="name">
              <el-input
                @input="dirty = true"
                maxlength="20"
                show-word-limit
                type="text"
                v-model="settingsBrandForm.name"
                autocomplete="off">
              </el-input>
            </el-form-item>

            <el-form-item label="Brand Logo" prop="logo">
              <custom-upload
                @input="dirty = true"
                :edit="true"
                v-model="settingsBrandForm.logo">

              </custom-upload>
            </el-form-item>
          </el-tab-pane>

          <el-tab-pane
            v-for="settingCategory in settingsBrandForm.setting_categories"
            :key="settingCategory.uuid"
            :label="Utils.capitalizeString(settingCategory.name)"
            :name="settingCategory.name"
          >
            <el-form-item v-for="brandSetting in settingCategory.brand_settings"
                          :key="brandSetting.uuid"
                          :label="Utils.capitalizeString(brandSetting.name)"
                          :prop="brandSetting.name">

              <template>
                <template v-if="brandSetting.input_type === 'upload'">
                  <custom-upload @input="dirty = true" :edit="true"
                                 v-model="brandSetting.value"></custom-upload>
                </template>

                <div v-else-if="brandSetting.input_type === 'color_picker'"
                     class="color-picker"
                >
                  <el-color-picker
                    @input="dirty = true"
                    v-model="brandSetting.value">

                  </el-color-picker>
                  <el-input
                    @input="dirty = true"
                    placeholder="Please Input"
                    :value="brandSetting.value">
                    <template slot="append">100%</template>
                  </el-input>
                </div>
                <div
                  v-else-if="brandSetting.input_type === 'select'"
                  :class="isFontSize(brandSetting.name) ? 'font-sizes' : ''"
                >
                  <el-tag
                    :style="`font-size: ${brandSetting.value}`"
                    v-if="isFontSize(brandSetting.name)">Aa
                  </el-tag>

                  <el-select
                    clearable
                    filterable
                    v-model="brandSetting.value"
                    placeholder="Select"
                    @input="dirty = true"
                  >
                    <el-option
                      v-for="item in selectOptions[
                          brandSetting.name
                        ] ?? []"
                      :key="item.id"
                      :label="item.name ?? item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>

                <template v-else-if="brandSetting.input_type === 'text'">
                  <el-input
                    type="text"
                    v-model="brandSetting.value"
                    autocomplete="off"
                    @input="dirty = true"
                  ></el-input>
                </template>
              </template>

            </el-form-item>
          </el-tab-pane>

          <el-tab-pane
            v-if="isValidPermission('DATASOURCE_LIST')"
            label="Data Sources"
            name="dataSources">
            <data-sources-list :brandId="brandId"></data-sources-list>
          </el-tab-pane>
        </el-tabs>

        <div v-if="(activeName !== 'dataSources')" class="brand-buttons">
          <el-button
            type="info"
            class="cancel"
            @click="goToHome"
            @keypress="goToHome"
          >
            Cancel
          </el-button>

          <el-button
            class="btn-primary submit"
            :loading="loading"
            type="info"
            @click="settingBrand('settingsBrandForm')"
            @keypress="settingBrand('settingsBrandForm')"
            :disabled="loading || uploading"
          >
            Save
          </el-button>
        </div>
      </el-form>

      <div v-if="settingsBrandForm.name && (activeName !== 'dataSources')" style="width: 100%">
        <report-template :brand="settingsBrandForm"></report-template>
      </div>

    </div>
  </div>
</template>

<script>
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import CustomUpload from '@/components/Uploads/CustomUpload.vue';
import ReportTemplate from '@/components/Reports/ReportTemplate.vue';
import { mapState, mapGetters } from 'vuex';
import Utils from '@/lib/Utils';
import DataSourcesList from '@/components/DataSources/DataSourcesList.vue';
import ApiMILO from '../../lib/ApiMILO';

export default {
  name: 'BrandEdit',
  components: {
    BreadcrumbView,
    CustomUpload,
    DataSourcesList,
    ReportTemplate,
  },
  props: ['brandId'],
  data() {
    return {
      Utils,
      uploading: false,
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      createBrandForm: {
        name: '',
      },
      settingsBrandForm: {
        visible: false,
      },
      rules: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter brand name.',
            trigger: 'blur',
          },
        ],
      },
      rulesSettings: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter brand name.',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 20,
            message: 'Should be max 20 characters',
            trigger: 'blur',
          },
        ],
      },
      selectOptions: {},
      themes: [
        {
          id: 'probability',
          name: 'Probability',
        }, {
          id: 'default',
          name: 'Default',
        },
      ],
      fontNames: [
        {
          id: 'Work Sans',
          name: 'Work Sans',
        }, {
          id: 'Helvetica',
          name: 'Helvetica',
        },
      ],
      fontSizes: [
        {
          id: '10px',
          name: '10px',
        }, {
          id: '12px',
          name: '12px',
        }, {
          id: '14px',
          name: '14px',
        }, {
          id: '16px',
          name: '16px',
        }, {
          id: '18px',
          name: '18px',
        }, {
          id: '20px',
          name: '20px',
        }, {
          id: '24px',
          name: '24px',
        },
      ],
      breadcrumb: [
        {
          string: 'Settings',
          active: true,
        },
      ],
      activeName: 'general',
      dirty: false,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isValidPermission']),
  },
  methods: {
    isFontSize(str) {
      const font = str.includes('font');
      const size = str.includes('size');

      if (font && size) {
        return true;
      }

      return false;
    },
    goToHome() {
      this.$router.push({ path: '/' });
    },
    async settingBrand(formName) {
      this.loading = true;
      try {
        this.loading = true;
        const valid = await this.$refs[formName].validate();
        if (valid) {
          await this.sendSettings();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async sendSettings() {
      try {
        this.loading = true;
        const r = await ApiMILO.put(`/brand/${this.brandId}`, this.settingsBrandForm);
        if (r) {
          this.$message({
            message: 'Congrats, this is a success.',
            type: 'success',
          });
        }
      } catch (error) {
        console.error(error);
        this.$message({
          message: error,
          type: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteImage(path) {
      const url = '/upload/remove';
      await ApiMILO.post(url, { path });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async handleClick() {
      if (this.dirty) {
        await this.$confirm('Are you sure to close and save changes?', 'Warning', {
          confirmButtonText: 'Save and close',
          cancelButtonText: 'Close',
          type: 'warning',
        })
          .then(async () => {
            await this.sendSettings();
          });
      }
      this.dirty = false;
    },
    getBrand() {
      ApiMILO.getOne(`/brand/${this.brandId}`).then((r) => {
        if (r) {
          if (r.success) {
            this.settingsBrandForm = r.data;
            this.breadcrumb[0].imgUrl = this.settingsBrandForm.logo;
          }
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },
    goToDataSources() {
      const brandID = this.brandId;
      this.$router.push({ path: `/brands/${brandID}/dataSources` });
    },
  },
  mounted() {
    if (this.brandId) {
      this.getBrand();
      this.$set(this.selectOptions, 'theme', this.themes);
      this.$set(this.selectOptions, 'font_name', this.fontNames);
      this.$set(this.selectOptions, 'font_size', this.fontSizes);
      this.$set(this.selectOptions, 'font_header_size', this.fontSizes);
    }
    setTimeout(() => {
      this.dirty = false;
    }, 2000);
  },
};
</script>

<style lang="scss">
.brand-edit {

  .el-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }

  .content {
    @media (min-width: 720px) {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
    }
  }

  .avatar-uploader {
    text-align: center;
  }

  .report-navbar {
    .container-breadcrumb {
      .el-breadcrumb {
        margin: 0 !important;
      }
    }
  }

  .brand-buttons {
    padding: 20px 50px;
    border-top: 1px solid #E5E5E5;
    text-align: end;
    @media (max-width: 424px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        margin-bottom: 10px;
      }
    }
    @media (max-width: 768px) {
    }
  }

  .el-form {
    .el-tabs {
      padding-left: 0;
      padding-right: 0;

      @media (min-width: 720px) {
        padding-left: 119px;
        padding-right: 50px;
      }
    }
  }

  .brand-edit-navbar {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    height: 130px;
    align-content: center;
    padding: 0 80px;
    @media (min-width: 720px) {
      padding: 0 60px;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
    }

    .container-breadcrumb span.el-breadcrumb__item.active span {
      color: #3FB5E5 !important;
    }
  }

  .el-tabs.el-tabs--top {
    width: 100%;
  }

  .el-tabs--top .el-tabs__item.is-top {
    padding-left: 30px;
    padding-right: 30px;
  }

  .el-tabs__item.is-active {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    color: #3FB5E5;
    border-bottom: 4px solid #3FB5E5;
  }

  .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #D3D3D3;
  }

  .el-tabs__active-bar {
    background: transparent;
  }

  .el-tabs--top .el-tabs__item.is-top:last-child {
    padding: 0 20px;
  }

  .el-tab-pane {
    padding: 0 50px;
    @media (min-width: 720px) {
      padding: 0;
    }
  }

  .custom-upload {
    .avatar-uploader {
      width: 100%;
    }

    .avatar {
      object-fit: contain;
    }

    background: #fff;
    padding: 0;
    border-radius: 4.3445px;
  }

  .el-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .color-picker {
    gap: 14px;

    .el-color-picker__trigger {
      width: 129px !important;
    }

    .el-input-group__append {
      background: #fff;
    }
  }

  .el-form-item__label {
    margin-bottom: 18px;
    font-weight: 400 !important;
  }

  .font-sizes {
    display: flex;
    gap: 18px;

    .el-tag {
      background-color: #FAFAFA;
      width: 129px;
      border: none;
      height: auto;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .container-table {
    padding: 0;
  }

  .cancel {
    width: 96px;
    height: 36px;
    background: #F1F1F1;
    border-radius: 4px;
    border: 0;
    color: #363738;
  }

  .submit {
    width: 134px;
    height: 36px;
    background: #3FB5E5;
    border-radius: 4px;
    border: 0;
    color: #FFFFFF;
  }
}

.el-tabs__item:focus.is-active.is-focus:not(:active) {
  box-shadow: none !important;
  border-radius: 0 !important;
}
</style>
