<template>
  <div class="embed-code-modal">
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      width="50%"
      :visible.sync="dialogVisible"
      :before-close="handleClose">

        <el-form label-width="100px">
          <div class="mb-15">
            <el-alert
              class="mb-15"
              :closable="false"
              title="Info"
              type="info"
              description="Place the following tag where you want the widgets to appear on screen"
              show-icon>
            </el-alert>

            <el-input
              readonly
              disabled
              placeholder="Please input" v-model="tag" class="input-with-select">
              <el-button @click="copyClipboard('tag')" type="primary" class="btn-primary"
                slot="append"><i class="mdi mdi-content-copy"></i></el-button>
            </el-input>
          </div>

          <div class="mb-15">
            <el-alert
              class="mb-15"
              :closable="false"
              title="Info"
              type="info"
              description="Please include the following script tag on your page"
              show-icon>
            </el-alert>

            <el-input
              readonly
              disabled
              placeholder="Please input" v-model="script" class="input-with-select">
              <el-button @click="copyClipboard('script')" type="primary" class="btn-primary"
                slot="append"><i class="mdi mdi-content-copy"></i></el-button>
            </el-input>
          </div>
          <div>
            <el-alert
              class="mb-15"
              :closable="false"
              title="Info"
              type="info"
              description="Please include the following link tag on your page"
              show-icon>
            </el-alert>

            <el-input
              readonly
              disabled
              placeholder="Please input" v-model="styles" class="input-with-select">
              <el-button @click="copyClipboard('styles')" type="primary" class="btn-primary"
                slot="append"><i class="mdi mdi-content-copy"></i></el-button>
            </el-input>
          </div>
        </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'EmbedCodeModal',
  props: ['showModal', 'data', 'title'],
  data() {
    return {
      dialogVisible: this.showModal,
      tag: '',
      host: window.location.origin,
      script: '',
      styles: '',
    };
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
    copyClipboard(type) {
      if (type === 'tag') {
        navigator.clipboard.writeText(this.tag);
      } else if (type === 'script') {
        navigator.clipboard.writeText(this.script);
      } else if (type === 'styles') {
        navigator.clipboard.writeText(this.styles);
      }

      this.$message({
        message: `${type.toUpperCase()} Copied to clipboard successfully!`,
        type: 'success',
      });
    },
  },
  mounted() {
    this.tag = `<milo-report uuid="${this.data.uuid}"></milo-report>`;
    this.styles = `<link rel="stylesheet" type="text/css" media="screen" href="${this.host}/styles/index.css">`;
    this.script = `<script defer src="${this.host}/js/app.js"></{endTag}>`;
    this.script = this.script.replaceAll('{endTag}', 'script');
  },
};
</script>

<style lang="scss">
.embed-code-modal {
  .mb-15 {
    margin-bottom: 15px;
  }
}
</style>
