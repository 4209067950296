<template>
  <div class="verify-two-fa-modal">
    <el-dialog
      :visible.sync="dialogVisible"
      :fullscreen="true"
      class="main-content"
      :close-on-click-modal="false"
      :before-close="handleClose"
      :show-close="false"
    >
      <img src="/img/milo_logo_centered.svg" alt="Milo Logo" class="milo-logo" />
      <img src="/img/key_icon.svg" alt="Key Icon" class="key-icon" />
      <el-form
        label-position="top"
        :model="verifyTwoFaForm"
        status-icon
        :rules="rules"
        ref="verifyTwoFaForm"
        label-width="130px"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
      <el-form-item
      label="Enter 6-digit code from your authenticator application"
      prop="strCode">
          <el-input
            type="tel"
            :clearable="false"
            v-model="verifyTwoFaForm.one"
            autocomplete="off"
            tabindex="0"
            placeholder=""
            v-mask="'#'"
            ref="input0"
            @keydown.delete="handleBackspace(0)"
            @input="handleChangeInput(verifyTwoFaForm.one, 0)"
          ></el-input>
          <el-input
            type="tel"
            :clearable="false"
            v-model="verifyTwoFaForm.two"
            autocomplete="off"
            tabindex="0"
            placeholder=""
            v-mask="'#'"
            ref="input1"
            @keydown.delete="handleBackspace(1)"
            @input="handleChangeInput(verifyTwoFaForm.two, 1)"
          ></el-input>
          <el-input
            type="tel"
            :clearable="false"
            v-model="verifyTwoFaForm.three"
            autocomplete="off"
            tabindex="0"
            placeholder=""
            v-mask="'#'"
            ref="input2"
            @keydown.delete="handleBackspace(2)"
            @input="handleChangeInput(verifyTwoFaForm.three, 2)"
          ></el-input>
          <el-input
            type="tel"
            :clearable="false"
            v-model="verifyTwoFaForm.four"
            autocomplete="off"
            tabindex="0"
            placeholder=""
            v-mask="'#'"
            ref="input3"
            @keydown.delete="handleBackspace(3)"
            @input="handleChangeInput(verifyTwoFaForm.four, 3)"
          ></el-input>
          <el-input
            type="tel"
            :clearable="false"
            v-model="verifyTwoFaForm.five"
            autocomplete="off"
            tabindex="0"
            placeholder=""
            v-mask="'#'"
            ref="input4"
            @keydown.delete="handleBackspace(4)"
            @input="handleChangeInput(verifyTwoFaForm.five, 4)"
          ></el-input>
          <el-input
            type="tel"
            :clearable="false"
            v-model="verifyTwoFaForm.six"
            autocomplete="off"
            tabindex="0"
            placeholder=""
            v-mask="'#'"
            ref="input5"
            @keydown.delete="handleBackspace(5)"
            @input="handleChangeInput(verifyTwoFaForm.six, 5)"
          ></el-input>

        </el-form-item>
        <el-form-item>
          <div
            v-if="!codeIsValid"
            class="el-form-item__error">
            Invalid OTP! Please try again.
          </div>
        </el-form-item>

        <el-form-item>
          <el-button
            class="btn-primary"
            :loading="loading"
            type="info"
            @click="verifyCode('verifyTwoFaForm')"
            @keypress="verifyCode('verifyTwoFaForm')"
            ref="submitbtn"
          >
            Validate
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import ApiSSO from '../../lib/ApiSSO';

export default {
  name: 'VerifyTwoFaModal',
  components: {},
  props: ['showModal', 'title', 'data'],
  data() {
    return {
      dialogVisible: this.showModal,
      loading: false,
      codeIsValid: true,
      verifyTwoFaForm: {
        code: [],
        strCode: '',
        one: '',
        two: '',
        three: '',
        four: '',
        five: '',
        six: '',
      },
      twoFactorCode: '',
      rules: {
        strCode: [
          {
            required: true,
            message: 'Please enter OTP.',
            trigger: 'blur',
          },
          {
            min: 6,
            message: 'OTP must be at least 6 characters.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  computed: {
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setPermissions', 'setRefreshToken']),
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async verifyCode(formName) {
      this.loading = true;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const result = await this.sendVerifyCode();
          this.loading = false;
          return result;
        }
        this.loading = false;
        return false;
      });
    },
    sendVerifyCode() {
      return new Promise((resolve, reject) => {
        try {
          const data = {
            userId: this.data.user.id,
            applicationId: this.data.user.applicationId,
            code: this.verifyTwoFaForm.strCode,
          };

          ApiSSO.post('/twoFAValidate', data)
            .then((r) => {
              if (r) {
                if (r.success) {
                  const {
                    codeIsValid,
                  } = r.data;

                  this.loading = false;

                  this.codeIsValid = codeIsValid;

                  if (codeIsValid) {
                    this.dialogVisible = false;
                    this.resetForm('verifyTwoFaForm');

                    this.setUser(this.data.user);
                    this.setPermissions(this.data.permissions);
                    this.setToken(this.data.accessToken);
                    this.setRefreshToken(this.data.refreshToken);

                    this.$router.push({ path: '/' });
                    this.loading = false;
                    resolve(true);
                  }
                }
              }
            })
            .catch((e) => {
              console.log(e);
              this.loading = false;
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false);
            })
            .finally(() => {
              this.loading = false;
              resolve(true);
            });
        } catch (e) {
          console.error(e);
          this.loading = false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async handleChangeInput(val, position) {
      // set new value
      let cleanVal = val.replace(/[^0-9]/g, '');
      cleanVal = (cleanVal.length > 0) ? cleanVal[0] : '';
      this.verifyTwoFaForm.code[position] = cleanVal;
      // Determine next field
      let nextPosition = position;
      if (val.length > 0 && position < 6) {
        nextPosition = position + 1;
      } else if (position > 0) {
        nextPosition = position - 1;
      }

      if (nextPosition > 5 || nextPosition < 0) {
        this.$refs?.submitbtn?.$el.focus();
        // this.$refs?.submitbtn?.$el.click();
        this.verifyTwoFaForm.strCode = this.verifyTwoFaForm.code.join('');
        this.verifyCode('verifyTwoFaForm');
      } else {
        this.$refs[`input${nextPosition}`]?.focus();
      }

      this.verifyTwoFaForm.strCode = this.verifyTwoFaForm.code.join('');
      try {
        await this.$refs.verifyTwoFaForm.validate();
      // eslint-disable-next-line no-empty
      } catch (error) {

      }
    },
    handleBackspace(index) {
      console.log(index);
      this.verifyTwoFaForm.code[index] = '';
      if (index > 0) {
        this.next(index - 1);
      }
      this.verifyTwoFaForm.strCode = this.verifyTwoFaForm.code.join('');
      try {
        this.$refs.verifyTwoFaForm.validate();
      // eslint-disable-next-line no-empty
      } catch (error) {

      }
      // const valid = await this.$refs.verifyTwoFaForm.validate();
      // console.log(valid);
      // debugger;
    },
    next(input) {
      this.$refs[`input${input}`].focus();
    },
    registerListeners() {
      const scope = this;
      window.addEventListener('paste', scope.handlePaste);
      // window.addEventListener('paste', () => scope.handlePaste);
    },
    removeListeners() {
      const scope = this;
      window.removeEventListener('paste', scope.handlePaste);
      // window.removeEventListener('paste', () => scope.handlePaste);
    },
    handlePaste(event) {
      event.preventDefault();

      const paste = (event.clipboardData || window.clipboardData).getData('text');
      // paste = paste.toUpperCase();
      // const selection = window.getSelection();
      // if (!selection.rangeCount) return;
      // selection.deleteFromDocument();
      // selection.getRangeAt(0).insertNode(document.createTextNode(paste));
      for (let i = 0; i < 6; i += 1) {
        const value = (typeof (paste) === 'string' || paste[i] != null) ? paste[i] : '';
        this.verifyTwoFaForm.code[i] = value;
        switch (i) {
          case 0:
            this.verifyTwoFaForm.one = value;
            break;
          case 1:
            this.verifyTwoFaForm.two = value;
            break;
          case 2:
            this.verifyTwoFaForm.three = value;
            break;
          case 3:
            this.verifyTwoFaForm.four = value;
            break;
          case 4:
            this.verifyTwoFaForm.five = value;
            break;
          case 5:
            this.verifyTwoFaForm.six = value;
            break;
          default:
        }
      }
      this.verifyTwoFaForm.strCode = this.verifyTwoFaForm.code.join('');
      try {
        this.$refs.verifyTwoFaForm.validate();
      // eslint-disable-next-line no-empty
      } catch (error) {

      }
      this.$refs?.submitbtn?.$el.focus();
      // this.$refs?.submitbtn?.$el.click();
      this.verifyCode('verifyTwoFaForm');
    },
  },
  beforeDestroy() {
    this.removeListeners();
  },
  mounted() {
    this.registerListeners();
  },
};

</script>

<style lang="scss">
.verify-two-fa-modal {
  .el-form-item__content {
    display: flex;
    column-gap: 10px;

    .el-input__suffix {
      display: none;
    }
  }
  .main-content {
    .el-dialog {
      .el-dialog__body {
        display:flex;
        flex-direction: column;
        align-items: center;
        row-gap: 25px;
        // justify-content: center;
      }
    }
    .demo-ruleForm {
      max-width: 400px;
      width: 100%;

      .el-form-item__label {
        color: $grey !important;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        font-size: small;
        white-space: normal;
      }
      input {
          text-align: center;
          position: relative;
          height: 50px;
          font-weight: 600;
          font-size: larger;
          padding: 0;

          @media screen and (min-width: 600px) {
            height: 70px;
            padding: 0 15px;
            font-size: large;
          }

          &:before {
              content:'asdf';
              height: 10px;
              width:100px;
              left:0;
              right: 0;
              background-color: red;
              // background-color: $primary;
              position: absolute;
              bottom: 2px;
            }

          &:focus {
            // text-decoration: underline;
            border-color: $primary;
          }
      }
    }
    .milo-logo {
      width: 100%;
      max-width: 120px;
      @media screen and (min-width: 600px) {
        max-width: 156px;
      }
    }
    .key-icon {
      width: 100%;
      max-width: 80px;
      @media screen and (min-width: 600px) {
        max-width: 179px;
      }
    }
  }
}
</style>
