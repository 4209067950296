<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="reports">
    <div class="report-navbar">
      <breadcrumb-view :items="breadcrumb"></breadcrumb-view>

      <principal-bar
        :buttonsList="(user && isValidPermission('REPORT_CREATE'))
        ? buttonsList : null"
      ></principal-bar>
    </div>

    <report-table
      :showSearch="(user && isValidPermission('REPORT_SEARCH'))
        ? true : false"
      @viewRow="goToView"
      @searchRow="searchReport"
      @dateRangeFilter="filterDateRangeDataSource"
      :searchText="'Search Report'"
      :data="reports"
      :tableColumns="tableColumns"
      :loading="loading"
      :firstColumn="firstColumn"
      placeholderSearch="Search by created name or report name"
      :showDateRange="true"
      class="container"
    ></report-table>

    <simple-paginator
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :currentPage="paginator.currentPage"
      :pageSize="paginator.pageSize"
      :total="paginator.total"
      :pagerCount="5"
      layout="total, sizes, prev, pager, next, jumper"
      class="container"
    ></simple-paginator>

    <report-modal v-if="showModalCreate"
                  :showModal="showModalCreate"
                  :create="true"
                  title="Create Report"
                  :brandId="brandId">
    </report-modal>

    <report-from-template-modal
      v-if="showModalCreateFromTemplate"
      :showModal="showModalCreateFromTemplate"
      title="Create Report from Template"
      :brandId="brandId">
    </report-from-template-modal>
  </div>
</template>

<script>

import ApiMILO from '@/lib/ApiMILO';
import { mapState, mapGetters } from 'vuex';
import Utils from '@/lib/Utils';
import PrincipalBar from '@/components/Bars/PrincipalBar.vue';
// import SimpleTable from '@/components/Tables/SimpleTable.vue';
import ReportTable from '@/components/Tables/ReportTable.vue';
import ReportModal from '@/components/Modals/ReportModal.vue';
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import ReportFromTemplateModal from '@/components/Modals/ReportFromTemplateModal.vue';
import SimplePaginator from '@/components/Paginator/SimplePaginator.vue';

export default {
  name: 'ReportsView',
  props: ['brandId'],
  components: {
    PrincipalBar,
    // SimpleTable,
    ReportTable,
    ReportModal,
    BreadcrumbView,
    ReportFromTemplateModal,
    SimplePaginator,
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isValidPermission']),
    principalBarTitle() {
      const { brand } = this;
      const result = `Reports for ${brand.name}`;
      return result;
    },
  },
  data() {
    return {
      Utils,
      brand: {},
      loading: false,
      showModalCreate: false,
      showModalCreateFromTemplate: false,
      showModalEdit: false,
      reports: [],
      buttonsList: [
        {
          text: 'Create from template',
          actionType: 'showModalCreateFromTemplate',
        },
        {
          text: 'New Report ',
          actionType: 'showModalCreate',
          icon: 'mdi-plus',
          primary: true,
        },
      ],
      paginator: {
        currentPage: 0,
        pageSize: 10,
        total: 0,
        page: 1,
      },
      tableColumns: [
        {
          prop: 'createdAt',
          label: 'Created Date',
          sortable: true,
          format: 'date',
        },
        {
          prop: 'title',
          label: 'Report Name',
          sortable: true,
        },
        {
          prop: 'actions',
          label: 'Actions',
          sortable: false,
          isActions: true,
          typeButtons: 'icons',
          dropdown: [],
        },
      ],
      firstColumn: {
        show: true,
        icon: 'mdi-chart-box-outline',
      },
      breadcrumb: [
        {
          imgUrl: '',
          active: false,
          type: 'image',
          link: '/',
        },
        {
          string: 'Reports',
          active: true,
        },
      ],
    };
  },
  methods: {
    getReports(search = null, dateRange = null) {
      this.loading = true;
      const paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}`;
      let url = `/brands/${this.brandId}/report?${paginate}`;

      if (search) {
        url = `/brands/${this.brandId}/report?${paginate}&search=${search}`;
      }
      if (dateRange) {
        url = `/brands/${this.brandId}/report?${paginate}&date=${dateRange}`;
      }
      ApiMILO.get(url).then((r) => {
        this.loading = false;
        this.reports = Object.values(r.data);
        if (r.paginator.total_pages) {
          this.paginator.currentPage = r.paginator.page;
          this.paginator.page_size = r.paginator.page_size;
          this.paginator.total = r.paginator.count;
        }
      }).catch((e) => {
        this.loading = true;
        console.log(e);
      });
    },
    async getBrand() {
      const { data } = await ApiMILO.getOne(`/brands/${this.brandId}`);

      return data;
    },
    closeModal() {
      this.showModalCreate = false;
      this.showModalEdit = false;
      this.showModalCreateFromTemplate = false;
    },
    openModalCreate() {
      this.showModalCreate = true;
    },
    openModalCreateFromTemplate() {
      this.showModalCreateFromTemplate = true;
    },
    goToEdit(data) {
      if (data.type === 'Reports') {
        // this.$router.push({ path: `/reports/${data.slug}/edit` });
        sessionStorage.setItem('goBackUrl', window.location.pathname);
        window.open(`/reports/${data.slug}/edit`, '_blank').focus();
      }
    },
    goToView(data) {
      // this.$router.push({
      //   name: 'ReportDetail',
      //   params: {
      //     reportId: data.id,
      //     slug: data.slug,
      //   },
      // });
      sessionStorage.setItem('goBackUrl', window.location.pathname);
      window.open(`/reports/${data.slug}`, '_blank').focus();
    },
    searchReport(string) {
      this.getReports(string);
    },
    filterDateRangeDataSource(dateRange) {
      this.getReports(null, dateRange);
    },
    validatePermissions() {
      if (this.user && this.isValidPermission('REPORT_VIEW')) {
        const actions = this.tableColumns.findIndex((btn) => btn.prop === 'actions');
        this.tableColumns[actions].dropdown.push({
          text: 'View',
          action: 'view',
        });
      }

      if (this.user && this.isValidPermission('REPORT_EDIT')) {
        const actions = this.tableColumns.findIndex((btn) => btn.prop === 'actions');
        this.tableColumns[actions].dropdown.push({
          text: 'Edit',
          action: 'edit',
        });
      }

      if (this.user && this.isValidPermission('REPORT_HIDE')) {
        const actions = this.tableColumns.findIndex((btn) => btn.prop === 'actions');
        this.tableColumns[actions].dropdown.push({
          text: 'Hide',
          action: 'hide',
        });
      }

      if (this.user && this.isValidPermission('REPORT_DELETE')) {
        const actions = this.tableColumns.findIndex((btn) => btn.prop === 'actions');
        this.tableColumns[actions].dropdown.push({
          text: 'Delete',
          action: 'delete',
        });
      }
    },
    currentChange(val) {
      this.paginator.page = val;
      this.getReports();
    },
    sizeChange(val) {
      this.paginator.pageSize = val;
      this.paginator.handlesSizeChange = true;
      this.getReports();
    },
    async deleteReport(data) {
      const { id, type } = data;
      try {
        if (type === 'Reports') {
          const r = await ApiMILO.delete(`/report/${id}`);
          if (r.success) {
            this.$message({
              message: 'Congrats, Report deleted.',
              type: 'success',
            });

            this.getReports();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    const { brandId } = this;
    if (brandId == null) {
      this.$router.push({ path: '/' });
    }
    this.brand = await this.getBrand();
    this.breadcrumb[0].imgUrl = this.brand.logo;
    this.getReports();
    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('openModalCreate', this.openModalCreate);
    this.$root.$on('openModalCreateFromTemplate', this.openModalCreateFromTemplate);
    this.$root.$on('reloadReports', this.getReports);
    this.$root.$on('editRow', this.goToEdit);
    this.$root.$on('deleteRow', this.deleteReport);

    // this.$root.$on('dateRangeFilter', this.filterDateRangeDataSource);

    this.validatePermissions();
  },
};
</script>

<style lang="scss">
.el-input--mini .el-input__inner {
    height: 32px;
    line-height: 28px;
}

.banner_report {
  width: 100%;
}

.container_banner {
  margin-bottom: 40px;
  height: 254px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.logo_brand {
  border-radius: 50%;
  width: 175px;
  height: 175px;
}

.title_report {
  font-weight: 700;
  font-size: 53px;
  color: #ffffff;
  margin-left: 44px;
}

.report-navbar{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto ;
  background: rgba(63, 181, 229, 0.08);
  height: 145px;
  align-content: center;
  padding: 0 80px;
  // .principal-bar{
  //   margin-top: 13px;
  // }
  @media (min-width: 720px){
    padding: 0 90px;
    grid-template-rows: auto;
    grid-template-columns: auto auto ;
  }

  .principal-bar{
    justify-self: end;
  }

}

</style>
