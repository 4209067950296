<template>
  <div class="report-modal">
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose">

      <template v-if="create">
        <el-form
            :model="createReportForm"
            status-icon
            :rules="rules"
            ref="createReportForm"
            label-width="120px"
            class="demo-ruleForm"
            :hide-required-asterisk="true"
          >
          <el-form-item label="Title" prop="title">
            <el-input
              type="text"
              v-model="createReportForm.title"
              autocomplete="off">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              class="btn-secondary"
              :loading="loading"
              type="info"
              @click="createReport('createReportForm')"
              @keypress="createReport('createReportForm')"
            >
              Create
            </el-button>
          </el-form-item>
        </el-form>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import ApiMILO from '@/lib/ApiMILO';
import { mapState } from 'vuex';
import Utils from '../../lib/Utils';

export default {
  name: 'ReportModal',
  components: {},
  props: ['showModal', 'title', 'create', 'edit', 'brandId', 'reportId'],
  data() {
    return {
      dialogVisible: this.showModal,
      loading: false,
      logoUrl: '',
      disabled: false,
      createReportForm: {
        title: '',
        brandId: this.brandId,
      },
      editReportForm: {
        visible: false,
      },
      rules: {
        title: [
          {
            type: 'string',
            required: true,
            message: 'Please enter report title.',
            trigger: 'blur',
          },
        ],
      },
      rulesEdit: {
        title: [
          {
            type: 'string',
            required: true,
            message: 'Please enter report title.',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 20,
            message: 'Should be max 20 characters',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async createReport(formName) {
      try {
        this.loading = true;

        this.$refs[formName].validate(async (valid) => {
          if (!valid) {
            return false;
          }
          const result = await this.sendCreate();
          this.handleClose();
          return result;
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    sendCreate() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        ApiMILO.post(`/brands/${this.brandId}/report`, this.createReportForm).then(async (r) => {
          if (r) {
            if (r.success) {
              this.loading = false;
              this.dialogVisible = false;
              this.resetForm('createReportForm');

              const { permission } = r.data;
              await Utils.afterCreatePermission(permission.data);
            }
            if (r.success === false) {
              this.$message({
                message: r.message,
                type: 'warning',
              });
              reject(r.message);
            } else {
              this.$message({
                message: r.message,
                type: 'success',
              });
            }
            resolve(r);
          }
        }).catch((e) => {
          console.log(e);
          // this.loading = false;
          reject(e);
        }).finally(() => {
          this.$root.$emit('reloadReports');
          this.loading = false;
        });
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getReport() {
      ApiMILO.getOne(`/report/${this.reportId}`).then((r) => {
        if (r) {
          if (r.success) {
            this.editReportForm = r.data;
          }
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },
    editReport(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.sendEdit();
        }
        this.loading = false;
        return false;
      });
    },
    sendEdit() {
      ApiMILO.put(`/report/${this.reportId}`, this.editReportForm).then((r) => {
        if (r) {
          if (r.success) {
            this.loading = false;
            this.dialogVisible = false;
            this.resetForm('editReportForm');
            this.$root.$emit('reloadReports');
          }
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },
  },
  mounted() {
    if (this.reportId) {
      this.getReport();
    }
  },
};
</script>

<style lang="scss">

.report-modal {
}
</style>
