<template>
  <div class="home">
    <brandsView></brandsView>
  </div>
</template>

<script>
import brandsView from '@/views/brands/BrandsView.vue';

export default {
  name: 'HomeView',
  components: {
    brandsView,
  },
  data() {
    return { visible: false };
  },
  methods: {
  },
  mounted() {},
};
</script>
