<template>
  <div v-loading="loading" v-if="show">
    <div v-if="!loading" class="embedded-view">
      <template v-if="!this.matchHost()">
        <h1>Unauthorized Origin</h1>
      </template>

      <report-detail v-if="this.matchHost()" :externalReport="report"></report-detail>
    </div>
  </div>
</template>
<script>

import Utils from '@/lib/Utils';
// import RenderWidget from '@/components/RenderWidget/RenderWidget.vue';
import ApiMILO from '../../lib/ApiMILO';
import ReportDetail from '../../views/reports/ReportDetail.vue';

export default {
  name: 'EmbeddedView',
  components: {
    // RenderWidget,
    ReportDetail,
  },
  props: ['uuid'],
  data() {
    return {
      show: true,
      loading: true,
      report: null,
      widgets: [],
      widgetTypes: [],
      automaticReloadTimer: null,
      renderComponent: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async getReport() {
      try {
        const { data } = await ApiMILO.getOne(`/report/uuid/${this.uuid}`);
        return data;
      } catch (error) {
        this.show = false;
        console.error(error);
        return null;
      }
    },
    matchHost() {
      const enabled = Utils.getSetting('origin validation', 'enabled', this.report.setting_categories);
      let hosts = Utils.getSetting('origin validation', 'hosts', this.report.setting_categories);
      if (!enabled) {
        return true;
      }
      hosts = hosts.split('||');
      const origin = window.location.host;

      const match = hosts.find((h) => typeof h === 'string' && origin.indexOf(h) > -1);
      console.log('🚀 ------------------------------🚀');
      console.log('🚀 ~ matchHost ~ match:', match);
      console.log('🚀 ------------------------------🚀');

      return match ?? false;
    },
    async getWidgetTypes() {
      try {
        const res = await ApiMILO.get('/widgets/types');
        return res.data ?? [];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getWidgets() {
      try {
        const { data } = await ApiMILO.get(`/report/${this.report.id}/widgets`);
        // this.widgetsAdd = (Array.isArray(data)) ? data : [];

        this.widgets = data.map((widget) => {
          const imgWidget = this.widgetTypes.find(
            (widgetImage) => widgetImage.id === widget.widgetTypeId,
          );

          const { defaultImage } = this;
          const imageWid = imgWidget?.image_url ?? defaultImage;

          if (imgWidget) {
            return {
              ...widget,
              urlImage: imageWid,
              cols: 12,
            };
          }
          return { ...widget, cols: 12 };
        });
      } catch (error) {
        console.error(error);
      }
    },
    destroyAutomaticReloadTimer() {
      if (this.automaticReloadTimer == null) {
        return;
      }
      clearInterval(this.automaticReloadTimer);
    },
    setupAutomaticReload() {
      const { report } = this;
      if (!report || !Array.isArray(report?.setting_categories)) {
        return;
      }
      const refreshInterval = Utils.getSetting('automation', 'automatic_refresh_in_seconds', report?.setting_categories);
      if (!refreshInterval || refreshInterval < 1) {
        return;
      }
      if (this.automaticReloadTimer != null) {
        this.destroyAutomaticReloadTimer();
      }
      this.automaticReloadTimer = setInterval(() => {
        // console.log('Emmitting...', 'reload-widget-data');
        this.$root.$emit('reload-widget-data');
      }, refreshInterval * 1000);
    },
  },
  beforeDestroy() {
    this.destroyAutomaticReloadTimer();
  },
  async mounted() {
    if (this.uuid) {
      const report = await this.getReport();
      if (!report) {
        this.show = false;
        return;
      }
      this.report = report;

      if (this.report?.brand?.brand_settings) {
        const brandSettings = this.report?.brand?.brand_settings.filter((setting) => setting.name === 'theme');
        if (brandSettings) {
          const [theme] = brandSettings;
          if (theme) {
            if (theme.value === 'probability') {
              import('@/styles/probability.scss');
            }
          }
        }
      }

      if (this.matchHost()) {
        this.widgetTypes = await this.getWidgetTypes();
        this.widgets = await this.getWidgets().widgets;
      }
      await this.setupAutomaticReload();
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.embedded-view {
  .mb-20 {
    margin-bottom: 20px;
  }

  .mx-auto {
    margin: 0 auto !important;
  }

  .container-grid {
    /* padding-top: 50px;
    padding-right: 15px;
    padding-left: 15px; */
  }

  .content-grid {
    overflow: hidden;
  }

  .milo-grid-100,
  .milo-grid-50,
  .milo-grid-33,
  .milo-grid-25 {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    align-items: start;
    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr !important;
    }
  }

  .milo-grid-100 {
    grid-template-columns: 1fr;
  }

  .milo-grid-50 {
    grid-template-columns: repeat(2, 1fr);
  }

  .milo-grid-33 {
    grid-template-columns: repeat(3, 1fr);
  }

  .milo-grid-25 {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
