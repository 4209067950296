<template>
  <div class="report-settings-config">
    <div class="add-elements">
      <el-button
        @click="addElement"
        size="mini"
        type="primary"
        plain
        icon="el-icon-plus">
      </el-button>
    </div>
    <div class="elements">
      <div class="blocks-config" v-for="(conf, index) in data" :key="conf.uuid">
        <el-collapse accordion>
          <el-collapse-item :name="index">
            <div class="items-head" slot="title">
              <div class="moves">
                <el-button
                  v-if="index !== 0"
                  @click.stop="handleMove('up', index-1, conf)"
                  type="text"
                  icon="el-icon-arrow-up">
                </el-button>
                <el-button
                  v-if="index !== data.length-1"
                  @click.stop="handleMove('down', index+1, conf)"
                  type="text"
                  icon="el-icon-arrow-down">
                </el-button>
              </div>
              <div class="title">
                Filter {{ `${conf.filterType}_${index} [${conf?.columns}]` ?? index }}
              </div>
              <div class="delete">
                <el-button
                  v-if="index !== 0"
                  @click.stop="deleteElement(index)"
                  size="mini"
                  plain
                  type="text"
                  icon="el-icon-delete-solid">
                </el-button>
              </div>
            </div>
            <div class="config" >
              <span class="demo-input-label">Filter Type</span>
              <el-select
                v-model="conf.filterType"
                clearable
                @change="handleChangeValue()"
                filterable
                placeholder="Select type">
                  <el-option
                    v-for="item in filtersType"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>

              <!-- <span class="demo-input-label">Columns</span>
              <custom-tags
                @change="handleChangeValue()"
                v-model="conf.columns"
                type="tag_text"
              ></custom-tags> -->

              <span class="demo-input-label">Data Source</span>
              <el-select
                v-model="conf.dataSource"
                @change="handleGetTables(conf, index)"
                clearable
                filterable
                placeholder="Select Data source">
                  <el-option
                    v-for="item in dataSources"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>

              <span class="demo-input-label">Table</span>
              <el-select
                :loading="loading"
                v-model="conf.table"
                @change="handleGetColumns(conf, index)"
                clearable
                filterable
                placeholder="Select Table">
                  <el-option
                    v-for="item in tables[index]"
                    :key="item.tables"
                    :label="item.tables"
                    :value="item.tables">
                  </el-option>
              </el-select>

              <span class="demo-input-label">Columns</span>
              <el-select
                :loading="loading"
                v-model="conf.columns"
                @change="handleChangeValue()"
                clearable
                filterable
                multiple
                placeholder="Select Columns">
                  <el-option
                    v-for="item in columns[index]"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>

              <template v-if="conf.filterType === 'dropDown'">
                <span class="demo-input-label">Is Multiple</span>
                <el-switch
                  @change="handleChangeValue()"
                  v-model="conf.is_multiple"
                  active-color="#3FB5E5"
                  inactive-color="#C0CDCF">
                </el-switch>

                <span class="demo-input-label">Affects to</span>
                <el-select
                  v-model="conf.affectsTo"
                  @change="handleChangeValue()"
                  clearable
                  filterable
                  placeholder="Select Filter">
                    <el-option
                      v-for="(item, key) in data"
                      :key="`${item.filterType}_${key}`"
                      :label="`${item.filterType}_${key} [${item?.columns}]`"
                      :value="`${item.filterType}_${key}`">
                    </el-option>
                </el-select>

                <span class="demo-input-label">Query</span>
                <div class="btn-test-query">
                  <template>
                    <el-button
                      v-loading="loading"
                      @click="handleTestQuery(conf)"
                      size="mini"
                      type="info"
                    >Test Query</el-button>
                  </template>
                </div>
                <el-input
                  @change="handleChangeValue()"
                  type="textarea"
                  :rows="4"
                  placeholder="Please input query"
                  v-model="conf.query">
                </el-input>
              </template>
            </div>
            </el-collapse-item>
          </el-collapse>
      </div>
    </div>

    <el-dialog
      class="show-query"
      :close-on-press-escape="false"
      :lock-scroll="false"
      title="Result"
      :visible.sync="showModalTestQuery"
      width="40%"
      :before-close="handleCloseModalTestQuery">
      <div class="showResultQuery scroll-gray">
        <highlight-code lang="json">
          {{ dataTestQuery }}
        </highlight-code>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showModalTestQuery = false">OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiMILO from '@/lib/ApiMILO';
// import CustomTags from '@/components/Tags/CustomTags.vue';

export default {
  name: 'CustomFiltersConfig',
  props: ['value', 'brandId'],
  components: {
    // CustomTags,
  },
  data() {
    return {
      showModalTestQuery: false,
      dataTestQuery: {},
      loading: false,
      currentVal: null,
      dataSources: {},
      tables: [],
      columns: [],
      filtersType: [
        {
          id: 'dropDown',
          name: 'Drop Down',
        },
        {
          id: 'check',
          name: 'Check',
        },
        {
          id: 'dateRange',
          name: 'Date Range',
        },
      ],
      data: [
        {
          dataSource: '',
          table: '',
          columns: '',
          query: '',
        },
      ],
    };
  },
  watch: {
    currentVal(newValue) {
      this.$emit('input', newValue);
    },
    data(newValue) {
      this.currentVal = JSON.stringify(newValue);
    },
    showModalTestQuery(value) {
      if (value) {
        const modal = document.querySelector('.show-query');
        if (modal) {
          setTimeout(() => {
            const outerModal = document.querySelector('.v-modal');
            outerModal.style.zIndex = 8;
          }, 1000);
        }
      }
    },
  },
  methods: {
    async getDataSources() {
      try {
        const res = await ApiMILO.get(`/brands/${this.brandId}/datasources`);
        return res.data ?? [];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    addElement() {
      this.data.push({
        dataSource: '',
        table: '',
        query: '',
      });
    },
    deleteElement(index) {
      this.data.splice(index, 1);
    },
    handleChangeValue() {
      this.currentVal = JSON.stringify(this.data);
    },
    async handleGetTables(conf, index) {
      if (conf?.dataSource) {
        this.$set(this.tables, index, await this.getTables(conf));
      } else {
        this.$set(this.tables, index, null);
        this.data[index].table = '';
      }
      this.handleChangeValue();
    },
    async handleGetColumns(conf, index) {
      if (conf?.table) {
        this.$set(this.columns, index, await this.getColumns(conf));
      } else {
        this.$set(this.columns, index, null);
        this.data[index].columns = '';
      }
      this.handleChangeValue();
    },
    async getTables(conf) {
      this.loading = true;
      try {
        const res = await ApiMILO.get(`/brands/${this.brandId}/datasources/${conf.dataSource}/tables`);
        return res.data;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        this.loading = false;
      }
    },
    async getColumns(conf) {
      this.loading = true;
      try {
        const res = await ApiMILO.get(`/brands/${this.brandId}/datasources/${conf.dataSource}/table/${conf.table}/columns`);
        return res.data;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        this.loading = false;
      }
    },
    async getQuery(data) {
      try {
        const res = await ApiMILO.post('/widgets/getQuery', data);
        return res.data ?? [];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async handleTestQuery(data) {
      this.loading = true;

      const payload = {
        dataSource: data.dataSource,
        query: data.query,
      };

      this.dataTestQuery = await this.getQuery(payload);

      this.loading = false;

      this.showModalTestQuery = true;
    },
    handleCloseModalTestQuery(done) {
      this.dataTestQuery = {};
      done();
    },
    handleMove(type, position, element) {
      let pos = position;
      if (pos < 0) {
        pos = 0;
      }

      const toReplace = this.data[position];

      this.data.splice(pos, 1, element);
      if (type === 'up') {
        this.data.splice(pos + 1, 1, toReplace);
      }
      if (type === 'down') {
        this.data.splice(pos - 1, 1, toReplace);
      }
    },
  },
  async mounted() {
    const { value } = this;
    this.currentVal = value;
    if (value) {
      this.data = JSON.parse(value);
    }

    this.dataSources = await this.getDataSources();
  },
};
</script>

<style lang="scss">
  .report-settings-config {
    .moves {
      display: grid;
      .el-button {
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .showResultQuery{
      max-height: 300px;
      overflow-x: scroll;
      color: #fff;
      // background: #202124;
      font-size: 12px;
      line-height: normal;
    }

    .btn-test-query{
      text-align: end;
    }

    .add-elements {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }
    .elements {
      .blocks-config {
        padding-bottom: 5px;
        .el-collapse-item{
          border: solid 1px #DCDFE6;
          &__header{
            padding: 0 0 0 15px;
            height: auto;
            .items-head{
              display: flex;
              width: 100%;
              justify-content: space-between;
              .delete{
                button{
                  border: none;
                  font-size: 15px;
                  color: red;
                  padding-right: 5px;
                  :hover{
                    opacity: 0.5;
                  }
                }
              }
            }
          }
          &__content{
            padding-bottom: 0;
          }
        }
        .config {
          display: grid;
          row-gap: 5px;
          padding: 15px;
        }
      }
    }
  }
</style>
