import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: () => ({
    user: null,
    token: null,
    permissions: null,
    uuid: null,
    refreshToken: null,
  }),
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    isRefreshToken(state) {
      return !!state.refreshToken;
    },
    isEmbed(state) {
      return !!state.uuid;
    },
    isValidPermission: (state) => (identifier) => {
      if (!state.permissions) {
        return false;
      }

      const decodePermission = JSON.parse(window.atob(state.permissions));

      if (decodePermission.includes('FULL_ACCESS')) {
        return true;
      }

      return decodePermission.includes(identifier);
    },
  },
  mutations: {
    setUser(state, user) {
      if (state.user && user !== null) {
        state.user = { ...state.user, ...user };
      } else {
        state.user = user;
      }
    },
    setToken(state, token) {
      state.token = token;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setUUID(state, uuid) {
      state.uuid = uuid;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin],
});
