<template>
  <div class="widget-table" :style="cssVars">
    <div :class="!showSearchBar ? 'content-title' : ''">
      <div class="content-tooltip">
        <h1>{{label}}</h1>
        <!-- <pre>{{ showTable }}</pre>
        <pre>{{ showTableData }}</pre> -->
        <el-tooltip
          v-if="tooltipContent.length > 0"
          popper-class="content-tooltip-width"
          class="item"
          effect="dark"
          :content="tooltipContent"
          placement="bottom">
          <img :src="`${URLSITE}/img/circle-question.svg`" alt="tooltip" />
        </el-tooltip>
      </div>

      <div class="search-download">
        <el-input
          v-if="showSearchBar"
          v-show="showTable"
          v-model="searchString"
          clearable
          size="mini"
          placeholder="Search"
          class="search-field">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="action-container">
          <el-button v-if="showExportData"
            :disabled="generatingExcelReport" @click="handleGenerateReport" type="text">
            <i class="mdi mdi-download"></i>
            {{ generatingExcelReport ? 'generating...' : 'Generate Excel Report' }}
          </el-button>
          <el-button v-if="!showTableData"
          :disabled="generatingExcelReport" @click="handleTableVisibility" type="text">
            <!-- <i class="mdi mdi-download"></i> -->
            {{ showTable ? 'Hide Data' : 'Show Data' }}
          </el-button>
        </div>
      </div>
    </div>
    <normal-table
      @sortChange="handleSortChange"
      :data="myData"
      :columnsTotal="columnsTotal"
      :columnsAverage="columnsAverage"
      :tableColumns="columns"
      :loading="loading"
      :firstColumn="firstColumn"
      v-if="showTable && renderComponent"
    ></normal-table>

    <simple-paginator
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :currentPage="paginator.currentPage"
      :pageSize="apiConfig.pageSize ?? 10"
      :total="paginator.total"
      :pagerCount="3"
      layout="total, sizes, prev, pager, next, jumper"
      v-if="paginator && showTable && renderComponent"
    ></simple-paginator>
    <div class="hidden-data" v-if="!showTableData && !showTable" :style="cssVars">
      <i slot="prefix" class="el-input__icon el-icon-lock"></i>
    </div>
  </div>
</template>

<script>
import Utils from '@/lib/Utils';
import ApiMILO from '@/lib/ApiMILO';
import NormalTable from '@/components/Tables/NormalTable.vue';
import _ from 'lodash';
import SimplePaginator from '@/components/Paginator/SimplePaginator.vue';

export default {
  name: 'WidgetTable',
  props: ['config', 'brandSettings', 'renderComponent', 'initialFilterConfig'],
  components: {
    NormalTable,
    SimplePaginator,
  },
  data() {
    return {
      apiBase: process.env.VUE_APP_API_MILO,
      myData: [],
      columnsTotal: {},
      columnsAverage: {},
      searchString: '',
      debounceSearch: null,
      loading: false,
      generatingExcelReport: false,
      columns: [
        {
          prop: 'name',
          label: 'DataSource Name',
          sortable: false,
        },
        {
          prop: 'createdAt',
          label: 'Created Date',
          sortable: false,
          format: 'fromNow',
        },
      ],
      firstColumn: {
        show: false,
        icon: 'mdi-chart-box-outline',
      },
      page: 1,
      pageSize: 10,
      defaultOrderBy: '',
      search: '',
      apiConfig: {},
      paginator: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        page: 1,
      },
      automaticListenerFunction: null,
      externalListenerFunction: null,
      showTable: false,
      URLSITE: process.env.VUE_APP_URL_SITE,
    };
  },
  watch: {
    searchString() {
      this.debounceSearch();
    },
    // showTableData(newValue) {
    //   if (newValue == null) {
    //     return;
    //   }
    //   this.showTable = newValue;
    // },
  },
  computed: {
    tooltipContent() {
      return Utils.getSetting('general', 'tooltip', this.config.widget_settings_categories);
    },
    label() {
      return Utils.getSetting('general', 'label', this.config.widget_settings_categories);
    },
    showSearchBar() {
      return Utils.getSetting('general', 'show_search_bar', this.config.widget_settings_categories);
    },
    showTableData() {
      const value = Utils.getSetting('general', 'show_datatable', this.config.widget_settings_categories);
      return value;
    },
    showExportData() {
      return Utils.getSetting('general', 'show_export_button', this.config.widget_settings_categories);
    },
    cssVars() {
      return {
        '--default-widget-background': this.default_widget_background,
        '--default-header-background-color': this.header_background_color,
        '--default-header-font-color': this.color_font_header,
      };
    },
    default_widget_background() {
      return Utils.getReportBrandSetting('default_widget_background', this.brandSettings) ?? 'transparent';
    },
    header_background_color() {
      return Utils.getSetting('general', 'header_background_color', this.config.widget_settings_categories);
    },
    color_font_header() {
      return Utils.getSetting('general', 'header_font_color', this.config.widget_settings_categories);
    },
  },
  methods: {
    async getWidgetData() {
      try {
        this.loading = true;
        const { id } = this.config;

        this.apiConfig.apiUrl = `/widgets/${id}/data?`;

        const apiUrl = Utils.queryStringGenerator(this.apiConfig);
        const response = await ApiMILO.get(apiUrl);
        const { success } = response;

        if (!success) return [];

        const {
          data,
          paginator,
          columnsTotal,
          columnsAverage,
        } = response;
        // console.log('🚀 ----------------------------------------🚀');
        // console.log('🚀 ~ getWidgetData ~ response:', response);
        // console.log('🚀 ----------------------------------------🚀');

        let columns = [];
        if (Array.isArray(data) && data.length > 0) {
          const apiColumns = Object.keys(data[0]);

          columns = apiColumns.map((column) => {
            const col = {
              prop: column,
              label: column,
              sortable: true,
            };
            return col;
          });
        }

        if (paginator.total_pages) {
          if (this.paginator?.handlesSizeChange) {
            this.$set(this.paginator, 'currentPage', 1);
          } else {
            this.paginator.currentPage = paginator.page;
          }
          this.paginator.page_size = paginator.page_size;
          this.paginator.total = paginator.total;

          this.paginator.handlesSizeChange = false;
        }

        return {
          data,
          columns,
          columnsTotal,
          columnsAverage,
        };
      } catch (error) {
        console.error('🚀 ~ file: WidgetTable.vue ~ line 99 ~ getWidgetData ~ error', error);
        return null;
      } finally {
        this.loading = false;
      }
    },
    async handleGenerateReport() {
      try {
        this.generatingExcelReport = true;
        const { id } = this.config;

        this.apiConfig.apiUrl = `/widgets/${id}/export?`;

        const apiUrl = Utils.queryStringGenerator(this.apiConfig);
        const { data } = await ApiMILO.getOne(apiUrl);

        // Open report in new browser window
        const url = `${this.apiBase}${data.path}`;
        window.open(url, '_blank').focus();

        return data.path ?? null;
      } catch (error) {
        console.error('🚀 ~ file: WidgetTable.vue ~ line 99 ~ getWidgetData ~ error', error);
        return null;
      } finally {
        this.generatingExcelReport = false;
      }
    },
    handleTableVisibility() {
      this.showTable = !this.showTable;
    },
    async handleSortChange(dataOrder) {
      let { order } = dataOrder;
      const { prop } = dataOrder;
      if (order !== null) {
        if (order === 'ascending') {
          order = 'ASC';
        } else {
          order = 'DESC';
        }
      } else {
        order = null;
      }
      const orderBy = order ? `${prop}::${order}` : this.defaultOrderBy;

      this.apiConfig.orderBy = orderBy;

      const {
        data,
        columns,
        columnsTotal,
        columnsAverage,
      } = await this.getWidgetData();
      this.myData = data;
      this.columnsTotal = columnsTotal;
      this.columnsAverage = columnsAverage;
      this.columns = columns;
    },
    async handleSearch() {
      const search = await Utils.getSetting('data', 'search', this.config.widget_settings_categories);
      if (!search || search.length === 0) {
        console.warn('No valid search column specified!');
        return;
      }
      let fields = [];
      let s = '';
      if (search) {
        fields = search.split('||');
        if (Array.isArray(fields) && this.searchString.length > 0) {
          fields.map((field, index) => {
            let separator = '';
            if (index < fields.length - 1) {
              separator = '||';
            }
            s += `${field}::${this.searchString}${separator}`;

            return field;
          });
        }

        this.apiConfig.search = s;
        const {
          data,
          columns,
          columnsTotal,
          columnsAverage,
        } = await this.getWidgetData();
        this.myData = data;
        this.columnsTotal = columnsTotal;
        this.columnsAverage = columnsAverage;
        this.columns = columns;
      }
    },
    async currentChange(val) {
      this.apiConfig.page = val;
      const {
        data,
        columns,
        columnsTotal,
        columnsAverage,
      } = await this.getWidgetData();
      this.myData = data;
      this.columnsTotal = columnsTotal;
      this.columnsAverage = columnsAverage;
      this.columns = columns;
    },
    async sizeChange(val) {
      this.apiConfig.pageSize = val;
      this.paginator.handlesSizeChange = true;
      const {
        data,
        columns,
        columnsTotal,
        columnsAverage,
      } = await this.getWidgetData();
      this.myData = data;
      this.columnsTotal = columnsTotal;
      this.columnsAverage = columnsAverage;
      this.columns = columns;
    },
    async reloadData(conf) {
      if (conf.type === 'dropDown' || conf.type === 'check') {
        let fields = [];
        let s = '';
        if (conf.columns) {
          fields = conf.columns;
          if (Array.isArray(fields) && conf.value) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }
          this.apiConfig[conf.type] = s;
          this.data = await this.getWidgetData();
        }
      }

      if (conf.type === 'search') {
        const search = await Utils.getSetting('filters', 'search', this.config.widget_settings_categories);
        if (!search || search.length === 0) {
          console.warn('No valid search column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (search) {
          fields = search.split('||');
          if (Array.isArray(fields) && conf.value.length > 0) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }

          this.apiConfig.search = s;
          const {
            data,
            columns,
            columnsTotal,
            columnsAverage,
          } = await this.getWidgetData();
          this.myData = data;
          this.columnsTotal = columnsTotal;
          this.columnsAverage = columnsAverage;
          this.columns = columns;
        }
      }

      if (conf.type === 'dateRange') {
        const dateRange = Array.isArray(conf.value) ? conf.value.toString().replaceAll(',', '&&') : '';

        let dateRangeColumns = await Utils.getSetting('filters', 'date_range', this.config.widget_settings_categories);
        dateRangeColumns = conf?.columns ?? dateRangeColumns;
        if (!dateRangeColumns || dateRangeColumns.length === 0) {
          console.warn('No valid dateRange column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (dateRangeColumns) {
          if (!Array.isArray(dateRangeColumns)) {
            fields = dateRangeColumns.split('||');
          }
          if (conf?.columns) {
            fields = [...new Set([...fields, ...conf.columns])];
          } else {
            fields = [...new Set([...fields])];
          }
          if (Array.isArray(fields) && dateRange.length > 0) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${dateRange}${separator}`;

              return field;
            });
          }

          this.apiConfig.dateRange = s;
        }
        const {
          data,
          columns,
          columnsTotal,
          columnsAverage,
        } = await this.getWidgetData();
        this.myData = data;
        this.columnsTotal = columnsTotal;
        this.columnsAverage = columnsAverage;
        this.columns = columns;
      }

      if (conf.type === 'optIn') {
        const optIn = await Utils.getSetting('filters', 'opt_in', this.config.widget_settings_categories);
        if (!optIn || optIn.length === 0) {
          console.warn('No valid optIn column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (optIn) {
          fields = optIn.split('||');
          if (Array.isArray(fields) && conf.value) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }

          this.apiConfig.optIn = s;
          const {
            data,
            columns,
            columnsTotal,
            columnsAverage,
          } = await this.getWidgetData();
          this.myData = data;
          this.columnsTotal = columnsTotal;
          this.columnsAverage = columnsAverage;
          this.columns = columns;
        }
      }
    },
    async setupAutomaticRefresh() {
      const allow = Utils.getSetting('events', 'allow_automatic_refresh', this.config.widget_settings_categories);
      if (allow) {
        this.automaticListenerFunction = async () => {
          const {
            data,
            columns,
            columnsTotal,
            columnsAverage,
          } = await this.getWidgetData();
          this.myData = data;
          this.columnsTotal = columnsTotal;
          this.columnsAverage = columnsAverage;
          this.columns = columns;
        };
        this.$root.$on('reload-widget-data', this.automaticListenerFunction);
      }
    },
    async setupExternalEventListener() {
      const allow = Utils.getSetting('events', 'allow_external_refresh', this.config.widget_settings_categories);
      if (allow) {
        const self = this;
        this.externalListenerFunction = async function reload() {
          const {
            data,
            columns,
            columnsTotal,
            columnsAverage,
          } = await self.getWidgetData();
          self.myData = data;
          self.columnsTotal = columnsTotal;
          self.columnsAverage = columnsAverage;
          self.columns = columns;
        };
        this.$listen(window, 'reload-milo', this.externalListenerFunction);
      }
    },
  },
  beforeDestroy() {
    try {
      if (this.automaticListenerFunction != null) {
        this.$root.$off('reload-widget-data', this.automaticListenerFunction);
      }
      this.$root.$off('applyFilters', this.reloadData);
    } catch (e) {
      console.error(e);
    }
  },
  async mounted() {
    this.apiConfig.page = this.page;
    this.apiConfig.pageSize = await Utils.getSetting('data', 'page_size', this.config.widget_settings_categories);
    this.apiConfig.orderBy = await Utils.getSetting('data', 'orderBy', this.config.widget_settings_categories);
    this.defaultOrderBy = await Utils.getSetting('data', 'orderBy', this.config.widget_settings_categories);
    this.apiConfig.groupBy = await Utils.getSetting('data', 'groupBy', this.config.widget_settings_categories);
    this.apiConfig.columnsTotal = await Utils.getSetting('data', 'columns_to_total', this.config.widget_settings_categories);
    this.apiConfig.columnsAverage = await Utils.getSetting('data', 'columns_to_average', this.config.widget_settings_categories);
    this.showTable = this.showTableData;

    if (this.initialFilterConfig) {
      this.reloadData(this.initialFilterConfig);
    } else {
      const {
        data,
        columns,
        columnsTotal,
        columnsAverage,
      } = await this.getWidgetData();
      this.myData = data;
      this.columnsTotal = columnsTotal;
      this.columnsAverage = columnsAverage;
      this.columns = columns;
    }

    this.$emit('dataRefresh', this.config.id);

    this.debounceSearch = _.debounce(this.handleSearch, 500);

    this.$root.$on('applyFilters', this.reloadData);
    this.setupAutomaticRefresh();
    this.setupExternalEventListener();
  },
};
</script>
<style lang="scss">
.widget-table {
  .el-table td.el-table__cell div {
      word-break: keep-all !important;
  }

  display: flex;
  flex-direction: column;
  flex: 1;

  .content-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
    }
    @media screen and (min-width: 62em)
    and (max-width: 75em){
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
    }

    .content-tooltip{
      h1{
        margin: 0;
      }
    }
  }

  .search-download {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
      margin-bottom: 10px;
    }

    .search-field {
      display: flex;
      flex: 1;
    }
  }

  .action-container {
    margin: 15px 0;
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
    @media (max-width: 768px) {
      width: 100%;
    }

    button {
      border: 1px solid var(--default-color-font);
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      width: 185px;
      height: 40px;
      margin: 0;
      transition: all 125ms ease-in-out;

      &:hover {
        cursor: pointer;
        opacity: 0.4;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .hidden-data {
    display: flex;
    flex-direction: column;
    flex: 1;
    // background: grey;
    align-items: center;
    justify-content: center;

    .el-input__icon {
      color: var(--default-color-font);
      font-size: 36px;
      min-width: auto;
      width: auto;
    }
  }

  .el-table td {
    background-color: var(--default-widget-background);
  }

  .el-table thead th {
    background-color: var(--default-header-background-color);
    color: var(--default-header-font-color);
  }

  .el-table .sort-caret.ascending {
    border-bottom-color: var(--default-header-font-color);
    display: block !important;
    top: 5px !important;
  }

  .el-table .sort-caret.descending {
    border-top-color: var(--default-header-font-color);
    display: block !important;
    bottom: 7px !important;
  }

  .el-table th.el-table__cell.is-leaf {
    border: 0;
  }

  .el-table td.el-table__cell {
    border-bottom: 1px solid #E1E7FF;
  }

  .el-table th.el-table__cell > .cell {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    width: 104%;
  }

  .el-pager li {
    background-color: var(--default-widget-background);
  }

  .el-pagination .el-select .el-input .el-input__inner {
    background-color: var(--default-widget-background);
  }

  .el-pagination .btn-next, .el-pagination .btn-prev {
    background-color: var(--default-widget-background);
  }

  // .el-pagination {
  //   flex-flow: row wrap;
  // }
  // .el-input__inner{
  //   background-color: var(--default-widget-background);
  // }

  .el-table th.el-table__cell>.cell {
    display: inline-block !important;
  }
  .el-table {
    word-break: break-word;
    background-color: var(--default-widget-background);
  }

  @media (min-width: 769px) {
    .el-table__body, .el-table__footer, .el-table__header {
      width: 100% !important;
    }
  }

  .el-input--mini .el-input__inner {
    height: 40px;
  }
}
</style>
