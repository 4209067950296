import axios from 'axios';
import store from '@/store';

export default {
  async setBase() {
    // if (store.getters.isLoggedIn) {
    axios.defaults.headers.common.token = process.env.VUE_APP_API_KEY_MILO;
    axios.defaults.baseURL = process.env.VUE_APP_API_MILO;
    // }
  },
  async getToken() {
    if (store.getters.isLoggedIn) {
      axios.defaults.headers.common.Authorization = `Bearer ${store.state.token}`;
      axios.defaults.headers.common.Permissions = `${store.state.permissions}`;
    } else {
      axios.defaults.headers.common.Authorization = null;
      axios.defaults.headers.common.Permissions = null;
    }
  },
  // CRUD methods----------------------------------------------------------------------
  async get(path) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .get(path);
      const {
        data,
      } = response || {};

      const {
        count,
        page,
        page_size: pageSize,
        total_pages: totalPages,
        total,
        columnsTotal,
        columnsAverage,
      } = data || {};

      const result = {
        success: true,
        data: data ? ([...data.rows] || []) : null,
        columnsTotal: columnsTotal || null,
        columnsAverage: columnsAverage || null,
        // data: data ? ({ ...data.rows } || {}) : null,
        paginator: {
          count: count || null,
          page: page || null,
          page_size: pageSize || null,
          total_pages: totalPages || null,
          total: total || null,
        },
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response?.status ?? 500,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async getOne(path) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .get(path);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? ({ ...data } || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async post(path, payload) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .post(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: data.success,
        data: data ? ({ ...data.data } || {}) : null,
        message: data?.message ?? '',
      };

      return result;
    } catch (e) {
      console.log('🚀 ----------------------------------------🚀');
      console.log('🚀 ~ file: ApiMILO.js:103 ~ post ~ e:', e);
      console.log('🚀 ----------------------------------------🚀');
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.errors ?? e.message,
      };
    }
  },

  async put(path, payload) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .put(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async patch(path, payload) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .patch(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async delete(path) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .delete(path);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : true,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },
};
