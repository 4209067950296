<template>
  <div v-if="!uuid" class="milo-container"
       @touchmove="handleScrollMove"
       @wheel="handleScrollMove">
    <div id="app">
      <el-container direction="vertical" :class="isLoggedIn ? '' : 'login'">
        <NavBar v-if="isLoggedIn"></NavBar>

        <el-main>
          <div v-if="showBackButton" class="back-btn">
            <el-tooltip
              class="item"
              effect="dark"
              content="Go to back"
              placement="right">
              <el-button
                @click="goBack">
                <i class="mdi mdi-arrow-left"></i>Back
              </el-button>
            </el-tooltip>
          </div>
          <router-view/>
        </el-main>

        <!-- <img
          v-if="!isLoggedIn"
          class="img-login"
          :src="logoBg"
          alt="Login Image"
        > -->
      </el-container>
    </div>
    <!-- <Footer v-if="isLoggedIn"></Footer> -->
  </div>

  <div v-else class="container milo-container">
    <embedded-view :uuid="uuid"></embedded-view>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import logoBg from '@/assets/login-bg.png';
import Utils from '@/lib/Utils';
import ApiSSO from '@/lib/ApiSSO';
import jwtDecode from 'jwt-decode';
import NavBar from './components/NavBar/NavBar.vue';
// import Footer from './components/Footer/Footer.vue';
import EmbeddedView from './components/Embeds/EmbeddedView.vue';

export default {
  name: 'App',
  components: {
    // Footer,
    NavBar,
    EmbeddedView,
  },
  props: ['uuid'],
  data() {
    return {
      logoBg,
      showBackButton: false,
      intervalVerifyToken: null,
      flag: false,
      refreshTokenOld: null,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  watch: {
    $route(val) {
      this.showBackButton = (val.path !== '/' && val.path !== '/login' && val.path !== '/token');
      if (!this.uuid) {
        document.title = `MILO | ${val.name}`;
      }
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'refreshToken']),
    ...mapState(['token', 'user', 'refreshToken']),
  },
  methods: {
    ...mapMutations(['setUUID', 'setUser', 'setToken', 'setPermissions', 'setRefreshToken']),
    goBack() {
      const goBackUrl = sessionStorage.getItem('goBackUrl');
      sessionStorage.removeItem('goBackUrl');
      if (goBackUrl !== null) {
        this.$router.push(goBackUrl);
      } else if (goBackUrl === null && window.history.length >= 1) {
        this.$router.push('/');
      } else {
        this.$router.go(-1);
      }
    },
    setIntervalVerifyToken() {
      this.intervalVerifyToken = setInterval(async () => {
        if (this.user) {
          await this.validateToken();
        }
      }, 60000);
    },
    async validateToken() {
      const now = Date.now() / 1000;
      const exp = +this.user.exp;
      if ((now + 30 >= exp && !this.flag)) {
        this.flag = true;
        // refresh token
        console.log('token expired, refresh token', new Date());
        try {
          this.refreshTokenOld = this.refreshToken;
          this.setRefreshToken(null);
          const r = await ApiSSO.post('/refreshToken', {
            applicationId: this.user.applicationId,
            refreshToken: this.refreshTokenOld,
          });
          if (r.success) {
            const { accessToken, refreshToken, permissions } = r.data;
            const jwtData = jwtDecode(accessToken);
            const user = jwtData.data;
            user.exp = jwtData.exp;
            this.setUser(user);
            this.setToken(accessToken);
            this.setPermissions(permissions);
            this.setRefreshToken(refreshToken);
            const dataToken = {
              user,
              accessToken,
              permissions,
              refreshToken,
            };
            const broadcastChannel = new BroadcastChannel('refresh_token');
            broadcastChannel.postMessage({ type: 'update', data: dataToken });
            return;
          }
          this.setUser(null);
          this.setPermissions(null);
          this.setToken(null);
          this.setRefreshToken(null);
          this.setUUID(null);
          this.$router.push({
            path: '/',
            query: { redirect: this.$route.fullPath },
          });
        } catch (e) {
          console.error(e);
          this.setUser(null);
          this.setPermissions(null);
          this.setToken(null);
          this.setRefreshToken(null);
          this.setUUID(null);
          this.$router.push({
            // path: '/login',
            path: '/',
            query: { redirect: this.$route.fullPath },
          });
        } finally {
          this.flag = false;
        }
      } else {
        // console.log('no refresh token', new Date());
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;

      this.$root.$emit('handleResize', this.window);
    },
    handleScrollMove() {
      this.$root.$emit('handleScrollMove');
    },
  },
  created() {
    const elementUILoaded = Utils.searchForCss('.el-table__header');
    console.log('elementUILoaded', elementUILoaded);
    // debugger;
    const { host } = window.location;
    if (!elementUILoaded || host === 'cms.milo-v2.debrain.cloud') {
      Utils.loadCSS('/styles/index.css');
    }
    if (this.user) {
      this.validateToken();
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    const broadcastChannel = new BroadcastChannel('refresh_token');
    broadcastChannel.addEventListener('message', (event) => {
      if (event.data.type === 'update') {
        const updatedData = event.data.data;
        console.log('refresh token event', new Date());
        this.setUser(updatedData.user);
        this.setToken(updatedData.accessToken);
        this.setPermissions(updatedData.permissions);
        this.setRefreshToken(updatedData.refreshToken);
      }
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    if (this.uuid) {
      this.setUUID(this.uuid);
    } else {
      this.setUUID(null);
    }

    this.setIntervalVerifyToken();
  },
  beforeDestroy() {
    clearInterval(this.intervalVerifyToken);
  },
};
</script>

<style lang="scss">
@import '@/styles/style.scss';

.back-btn {
  margin-right: 10px;
  position: relative;
  z-index: 999;

  i {
    font-weight: bold;
    font-size: 16px;
  }

  .el-button {
    border: none;
    position: absolute;
    left: 10px;
    top: 52px;
    background-color: transparent;
    text-align: left;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
    color: #3FB5E5;
    @media (min-width: 720px) {
      left: 30px;
    }
  }

  .el-button:hover {
    background-color: transparent;
    border: none;
  }

  .el-button:active {
    background-color: transparent;
    border: none;
  }

  .el-button:focus {
    background-color: transparent;
    border: none;
  }
}

.milo-container {
  * {
    font-family: 'Work Sans';
  }
}

div[role=tooltip] {
  font-family: "Work Sans";
}
</style>
