<template>
  <div class="widget-scalar" :style="cssVars">
    <div class="content-container"  v-loading="loading">
      <div v-if="icon && icon.length>0" class="icon">
        <i :class="`mdi ${icon}`"></i>
      </div>
      <div class="text-container">
        <div v-html="value"></div>
        <div class="content-tooltip">
          <span>{{ label }}</span>
          <el-tooltip
            v-if="tooltipContent.length > 0"
            popper-class="content-tooltip-width"
            class="item"
            effect="dark"
            :content="tooltipContent"
            placement="bottom">
            <img :src="`${URLSITE}/img/circle-question.svg`" alt="tooltip" />
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from '@/lib/Utils';
import ApiMILO from '@/lib/ApiMILO';

export default {
  name: 'WidgetScalar',
  props: ['config', 'initialFilterConfig'],
  data() {
    return {
      loading: false,
      data: null,
      apiConfig: {},
      fetchingData: false,
      automaticListenerFunction: null,
      externalListenerFunction: null,
      URLSITE: process.env.VUE_APP_URL_SITE,
    };
  },
  computed: {
    cssVars() {
      return {
        '--icon-size': this.iconSize,
      };
    },
    iconSize() {
      return Utils.getSetting('general', 'icon_size', this.config.widget_settings_categories);
    },
    label() {
      return Utils.getSetting('general', 'label', this.config.widget_settings_categories);
    },
    icon() {
      return Utils.getSetting('general', 'icon', this.config.widget_settings_categories);
    },
    tooltipContent() {
      return Utils.getSetting('general', 'tooltip', this.config.widget_settings_categories);
    },
    value() {
      if (!this.data) {
        return '';
      }
      const { data } = this.data;

      if (!data) {
        return '';
      }
      let str = Object.values(data[0])[0];

      if (!str && str !== 0) {
        return '';
      }

      const separator = Utils.getSetting('general', 'thousand_separator', this.config.widget_settings_categories);
      if (separator) {
        str = Utils.numberWithCommas(str, separator);
      }

      const prefix = Utils.getSetting('general', 'prefix', this.config.widget_settings_categories);

      if (prefix) {
        str = prefix + str;
      }

      const suffix = Utils.getSetting('general', 'suffix', this.config.widget_settings_categories);

      if (suffix) {
        str += suffix;
      }

      if (str !== '') {
        const strSplit = str.toString().split('|');
        if (strSplit.length === 2) {
          str = `<h1>${this.convertNumber(strSplit[0])}</h1><label>${this.convertNumber(strSplit[1].trim())}</label>`;
        } else {
          str = `<h1>${str}</h1>`;
        }
      }
      return str;
    },
  },
  methods: {
    async getWidgetData() {
      this.loading = true;
      try {
        const { id } = this.config;
        this.apiConfig.apiUrl = `/widgets/${id}/data?`;

        const apiUrl = Utils.queryStringGenerator(this.apiConfig);
        const result = await ApiMILO.get(apiUrl);

        const { success } = result;

        if (!success) {
          return [];
        }

        return result;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        this.fetchingData = false;
        this.loading = false;
      }
    },
    async reloadData(conf) {
      try {
        if (this.fetchingData) {
          return;
        }
        this.fetchingData = true;
        if (conf.type === 'dropDown' || conf.type === 'check') {
          let fields = [];
          let s = '';
          if (conf.columns) {
            fields = conf.columns;
            if (Array.isArray(fields) && conf.value) {
              fields.map((field, index) => {
                let separator = '';
                if (index < fields.length - 1) {
                  separator = '||';
                }
                s += `${field}::${conf.value}${separator}`;

                return field;
              });
            }
            this.apiConfig[conf.type] = s;
            this.data = await this.getWidgetData();
          }
        }

        if (conf.type === 'search') {
          const search = await Utils.getSetting('filters', 'search', this.config.widget_settings_categories);
          if (!search || search.length === 0) {
            console.warn('No valid search column specified!');
            return;
          }
          let fields = [];
          let s = '';
          if (search) {
            fields = search.split('||');
            if (Array.isArray(fields) && conf.value.length > 0) {
              fields.map((field, index) => {
                let separator = '';
                if (index < fields.length - 1) {
                  separator = '||';
                }
                s += `${field}::${conf.value}${separator}`;

                return field;
              });
            }

            this.apiConfig.search = s;
            this.data = await this.getWidgetData();
          }
        }

        if (conf.type === 'dateRange') {
          const dateRange = Array.isArray(conf.value) ? conf.value.toString().replaceAll(',', '&&') : '';

          let dateRangeColumns = await Utils.getSetting('filters', 'date_range', this.config.widget_settings_categories);
          dateRangeColumns = conf?.columns ?? dateRangeColumns;
          if (!dateRangeColumns || dateRangeColumns.length === 0) {
            console.warn('No valid dateRange column specified!');
            return;
          }
          let fields = [];
          let s = '';
          if (dateRangeColumns) {
            if (!Array.isArray(dateRangeColumns)) {
              fields = dateRangeColumns.split('||');
            }
            if (conf?.columns) {
              fields = [...new Set([...fields, ...conf.columns])];
            } else {
              fields = [...new Set([...fields])];
            }

            if (Array.isArray(fields) && dateRange.length > 0) {
              fields.map((field, index) => {
                let separator = '';
                if (index < fields.length - 1) {
                  separator = '||';
                }
                s += `${field}::${dateRange}${separator}`;

                return field;
              });
            }

            this.apiConfig.dateRange = s;
          }
          this.data = await this.getWidgetData();
        }

        if (conf.type === 'optIn') {
          const optIn = await Utils.getSetting('filters', 'opt_in', this.config.widget_settings_categories);
          if (!optIn || optIn.length === 0) {
            console.warn('No valid optIn column specified!');
            return;
          }
          let fields = [];
          let s = '';
          if (optIn) {
            fields = optIn.split('||');
            if (Array.isArray(fields) && conf.value) {
              fields.map((field, index) => {
                let separator = '';
                if (index < fields.length - 1) {
                  separator = '||';
                }
                s += `${field}::${conf.value}${separator}`;

                return field;
              });
            }

            this.apiConfig.optIn = s;
            this.data = await this.getWidgetData();
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.fetchingData = false;
      }
    },
    async setupAutomaticRefresh() {
      const allow = Utils.getSetting('events', 'allow_automatic_refresh', this.config.widget_settings_categories);
      if (allow) {
        this.automaticListenerFunction = async () => {
          this.data = await this.getWidgetData();
        };
        this.$root.$on('reload-widget-data', this.automaticListenerFunction);
      }
    },
    async setupExternalEventListener() {
      const allow = Utils.getSetting('events', 'allow_external_refresh', this.config.widget_settings_categories);
      if (allow) {
        const self = this;
        this.externalListenerFunction = async function reload() {
          this.data = await self.getWidgetData();
        };
        this.$listen(window, 'reload-milo', this.externalListenerFunction);
      }
    },
    convertNumber(value) {
      return value.replace(/(\.\d+)|\B(?=(\d{3})+(?!\d))/g, (m, e) => e || ',');
    },
  },
  beforeDestroy() {
    try {
      if (this.automaticListenerFunction != null) {
        this.$root.$off('reload-widget-data', this.automaticListenerFunction);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async mounted() {
    this.apiConfig.orderBy = await Utils.getSetting('data', 'orderBy', this.config.widget_settings_categories);
    this.apiConfig.groupBy = await Utils.getSetting('data', 'groupBy', this.config.widget_settings_categories);

    if (this.initialFilterConfig) {
      this.reloadData(this.initialFilterConfig);
    } else {
      this.data = await this.getWidgetData();
    }
    this.$emit('dataRefresh', this.config.id);

    this.$root.$on('applyFilters', this.reloadData);
    this.setupAutomaticRefresh();
    this.setupExternalEventListener();
  },
};
</script>

<style lang="scss">

.widget-scalar {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .content-tooltip {
    align-items: center !important;
  }

  .content-container {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-flow: row wrap;
    flex: 1;
    min-width: 100%;
    column-gap: 25.5px;
    row-gap: 15px;

    .icon {
      display: flex;
      justify-content: center;
      align-content: space-around;
      align-items: center;
      flex-flow: row wrap;
      font-size: var(--icon-size);
      background: #F5F7FF;
      border-radius: 6.85714px;
      padding: 2px;
      height: 36px;
      width: 36px;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .text-container {
      position: relative;
      div{
        display: flex;
        align-items: center;
        gap: 54px;
        @media (max-width: 768px) {
          display: block;
        }
      }
      h1 {
        font-weight: 400;
        font-size: 42px;
        line-height: 49px;
        letter-spacing: 0.02em;
        margin: 0;
      }

      label {
        // color: #B1B4B7;
        color: #1B973E;
        font-size: 14.1488px;
      }

      .span {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18.77px;
        color: #6C6E6F;
      }
    }
  }
}
</style>
