import VueJWT from 'vuejs-jwt';
import UUID from 'vue-uuid';
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en';
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor';
import locale from 'element-ui/lib/locale';
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import VueAddEventListener from 'vue-add-event-listener';
import { Chart } from 'chart.js';
import Chartkick from 'vue-chartkick';
import VueApexCharts from 'vue-apexcharts';
import VueTour from 'vue-tour';
import VueMask from 'v-mask';
import VueHighlightJS from 'vue-highlight.js';
import store from './store';
import router from './router';
import App from './App.vue';

import 'vue-highlight.js/lib/allLanguages';
import 'highlight.js/styles/vs2015.css';

require('vue-tour/dist/vue-tour.css');

Vue.config.productionTip = false;

// configure language
locale.use(lang);

Vue.use(VueAddEventListener);
Vue.use(ElementUI);
Vue.use(UUID);
Vue.use(VueJWT);
Vue.use(vueCustomElement);
Vue.use(Chartkick.use(Chart));
Vue.use(VueApexCharts);
Vue.use(VueTour);
Vue.use(VueMask);
Vue.use(VueHighlightJS);
Vue.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs',
  },
});

/* eslint-disable */
Vue.component('apexchart', VueApexCharts);

App.store = store;

App.router = router;
Vue.customElement('milo-report', App, {
  shadow: false,
  // beforeCreateVueInstance(root) {
  //   const rootNode = root.el.getRootNode();

  //   if (rootNode instanceof ShadowRoot) {
  //     root.shadowRoot = rootNode;
  //   } else {
  //     root.shadowRoot = document.head;
  //   }
  //   return root;
  // },
});
