<template>
  <el-input
    placeholder="Select Icon"
    v-model="inputValue"
    autocomplete="off"
    type="text"
    clearable
    >
    <el-button @click="showIcons = !showIcons" slot="append">
      <i class="mdi mdi-sticker-emoji"></i>
    </el-button>

    <div v-if="showIcons" slot="append" class="menu-icons">
        <div class="search">
          <el-input
            size="mini"
            placeholder="Search Icon"
            v-model="searchString"
            autocomplete="off"
            type="text"
            clearable
          >
          </el-input>
        </div>
        <div class="grid-icons scroll-gray">
            <div
              class="icon"
              :id="icon.id"
              :key="icon.id"
              v-for="icon in IconsObj"
              @click="addEmoji(icon.name)"
              @keydown="addEmoji(icon.name)">
                <i :title="`mdi-${icon.name}`" :class="`mdi mdi-${icon.name}`"></i>
            </div>
        </div>

    </div>
  </el-input>
</template>

<script>

import Icons from '@/assets/icons.json';
import _ from 'lodash';

export default {
  name: 'IconsInput',
  props: ['value'],
  data() {
    return {
      inputValue: this.value,
      Icons,
      IconsObj: Icons,
      searchString: '',
      debounceSearch: null,
      showIcons: false,
    };
  },
  watch: {
    searchString() {
      this.debounceSearch();
    },
    showIcons(val) {
      if (val) {
        this.IconsObj.forEach((icon) => {
          this.visibleIcon(icon.id);
        });
      }
    },
    value(newVal) {
      this.inputValue = newVal;
    },
    inputValue(newVal, oldValue) {
      if (newVal !== oldValue) {
        this.$emit('input', newVal);
      }
    },
  },
  computed: {
  },
  methods: {
    addEmoji(emoji) {
      this.inputValue = `mdi-${emoji}`;
      this.showIcons = false;
    },
    search() {
      if (this.searchString !== '') {
        if (this.IconsObj.length === 0) {
          this.IconsObj = this.Icons;
        }
        const filteredIcons = this.IconsObj.filter((icon) => {
          if (icon.name.toLowerCase().includes(this.searchString.toLowerCase())) {
            return icon;
          }
          return null;
        });

        this.IconsObj = filteredIcons;
      } else {
        this.IconsObj = this.Icons;
      }

      this.IconsObj.forEach((icon) => {
        this.visibleIcon(icon.id);
      });
    },
    sleep(time = 500) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    },
    async visibleIcon(idIcon) {
      await this.sleep(100);

      const element = document.getElementById(idIcon);

      if (!element) {
        return '';
      }
      const { width } = element.getBoundingClientRect();
      if (width < 5) {
        element.classList.add('hideIcon');
      }
      return '';
    },
  },
  mounted() {
    this.debounceSearch = _.debounce(this.search, 500);
  },
};
</script>

<style lang="scss">
.menu-icons{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: absolute;
    z-index: 1;
    background: #ffffff;
    padding: 10px;
    right: 0px;
    border: 0.8px solid #DDE4FB;
    box-shadow: 0px 0px 12px rgb(77 112 245 / 30%);
    border-radius: 4px;
}
.el-input-group__append{
  button{
    .mdi{
      font-size: 25px;
    }
  }
}
.grid-icons {
    padding: 10px;
    overflow-y: auto;
    height: 200px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-size: 20px;
    justify-items: center;
    gap: 10px;
    .icon{
      border: solid 1px;
      cursor: pointer;
      &:hover{
        transform: scale(1.5);
      }
    }
    .hideIcon{
      display: none;
    }
}
</style>
