<template>
  <div class="report-from-template-modal">
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="scroll-gray content-modal-create-report">
        <el-steps v-if="createReportFormTemplate.include_consolidated"
                  :active="activeStep"
                  finish-status="success"
                  style="margin-bottom:1.5rem;"
                  align-center>
          <el-step title="Step 1"></el-step>
          <el-step title="Step 2"></el-step>
          <el-step title="Step 3"></el-step>
        </el-steps>
        <el-form
          label-position="top"
          :model="createReportFormTemplate"
          status-icon
          :rules="rules"
          ref="createReportFormTemplate"
          label-width="120px"
          class="demo-ruleForm"
          :hide-required-asterisk="true">
          <template v-if="activeStep === 1">
            <el-form-item label="Include consolidated">
              <el-switch v-model="createReportFormTemplate.include_consolidated"></el-switch>
            </el-form-item>
            <el-form-item label="Title" prop="title">
              <el-input
                type="text"
                v-model="createReportFormTemplate.title"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="Template" prop="template">
              <el-select
                clearable
                filterable
                v-model="createReportFormTemplate.template"
                placeholder="Select"
              >
                <el-option
                  v-for="item in templates"
                  :key="item.id"
                  :label="`${item.brand.name} - ${item.title}`"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="dataSources && !createReportFormTemplate.include_consolidated"
              label="Data Source Origin"
              prop="dataSourceOrigin">
              <el-select
                clearable
                filterable
                v-model="createReportFormTemplate.dataSourceOrigin"
                placeholder="Select"
              >
                <el-option
                  v-for="item in dataSources"
                  :key="item.id"
                  :label="`${item.name}`"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Start Campaign Date" prop="startDate">
              <el-date-picker
                v-model="createReportFormTemplate.startDate"
                type="datetime"
                :clearable="false"
                placeholder="Select date and time">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="End Campaign Date" prop="endDate">
              <el-date-picker
                v-model="createReportFormTemplate.endDate"
                type="datetime"
                :clearable="false"
                placeholder="Select date and time">
              </el-date-picker>
            </el-form-item>
          </template>
          <template v-if="activeStep === 2">
            <el-form-item
              v-if="dataSources" label="Data Source Origin" prop="dataSourceOrigin">
              <el-select
                clearable
                filterable
                v-model="createReportFormTemplate.dataSourceOrigin"
                placeholder="Select"
              >
                <el-option
                  v-for="item in dataSources"
                  :key="item.id"
                  :label="`${item.name}`"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Form field amount">
              <el-input-number v-model="createReportFormTemplate.form_field_amount"
                               controls-position="right"
                               :min="1"
                               size="small">
              </el-input-number>
            </el-form-item>
            <el-form-item label="Has open question">
              <el-switch v-model="createReportFormTemplate.has_open_question"></el-switch>
            </el-form-item>
            <el-form-item label="Is contest">
              <el-switch v-model="createReportFormTemplate.is_contest"></el-switch>
            </el-form-item>
            <el-form-item label="Campain type" prop="type">
              <el-select
                clearable
                filterable
                v-model="createReportFormTemplate.type"
                placeholder="Select"
              >
                <el-option
                  v-for="item in types"
                  :key="item"
                  :label="`${item}`"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-if="activeStep === 3">
            <el-form-item label="Summary Config">
              <report-settings-config
                :brandId="brandId"
                type="text"
                v-model="createReportFormTemplate.summary_config"
                autocomplete="off">
              </report-settings-config>
            </el-form-item>

          </template>
          <el-form-item style="margin-top:1.5rem;">
            <template v-if="createReportFormTemplate.include_consolidated">
              <el-button class="btn-secondary"
                         @click="prevStep"
                         type="info"
                         v-if="currentStep > 1">
                Prev Step
              </el-button>
              <el-button class="btn-secondary"
                         @click="nextStep"
                         type="info"
                         v-if="currentStep < 3">
                Next Step
              </el-button>
            </template>
            <el-button
              class="btn-secondary"
              :loading="loading"
              type="info"
              @click="createReport('createReportFormTemplate')"
              @keypress="createReport('createReportFormTemplate')"
              v-if="currentStep === 3">
              Create
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import ApiMILO from '@/lib/ApiMILO';
import { mapState } from 'vuex';
import moment from 'moment';
import Utils from '../../lib/Utils';
import ReportSettingsConfig from '../ReportSettingsConfig/ReportSettingsConfig.vue';

export default {
  name: 'ReportFromTemplateModal',
  components: { ReportSettingsConfig },
  props: ['showModal', 'title', 'brandId'],
  data() {
    return {
      dataSources: [],
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      createReportFormTemplate: {
        brandId: '',
        startDate: moment().format('YYYY-MM-DD 00:00:00'),
        endDate: moment().add(1, 'months').format('YYYY-MM-DD 23:59:59'),
        include_consolidated: false,
      },
      rules: {
        title: [
          {
            type: 'string',
            required: true,
            message: 'Please enter report title.',
            trigger: 'blur',
          },
        ],
        template: [
          {
            required: true,
            message: 'Please select report template.',
            trigger: 'blur',
          },
        ],
      },
      templates: [],
      activeStep: 1,
      currentStep: 3,
      types: ['sweepstakes', 'giveaway', 'instant win', 'pwa', 'n/a'],
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
    // eslint-disable-next-line func-names
    'createReportFormTemplate.include_consolidated': function (val) {
      if (val) {
        this.currentStep = 1;
      } else {
        this.currentStep = 3;
      }
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
    createReport(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.sendCreate();
        }
        this.loading = false;
        return false;
      });
    },
    sendCreate() {
      return new Promise((resolve, reject) => {
        this.createReportFormTemplate.brandId = +this.brandId;
        ApiMILO.post(`/brands/${this.brandId}/report/fromTemplate`, this.createReportFormTemplate).then(async (r) => {
          if (r) {
            if (r.success) {
              this.loading = false;
              this.dialogVisible = false;
              // this.resetForm('createReportFormTemplate');

              const { permission } = r.data;
              await Utils.afterCreatePermission(permission.data);
            }

            if (r.success === false) {
              this.$message({
                message: r.message,
                type: 'warning',
              });
              reject(r.message);
            } else {
              this.$message({
                message: r.message,
                type: 'success',
              });
            }
            resolve(r);
          }
        }).catch((e) => {
          console.log(e);
          reject(e);
        }).finally(() => {
          this.$root.$emit('reloadReports');
          this.loading = false;
        });
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async getTemplates() {
      try {
        const res = await ApiMILO.get('/report/templates');

        if (res) {
          if (res.success) {
            this.templates = res.data;
          }
        }
      } catch (error) {
        console.error(error);
        this.templates = [];
      }
    },
    async getDataSources() {
      try {
        const res = await ApiMILO.get(`/brands/${this.brandId}/datasources`);
        return res.data ?? [];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    nextStep() {
      this.$refs.createReportFormTemplate.validate((valid) => {
        if (valid) {
          this.activeStep += 1;
          this.currentStep += 1;
        }
      });
    },
    prevStep() {
      this.activeStep -= 1;
      this.currentStep -= 1;
    },
    summaryConfig() {
      const summaryConfig = [
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'consolidated',
          columnDestination: [
            'date',
          ],
          query: "select date_format(createdAt, '%Y-%m-%d %H:00:00') as date, count(1) as entries\nfrom Entries\nwhere createdAt >= {last_synced_date}\nAND (email not like '%hellodebrain%' AND email not like '%mailinator%')\ngroup by 1",
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'consolidated',
          query: "select date_format(createdAt, '%Y-%m-%d %H:00:00') as date, count(1) as total_entries\nfrom Entries\nwhere\n    createdAt >= {last_synced_date} AND\n    (email not like '%hellodebrain%' AND email not like '%mailinator%')\ngroup by 1",
          columnDestination: [
            'total_entries',
          ],
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'consolidated',
          query: "select date_format(createdAt, '%Y-%m-%d %H:00:00') as date, count(1) as unique_entries\nfrom (SELECT min(createdAt) as 'createdAt', email\n      FROM Entries\n      WHERE (email not like '%hellodebrain%' AND email not like '%mailinator%')\n        AND email != ''\n      group by email) as aux\nwhere createdAt >= {last_synced_date}\ngroup by 1",
          columnDestination: [
            'unique_entries',
          ],
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'consolidated',
          query: "select date_format(created_date, '%Y-%m-%d %H:00:00') as date, count(1) as optIn\nfrom web_entries\nwhere\n    created_date >= {last_synced_date}   AND\n    (email not like '%hellodebrain%' AND email not like '%mailinator%')\n  AND optin = 1\ngroup by 1",
          columnDestination: [
            'optIn',
          ],
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'consolidated',
          query: "select date_format(createdAt, '%Y-%m-%d %H:00:00') as date, count(1) as page_views\nfrom PageViews\nwhere createdAt >= {last_synced_date}\ngroup by 1",
          columnDestination: [
            'page_views',
          ],
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'consolidated',
          query: "select date_format(createdAt, '%Y-%m-%d %H:00:00') as date, count(1) as uniqueOptIn\nfrom (SELECT min(created_date) as 'createdAt',  email\n      FROM web_entries\n      WHERE optin = 1\n        AND (email not like '%hellodebrain%' AND email not like '%mailinator%')\n        AND email != ''\n      group by email) as aux\nwhere createdAt >= {last_synced_date}\ngroup by 1",
          columnDestination: [
            'uniqueOptIn',
          ],
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'consolidated',
          query: "select date_format(createdAt, '%Y-%m-%d %H:00:00') as date, count(1) as mobile\nfrom Entries\nwhere\n    createdAt >= {last_synced_date} and\n      device_type = 'mobile'\ngroup by 1",
          columnDestination: [
            'mobile',
          ],
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'consolidated',
          query: "select date_format(createdAt, '%Y-%m-%d %H:00:00') as date, count(1) as desktop\nfrom PageViews\nwhere\n    createdAt >= {last_synced_date} and\n    device_type = 'desktop'\ngroup by 1",
          columnDestination: [
            'desktop',
          ],
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'consolidated',
          query: "select date_format(createdAt, '%Y-%m-%d %H:00:00') as date, count(1) as tablet\nfrom PageViews\nwhere\n    createdAt >= {last_synced_date} and\n    device_type = 'tablet'\ngroup by 1",
          columnDestination: [
            'tablet',
          ],
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'locations',
          query: "select date_format(createdAt, '%Y-%m-%d %H:00:00') as date, req_country as country, req_subdivision as state, count(email) as entries, req_country_code as country_code\nfrom Entries\nwhere (email not like '%hellodebrain%' AND email not like '%mailinator%')\n and createdAt >= {last_synced_date}\ngroup by 1, 2, 3",
          columnDestination: [
            'country',
            'state',
            'entries',
            'country_code',
          ],
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'consolidated',
          query: "SELECT aux_date as date, count(aux_unique_ip) as unique_ip FROM (select min(date_format(createdAt, '%Y-%m-%d %H:00:00')) as aux_date, req_ip as aux_unique_ip\nfrom Entries\nwhere\n    createdAt >= {last_synced_date} AND\n    (email not like '%hellodebrain%' AND email not like '%mailinator%')\ngroup by 2) aux GROUP BY 1;",
          columnDestination: [
            'unique_ip',
          ],
        },
        {
          dataSourceOrigin: null,
          dataSourceDestination: 101,
          tableDestination: 'utms',
          query: "select date_format(createdAt, '%Y-%m-%d %H:00:00') as date,\n       IFNULL(utm_source, 'Other') as 'utm_source',\n       IFNULL(utm_medium, 'Other') as 'utm_medium',\n       IFNULL(utm_campaign, 'Other') as 'utm_campaign',\n       IFNULL(utm_id, 'Other') as 'utm_id',\n       IFNULL(utm_term, 'Other') as 'utm_term',\n       IFNULL(utm_content, 'Other') as 'utm_content',\n       count(1) as total\nfrom Entries\nwhere (email not like '%hellodebrain%' AND email not like '%mailinator%')\nand createdAt >= {last_synced_date}\ngroup by 1, 2, 3, 4, 5, 6, 7",
          columnDestination: [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_id',
            'utm_term',
            'utm_content',
            'total',
          ],
        },
      ];
      this.createReportFormTemplate.summary_config = JSON.stringify(summaryConfig);
    },
  },
  async mounted() {
    await this.getTemplates();
    this.dataSources = await this.getDataSources();
    this.summaryConfig();
  },
};
</script>

<style lang="scss" scoped>
.content-modal-create-report {
  height: 542px;
  overflow-y: auto;
  padding-right: 12px;
}
</style>
