<template>
  <div class="simple-table">

    <div class="filters">
      <div v-if="showSearch" class="search-table">
        <span v-if="!showDateRange">{{ searchText }}</span>
        <el-input
          v-model="searchString"
          clearable
          size="medium"
          prefix-icon="el-icon-search"
          :placeholder="placeholderSearch"/>
      </div>
      <div v-if="showDateRange" class="date-range-table">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          size="medium"
          range-separator="To"
          start-placeholder="Start date"
          value-format="yyyy-MM-dd"
          end-placeholder="End date">
        </el-date-picker>
      </div>
    </div>

    <el-container class="container-table">
      <el-table
        v-loading="loading"
        :data="data"
        :row-class-name="tableRowClassName"
        :default-sort="{prop: 'id', order: 'descending'}"
        @row-click="rowClicked"
        class="invisible-scrollbars"
        >
        <!--<el-table-column v-if="firstColumn.show">
           <template>
             <i class="first-icon-table mdi" :class="firstColumn.icon"></i>
           </template>
         </el-table-column>-->
        <template v-for="(column, key)  in tableColumns">
          <el-table-column
            v-if="column?.isActions"
            :key="`column-${key}`"
            :prop="column.prop"
            :label="column.label"
            :sortable="column.sortable">
            <template slot-scope="scope">
              <template v-if="column.typeButtons === 'links'">
                <div v-for="btn in column.buttons" :key="btn.uuid">
                  <el-link
                    class="links-actions"
                    @click.stop="validateHandle(scope.$index, scope.row, btn.action)"
                  >{{ btn.text }}
                  </el-link>
                </div>
              </template>
              <template v-else-if="column.typeButtons === 'dropdowns'">
                <el-dropdown trigger="click">
                  <el-button size="small" class="dropdown-list">
                    Select<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="item in column.dropdown"
                      :key="item.uuid"
                      @click.stop="validateHandle(scope.$index, scope.row, item.action)">
                      {{ item.text }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template v-else-if="column.typeButtons === 'icons'">
                <el-tooltip
                v-for="item in column.dropdown" :key="item.uuid"
                :content="item.text" placement="top">
                  <el-link
                    class="icons-actions"
                    @click.stop="validateHandle(scope.$index, scope.row, item.action)"
                  ><i :class="`mdi ${iconAction(item.action)}`"></i>
                  </el-link>
                </el-tooltip>
              </template>
            </template>
          </el-table-column>

          <template v-else>
            <el-table-column
              v-if="column.format"
              :key="`column-${key}`"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable">
              <template slot-scope="scope">
                <span>{{ format(column.format, scope.row.createdAt) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :key="`column-${key.uuid}`"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable">
            </el-table-column>
          </template>
        </template>
      </el-table>
    </el-container>
  </div>
</template>

<script>

import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'SimpleTable',
  components: {},
  props: ['data', 'tableColumns', 'loading', 'firstColumn', 'searchText', 'showSearch', 'showDateRange', 'placeholderSearch'],
  data() {
    return {
      tableData: [],
      searchString: '',
      debounceSearch: null,
      dateRange: '',
    };
  },
  watch: {
    searchString() {
      this.debounceSearch();
    },
    dateRange() {
      this.emitDateRange();
    },
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      /* eslint-disable */
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    handleView(index, row) {
      // console.log(index, row);
      this.$emit('viewRow', row);
    },
    validateHandle(index, row, action) {
      if (action === 'view') {
        this.handleView(index, row);
      } else if (action === 'edit') {
        this.handleEdit(index, row);
      } else if (action === 'hide') {
        this.handleHide(index, row);
      } else if (action === 'delete') {
        this.handleDelete(index, row);
      }
    },
    rowClicked(row) {
      this.handleView(null, row);
    },
    handleEdit(index, row) {
      this.$root.$emit('editRow', { id: row.id, slug: row.slug ?? null, type: this.$route.name });
    },
    handleHide(index, row) {
      // console.log(index, row);
      this.$confirm('This will permanently hide the datasource. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.$root.$emit('hideRow', row.id);
      }).catch(() => {
      });

    },
    handleDelete(index, row) {
      // console.log(index, row);
      this.$confirm(`This will permanently delete <strong>"${row.name ?? row.title}"</strong>. Continue?`, 'Warning', {
        confirmButtonText: 'OK',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.$root.$emit('deleteRow', { id: row.id, slug: row.slug ?? null, type: this.$route.name });
      }).catch(() => {
      });

    },
    format(type, item) {
      if (type === 'fromNow') {
        return moment(item).fromNow();
      }
      if (type === 'date') {
        return moment(item).format('MM/DD/YY HH:mm');
      }
    },
    emitSearch() {
      this.$emit('searchRow', this.searchString)
    },
    emitDateRange() {
      const date = this.dateRange ? this.dateRange.toString() : null;

      this.$emit('dateRangeFilter', date);
    },
    handleResize(window) {
      if (window.width < 768) {
        this.firstColumn.show = false;
      } else {
        this.firstColumn.show = true;
      }
    },
    iconAction(action) {
      let icon = '';
      if (action === 'edit') {
        icon = 'mdi-pencil';
      }
      if (action === 'hide') {
        icon = 'mdi-eye-off';
      }
      if (action === 'delete') {
        icon = 'mdi-delete';
      }
      if (action === 'view') {
        icon = 'mdi-eye';
      }
      return icon;
    }
  },
  mounted() {
    this.debounceSearch = _.debounce(this.emitSearch, 500);

    this.handleResize({
      width: window.innerWidth,
      height: window.innerHeight
    });
    this.$root.$on('handleResize', this.handleResize);
    const th = document.getElementsByClassName('is-leaf');
    setTimeout(() => {
      if (th.length > 0) {
        for (let i = 0; i < th.length; i++) {
          if (i === th.length - 1) {
            th[i].style.cssText += 'text-align:center !important';
          }
        }
      }
    }, 10);

  },
};
</script>
<style lang="scss">
.simple-table {
  .search-table {
    width: 100%;
    // margin: 30px 0 25px;
    // @media (min-width: 720px) {
    //   width: 40%;
    // }

    // span {
    //   color: #868686;
    //   font-weight: 700;
    //   font-size: 20px;
    //   display: inline-block;
    //   margin-bottom: 7px;
    // }
    .el-input--medium{
      font-size: 16px;
    }

    // .el-input--mini .el-input__icon {
    //   color: #ECECEC;
    //   font-size: 15px;
    // }

    // input {
    //   // border: 2px solid #CBD2E0;
    //   // border-radius: 6px;
    // }
  }

  .el-range-editor--medium .el-range-input {
    font-size: 16px;
  }

  .el-range-separator{
    font-size: 16px;
  }

  .container-table {
    padding: 40px 0 !important;

    @media (min-width: 720px) {
      padding: 20px 50px 40px;
    }

    table {
      @media (max-width: 768px) {
        width: 560px !important;
      }

    }

    .el-table .sort-caret.ascending {
      border-bottom-color: #3FB5E5;
    }

    .el-table .sort-caret.descending {
      border-top-color: #3FB5E5;
    }

    .el-table {
      background-color: transparent;
      word-break: break-word;

      .cell {
        @media (max-width: 769px) {
          word-break: break-word;
        }

        a {
          .mdi-pencil, .mdi-eye-off {
            color: #363738;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
          }

          .mdi-delete {
            color: #FF3636;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
          }

          .mdi-eye {
            color: #363738;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
          }
        }

      }

      thead {
        color: #3FB5E5;
        font-weight: 700;
        line-height: 16px;
        background: #F8FDFF;
      }

      &__row {
        color: #313131;
        font-weight: 600;
        // font-size: 18px;
      }

      a {
        color: #214BEB;
        font-weight: 600;

        &:hover {
          color: #214BEB;
        }
      }

      .el-link.is-underline:hover:after {
        border-bottom: transparent;
      }

      th.el-table__cell {
        background-color: transparent;
      }

      th.el-table__cell:last-child {
        text-align: center !important;
      }

      tr {
        background-color: transparent;
      }

      .el-table__cell {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;

        &:last-child {
          text-align: center;
        }

        @media (max-width: 769px) {
          width: 100%;
          word-break: break-word;
        }

        .first-icon-table {
          font-size: 30px;
          color: #214BEB;
        }
      }
    }

    tbody {
      .cell {
        padding-left: 0px;
        padding-right: 0px;
      }

      tr {
        border-bottom: 1pt solid black;
      }

      td:first-child {
        border-left: 3px solid #FFFFFF;
      }

      tr:hover {
        background: #CACBCE30;
        cursor: pointer;

        td:first-child {
          border-left: 3px solid #3FB5E5;
        }
      }
    }

    .el-table__header {
      .cell {
        width: 100% !important;
        padding: 0px;
        @media (min-width: 720px) {
          width: 100% !important;
        }
      }

    }

    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: transparent;
    }

    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
      border-bottom: 0;
    }

    .el-table::before {
      width: 0;
      height: 0;
    }

    .el-table td.el-table__cell div {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #363738;
    }

    .dropdown-list {
      background: #313131;
      border-radius: 6px;
      color: #fff !important;
      font-weight: 600;
      letter-spacing: -0.01em;

      &:hover, &:focus {
        color: #fff;
        border-color: #313131;
        background-color: #313131;
      }
    }
  }

  .icons-actions {
    margin: 5px;

    i {
      font-size: 19px !important;
    }
  }

  .links-actions {
    span {
      color: #3FB5E5 !important;
    }
  }

  .filters {
    margin: 30px 0 0;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 844px) {
      display: grid;
    }
  }

}

</style>
