<template>
  <div class="data-source">
    <div class="button-create" v-if="isValidPermission('DATASOURCE_CREATE')">
      <el-button
      v-for="btn in buttonsList"
      :key="btn.uuid"
      class="fw-bold btn-secondary"
      @click="action(btn)"
      size="medium"><i :class="`mdi ${btn.icon}`"></i> {{ btn.text }}</el-button>
    </div>

    <simple-table
      :showSearch="(isValidPermission('DATASOURCE_SEARCH'))
      ? true : false"
      @viewRow="viewDataSource"
      @searchRow="searchDataSource"
      :searchText="'DataSource'"
      :data="dataSource"
      :tableColumns="columns"
      :loading="loading"
      :firstColumn="firstColumn"
      placeholderSearch="Search by date, datasource name or host"
      :showDateRange="false"
    ></simple-table>

    <simple-paginator
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :currentPage="paginator.currentPage"
      :pageSize="paginator.pageSize"
      :total="paginator.total"
      :pagerCount="5"
      layout="total, sizes, prev, pager, next, jumper"
      class="container"
    ></simple-paginator>

    <data-source-modal
      v-if="showModalCreate"
      :showModal="showModalCreate"
      :state="state"
      :brandId="brandId"
      :id="dataSourceID"
      :title="title">
    </data-source-modal>

    <view-modal
      v-if="showModalView"
      :showModal="showModalView"
      :data="dataSourceItem">
    </view-modal>
  </div>
</template>

<script>

import ApiMILO from '@/lib/ApiMILO';
import { mapState, mapGetters } from 'vuex';
import Utils from '@/lib/Utils';
import SimpleTable from '@/components/Tables/SimpleTable.vue';
import DataSourceModal from '@/components/Modals/dataSources/DataSourceModal.vue';
import ViewModal from '@/components/Modals/ViewModal.vue';
import SimplePaginator from '@/components/Paginator/SimplePaginator.vue';

export default {
  name: 'DataSourcesList',
  components: {
    ViewModal,
    DataSourceModal,
    SimpleTable,
    SimplePaginator,
  },
  props: ['brandId'],
  data() {
    return {
      Utils,
      buttonsList: [
        {
          text: 'New DataSource',
          actionType: 'showModalCreate',
          icon: 'mdi-plus',
        },
      ],
      loading: true,
      showModalCreate: false,
      showModalEdit: false,
      showModalView: false,
      dataSource: [],
      dataSourceItem: {},
      brand: {},
      state: 'create',
      title: 'New DataSource',
      dataSourceID: null,
      breadcrumb: [
        {
          string: 'Brands',
          active: false,
        },
        {
          imgUrl: '',
          active: false,
          type: 'image',
        },
        {
          string: 'Configure DataSources',
          active: true,
        },
      ],
      columns: [
        {
          prop: 'createdAt',
          label: 'Created Date',
          sortable: true,
          format: 'fromNow',
        },
        {
          prop: 'name',
          label: 'Name',
          sortable: true,
        },
        // {
        //   prop: 'host',
        //   label: 'Host',
        //   sortable: false,
        // },
        // {
        //   prop: 'database',
        //   label: 'Database',
        //   sortable: false,
        // },
        {
          prop: 'actions',
          label: 'Actions',
          sortable: false,
          isActions: true,
          typeButtons: 'icons',
          dropdown: [],
        },
      ],
      firstColumn: {
        show: true,
        icon: 'mdi-chart-box-outline',
      },
      paginator: {
        currentPage: 0,
        pageSize: 10,
        total: 0,
        page: 1,
      },
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isValidPermission']),
    brandName() {
      const { brand } = this;
      const result = brand.name;
      return result;
    },
  },
  methods: {
    action(conf) {
      if (conf.actionType === 'showModalCreate') {
        this.$root.$emit('openModalCreate');
      }
      if (conf.actionType === 'showModalCreateFromTemplate') {
        this.$root.$emit('openModalCreateFromTemplate');
      }
      if (conf.actionType === 'goToRoute') {
        this.$router.push({ name: conf.routeName });
      }
    },
    getAll(search = null) {
      this.dataSource = [];
      this.loading = true;
      const paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}`;

      let url = `/brands/${this.brandId}/datasources?${paginate}`;

      if (search) {
        url = `/brands/${this.brandId}/datasources?${paginate}&search=${search}`;
      }
      ApiMILO.get(url).then((r) => {
        this.dataSource = Object.values(r.data);
        if (r.paginator.total_pages) {
          this.paginator.currentPage = r.paginator.page;
          this.paginator.page_size = r.paginator.page_size;
          this.paginator.total = r.paginator.count;
        }
        this.loading = false;
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },
    closeModal() {
      this.showModalView = false;
      this.showModalEdit = false;
      this.showModalCreate = false;
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.state = 'create';
      this.title = 'New DataSource';
    },
    viewDataSource(row) {
      const dataSourceItem = [];
      this.showModalView = true;
      dataSourceItem.push({
        name: row.name,
        host: row.host,
        user: row.user,
        database: row.database,
      });
      const [arr] = dataSourceItem;
      this.dataSourceItem = arr;
      // console.log('-> row', row);
    },
    editDataSource(data) {
      if (data.type === 'DataSources' || data.type === 'BrandEdit') {
        this.showModalCreate = true;
        this.state = 'update';
        this.title = this.brandName;
        this.dataSourceID = data.id;
      }
    },
    async hideDataSource(id) {
      try {
        const r = await ApiMILO.put(`/brands/${this.brandId}/datasources/${id}`, { enabled: false });
        if (r.success) {
          this.getAll();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteDataSource(data) {
      const { id, type } = data;
      try {
        if (type === 'BrandEdit') {
          const r = await ApiMILO.delete(`/brands/${this.brandId}/datasources/${id}`);
          if (r.success) {
            this.$message({
              message: 'Congrats, DataSource deleted.',
              type: 'success',
            });

            this.getAll();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getBrand() {
      const { data } = await ApiMILO.getOne(`/brands/${this.brandId}`);

      return data;
    },
    searchDataSource(string) {
      this.getAll(string);
    },
    validatePermissions() {
      if (this.user && this.isValidPermission('DATASOURCE_VIEW')) {
        const actions = this.columns.findIndex((btn) => btn.prop === 'actions');
        this.columns[actions].dropdown.push({
          text: 'View',
          action: 'view',
        });
      }

      if (this.user && this.isValidPermission('DATASOURCE_EDIT')) {
        const actions = this.columns.findIndex((btn) => btn.prop === 'actions');
        this.columns[actions].dropdown.push({
          text: 'Edit',
          action: 'edit',
        });
      }

      // if (this.user && this.isValidPermission('DATASOURCE_HIDE')) {
      //   const actions = this.columns.findIndex((btn) => btn.prop === 'actions');
      //   this.columns[actions].dropdown.push({
      //     text: 'Hide',
      //     action: 'hide',
      //   });
      // }

      if (this.user && this.isValidPermission('DATASOURCE_DELETE')) {
        const actions = this.columns.findIndex((btn) => btn.prop === 'actions');
        this.columns[actions].dropdown.push({
          text: 'Delete',
          action: 'delete',
        });
      }
    },
    currentChange(val) {
      this.paginator.page = val;
      this.getAll();
    },
    sizeChange(val) {
      this.paginator.pageSize = val;
      this.paginator.handlesSizeChange = true;
      this.getAll();
    },
  },
  async mounted() {
    if (this.brandId) {
      this.brand = await this.getBrand();
      this.breadcrumb[0].string = this.brand.name;
      this.breadcrumb[1].imgUrl = this.brand.logo;
      this.getAll();
    }

    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('openModalCreate', this.openModalCreate);
    this.$root.$on('reloadDataSources', this.getAll);

    // TABLE EMITS
    this.$root.$on('editRow', this.editDataSource);
    this.$root.$on('hideRow', this.hideDataSource);
    this.$root.$on('deleteRow', this.deleteDataSource);

    this.validatePermissions();
  },
};
</script>

<style lang="scss">
  .button-create{
    position: absolute;
    right: 0;
    top: 71px;
  }
  .search-table {
    position: relative;
    width: 60% !important;

    span {
      color: #868686;
      font-weight: 700;
      font-size: 20px;
      display: inline-block;
      margin-bottom: 21px;
    }
    .el-input--medium{
      font-size: 16px;
    }

    .el-input--medium .el-input__icon {
      color: #ECECEC;
      font-size: 16px;
    }

    // input {
    //   border: 2px solid #CBD2E0;
    //   border-radius: 6px;
    // }
  }

  .button-create {
    text-align: end;
  }
  .data-source-navbar{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto ;
  background: rgba(63, 181, 229, 0.08);
  height: 145px;
  align-content: center;
  padding: 0 30px;

  @media (min-width: 720px){
    padding: 0 90px;
    grid-template-rows: auto;
    grid-template-columns: auto auto ;
  }

  .principal-bar{
    justify-self: end;
    column-gap: 10px !important;
    @media (min-width: 720px){
      column-gap: 20px;
    }

    .title{
      display: block;
      @media (min-width: 720px){
        display: none;
      }

      h1{
        font-size: 20px;
      }
    }
  }

  .el-breadcrumb__item:first-child{
    display: none;
  }
}
</style>
