<template class="nav-bar">
  <div>
    <div class="header fixed-top" style="height: auto; z-index: 2001">
      <el-header>
        <el-menu
          background-color="#313131"
          text-color="#FFFFFF"
          active-text-color="#FFFFFF"
          :default-active="activeMenu"
          class="el-menu-demo navbar-milo"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
        >
          <el-menu-item index="false" disabled class="logo">
            <img
              class="nav_logo"
              style="cursor: pointer"
              @click="toHome"
              @keypress="toHome"
              :src="logo"
              alt="nav_logo"
            />
          </el-menu-item>
          <el-menu-item class="home desktop cursor-pointer d-flex fr bb-none mb-menu" index="/">Home
          </el-menu-item>

          <el-menu-item disabled class="cursor-pointer d-flex fr bb-none admin">
            <avatar
              v-if="!this.user.image"
              :fullname="user.name"
              class="avatar-text"
              color="#214BEB"
            ></avatar>

            <el-avatar
              v-else
              shape="circle"
              fit="cover"
              :size="50"
              :src="user.image"
            ></el-avatar>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link d-flex">
                <div id="v-step-0" class="user-info">
                  <div class="logout d-flex">
                    <div class="username">{{ user.name }}</div>
                    <!-- <div class="status">Enabled / Disabled</div> -->
                  </div>
                  <div>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </div>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown" class="popper right">
                <el-dropdown-item command="mange-2fa"
                >Manage Profile
                </el-dropdown-item
                >
                <el-dropdown-item command="reset"
                >Reset Password
                </el-dropdown-item
                >
                <el-dropdown-item command="logout" class="logout-button"
                >Logout
                </el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
          <!-- </div> -->
        </el-menu>
      </el-header>
    </div>
    <reset-password-modal
      v-if="showResetPassword"
      :showModal="showResetPassword"
      title="Reset Password"
    >
    </reset-password-modal>

    <v-tour
      name="myTour"
      :steps="steps"
      :callbacks="myCallbacks"
      :options="myOptions"
    ></v-tour>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import logo from '@/assets/milo_icon_white.png';
import Avatar from 'vue-avatar-component';
import ResetPasswordModal from '@/components/Modals/ResetPasswordModal.vue';

export default {
  name: 'NavBar',
  props: [],
  components: {
    Avatar,
    ResetPasswordModal,
  },
  data() {
    return {
      logo,
      activeMenu: '/',
      showResetPassword: false,
      showManage2FA: false,
      steps: [
        {
          target: '#v-step-0',
          // header: {
          //   title: 'ENABLE TWO FACTOR AUTHENTICATION',
          // },
          content: 'For added security, it is highly encouraged to enable <strong>two factor authentication</strong>.<br>Enable it here.',
          params: {
            highlight: false,
          },
        },
        {
          target: '#v-step-1',
        },
      ],
      myCallbacks: {
        onNextStep: this.finishTourCallback,
      },
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Later',
          buttonNext: 'Activate Now',
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapState(['token', 'user']),
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setPermissions', 'setRefreshToken', 'setUUID']),
    setRoute() {
      this.activeMenu = this.$route.fullPath;
    },
    handleSelect(key) {
      this.activeMenu = key;
    },
    logout() {
      this.setUser(null);
      this.setPermissions(null);
      this.setToken(null);
      this.setRefreshToken(null);
      this.setUUID(null);
      // this.$router.push({ path: '/login' });
      window.location.href = '/';
    },
    toHome() {
      if (window.location.pathname !== '/') {
        this.activeMenu = '/';
        this.$router.push({ path: '/' });
      }
    },
    handleCommand(command) {
      if (command === 'reset') {
        this.showResetPassword = true;
      } else if (command === 'logout') {
        this.logout();
      } else if (command === 'new-user') {
        console.log('new-user');
      } else if (command === 'manage-user') {
        console.log('manage-user');
      } else if (command === 'mange-2fa') {
        // this.showManage2FA = true;
        if (this.$route.path !== '/profile') {
          this.$router.push({ path: '/profile' });
        }
      } else if (command === 'home') {
        this.$router.push({ path: '/' });
        console.log('mange-2fa');
      }
    },
    closeModal() {
      this.showResetPassword = false;
      this.showManage2FA = false;
    },
    finishTourCallback() {
      // this.showManage2FA = true;
      if (this.$route.path !== '/profile') {
        this.$router.push({ path: '/profile' });
      }
    },
  },
  mounted() {
    this.setRoute();
    this.$root.$on('closeModal', this.closeModal);

    if (!this.user.confirmedTwoFA) {
      this.$tours.myTour.start();
    }
  },
};
</script>
<style lang="scss" scoped>
* {
      box-sizing: border-box;
      // border: 1px solid red;
    }
.nav_logo {
  transition: all 125ms;

  &:hover {
    cursor: pointer;
    opacity: 0.4;
  }

}

.logo {
  display: grid;
  justify-content: center;
  @media (min-width: 720px) {
    justify-content: start;
  }
}

.logout-button {
  width: 100%;
  text-align: center;
}

.header,
.el-header {
  height: auto !important;
}

.el-header {
  .navbar-milo {
    padding: 0 0 0 25px;
    // border-radius: 0 0 15px 15px;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    height: 77px;
    background-color: #3FB5E5 !important;
    @media (min-width: 720px) {
      padding: 0 0 0 81px;
    }
  }
}

.el-menu-item {
  padding-left: 0;
  background-color: #3FB5E5 !important;
}

.el-menu-item:last-child {
  background: #299BC9 !important;
}

.admin {
  padding-right: 0;
  display: grid;
  justify-content: end;
  padding: 0 25px 0 0;
  @media (min-width: 720px) {
    column-gap: 10px !important;
    padding: 0 54px 0 54px;
  }
}

.el-dropdown-link {
  .mb {
    display: block;
    @media (min-width: 720px) {
      display: none;
    }

    span {
      display: block;
      width: 20px;
      height: 3px;
      right: 0;
      margin-bottom: 3px;
      background: white;
      border-radius: 2px;
    }
  }
}

.desktop {
  display: none;
  @media (min-width: 720px) {
    display: block;
  }
}

.avatar {
  margin-right: 10px;
}

.el-menu--horizontal > .el-menu-item {
  display: flex;
  align-items: center;
  height: 77px;
}

span {
  font-size: 18px;
  font-family: 'Work Sans';
  font-weight: 400;
  color: #FFFFFF;

  .el-icon-arrow-down {
    color: #FFFFFF;
  }
}

.mb-home {
  display: block;
  @media (min-width: 720px) {
    display: none;
  }

}
li.el-menu-item.home {
  margin-right: 25px;
}
.popper {
  width: 100%;
  text-align: center;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Work Sans';
  font-weight: 400;
  top: 65px !important;
  margin-left: -5px !important;
  @media (min-width: 720px) {
    width: 20%;
    margin-left: 0px !important;
    margin-right: -22px !important;
  }
}

.right {
  @media (min-width: 720px) {
    width: 20%;
    margin-left: 0px !important;
    margin-right: -54px !important;
  }
}

.navbar-milo {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto;
  @media (min-width: 720px) {
    grid-template-columns: 0 max-content auto max-content;
  }
}

.mb-menu {
  order: -1;
  @media (min-width: 720px) {
    order: 0;
    display: grid;
    justify-content: end;
    column-gap: 54px;
  }
}

.home {
  background-color: #3FB5E5 !important;
  font-size: 18px;
  font-family: 'Work Sans';
  font-weight: 700;
}

.user-info {
  .logout {
    display: none;
    @media (min-width: 720px) {
      display: block;
    }

    .username {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
    }
  }
}

</style>
