<template>
  <div class="reset-password-modal">
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        label-position="top"
        :model="resetPasswordForm"
        status-icon
        :rules="rules"
        ref="resetPasswordForm"
        label-width="130px"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <el-form-item label="New Password" prop="newPassword">
          <el-input
            :type="showPassword"
            v-model="resetPasswordForm.newPassword"
            autocomplete="off"
          >
            <el-button
              @click="handleShowPassword"
              slot="append"
              icon="el-icon-view">
            </el-button>
          </el-input>
        </el-form-item>

        <el-form-item label="Confirm Password" prop="confirmPassword">
          <el-input
            :type="showPassword"
            v-model="resetPasswordForm.confirmPassword"
            autocomplete="off"
          >
            <el-button
              @click="handleShowPassword"
              slot="append"
              icon="el-icon-view">
            </el-button>
          </el-input>
        </el-form-item>
        <div class="footer">
          <el-form-item>
            <el-button
              class="btn-secondary cancel"
              type="info"
              @click="handleClose()"
              @keypress="handleClose()"
            >
              Cancel
            </el-button>
            <el-button
              class="btn-secondary submit"
              :loading="loading"
              type="info"
              @click="resetPassword('resetPasswordForm')"
              @keypress="resetPassword('resetPasswordForm')"
            >
              Submit
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApiSSO from '../../lib/ApiSSO';

export default {
  name: 'ResetPasswordModal',
  components: {},
  props: ['showModal', 'title'],
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the password again'));
      } else if (value !== this.resetPasswordForm.newPassword) {
        callback(new Error("Two passwords doesn't match!"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the password'));
      } else {
        // Check if password is at least 12 characters long
        if (value.length < 12) {
          callback(new Error('Check if password is at least 12 characters long'));
        }

        // Check if password contains at least one lowercase letter
        if (!/[a-z]/.test(value)) {
          callback(new Error('Check if password contains at least one lowercase letter'));
        }

        // Check if password contains at least one uppercase letter
        if (!/[A-Z]/.test(value)) {
          callback(new Error('Check if password contains at least one uppercase letter'));
        }

        // Check if password contains at least one number
        if (!/[0-9]/.test(value)) {
          callback(new Error('Check if password contains at least one number'));
        }

        // Check if password contains at least one special character
        if (!/[\W]/.test(value)) {
          callback(new Error('Check if password contains at least one special character'));
        }

        callback();
      }
    };
    return {
      showPassword: 'password',
      dialogVisible: this.showModal,
      loading: false,
      resetPasswordForm: {
        newPassword: '',
        confirmPassword: '',
      },
      rules: {
        newPassword: [
          {
            validator: validatePass,
            required: true,
            trigger: 'blur',
          },
        ],
        confirmPassword: [
          {
            validator: validatePass2,
            type: 'string',
            required: true,
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    handleShowPassword() {
      if (this.showPassword === 'password') {
        this.showPassword = 'text';
      } else {
        this.showPassword = 'password';
      }
    },
    handleClose() {
      this.$root.$emit('closeModal');
    },
    resetPassword(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.sendReset();
        }
        this.loading = false;
        return false;
      });
    },
    sendReset() {
      const data = {
        id: this.user.id,
        password: this.resetPasswordForm.newPassword,
      };

      ApiSSO.put('/users', data)
        .then((r) => {
          if (r) {
            if (r.success) {
              this.loading = false;
              this.dialogVisible = false;
              this.resetForm('resetPasswordForm');
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.reset-password-modal {
  .footer {
    height: 80px;
    background: #FFFFFF;
    border-top: 1px solid #E5E5E5;
    margin-top: 50px;
    display: flex;
    justify-content: end;
    padding-top: 20px;

    .el-form-item__content {
      display: flex;
    }

    .cancel {
      width: 96px;
      height: 36px;
      background: #F1F1F1;
      border-radius: 4px;
      border: 0;
      color: #363738;
    }

    .submit {
      width: 134px;
      height: 36px;
      background: #3FB5E5;
      border-radius: 4px;
      border: 0;
      color: #FFFFFF;
    }
  }
}
</style>
