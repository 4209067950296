<template>
  <div :style="cssVars" class="widget-label">
    <h1>{{ value }}</h1>
  </div>
</template>

<script>
import Utils from '@/lib/Utils';

export default {
  name: 'WidgetLabel',
  props: ['config'],
  data() {
    return {};
  },
  mounted() {
    this.$emit('dataRefresh', this.config.id);
  },
  computed: {
    value() {
      const value = Utils.getSetting('general', 'value', this.config.widget_settings_categories);
      // this.$root.$emit('widgetLabel', value !== '');
      return value;
    },
    fontSize() {
      return Utils.getSetting('general', 'font_size', this.config.widget_settings_categories);
    },
    margin() {
      return Utils.getSetting('general', 'margin_(t, r, b, l)', this.config.widget_settings_categories);
    },
    padding() {
      return Utils.getSetting('general', 'padding_(t, r, b, l)', this.config.widget_settings_categories);
    },
    cssVars() {
      return {
        'font-size': this.fontSize,
        margin: this.margin,
        padding: this.padding,
      };
    },
  },
};
</script>
