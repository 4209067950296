<template>
  <div class="widget-filter-bar">
    <div class="content-container" v-if="renderComponent">
      <template v-for="filter in filters">
        <template v-if="filter.name === 'custom_filters'">
          <template v-for="(customFilter, index) in JSON.parse(filter.value)">
            <div :key="customFilter.uuid" :class="filter.name">
              <template v-if="customFilter.filterType === 'dropDown'">
                <el-select
                  :loading="loading"
                  @input="
                    emitFilter(
                      inputFilter[`${customFilter.filterType}_${index}`],
                      customFilter.columns,
                      customFilter.filterType
                    )
                  "
                  :multiple="customFilter.is_multiple"
                  v-model="inputFilter[`${customFilter.filterType}_${index}`]"
                  filterable
                  size="mini"
                  clearable
                  @change="
                    handleAffectsTo(
                      customFilter,
                      inputFilter[`${customFilter.filterType}_${index}`],
                      index
                    )
                  "
                  :placeholder="`Select ${customFilter.columns}`"
                >
                  <el-option
                    v-for="item in selectOptions[
                      `${customFilter.filterType}_${index}`
                    ] ?? []"
                    :key="item.id"
                    :label="item.name ?? item.title"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-if="customFilter.filterType === 'check'">
                <el-checkbox
                  @input="
                    emitFilter(
                      inputFilter[customFilter.filterType],
                      customFilter.columns,
                      customFilter.filterType
                    )
                  "
                  v-model="inputFilter[customFilter.filterType]"
                  :label="`Filter ${customFilter.columns}`"
                  border
                  size="mini"
                >
                </el-checkbox>
              </template>
              <template v-if="customFilter.filterType === 'dateRange'">
                <el-date-picker
                  v-model="inputFilter[customFilter.filterType]"
                  @input="
                    emitFilter(
                      inputFilter[customFilter.filterType],
                      customFilter.columns,
                      customFilter.filterType
                    )
                  "
                  type="daterange"
                  size="mini"
                  align="right"
                  unlink-panels
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </template>
            </div>
          </template>
        </template>

        <div
          :key="filter.uuid"
          v-if="filter.name !== 'show/hide' && filter.value == 'true'"
          :class="filter.name"
        >
          <template v-if="filter.name === 'search'">
            <el-input
              v-model="searchString"
              clearable
              size="mini"
              placeholder="Search by name, last name, email or lenguaje"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </template>

          <template v-if="filter.name === 'date_range'">
            <el-date-picker
              v-model="dateRangeValue"
              @change="emitSelectDateRange"
              type="daterange"
              size="mini"
              align="right"
              unlink-panels
              format="MMMM dd, yyyy"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </template>

          <template v-if="filter.name === 'geographical_location'">
            <el-select
              @change="emitSelectState"
              v-model="stateValue"
              filterable
              size="mini"
              clearable
              placeholder="Select state"
            >
              <el-option
                v-for="state in states"
                :key="state.isoCode"
                :label="state.name"
                :value="state.isoCode"
              >
              </el-option>
            </el-select>
          </template>

          <template v-if="filter.name === 'opt_in'">
            <el-checkbox
              @change="emitSelectOptIn"
              v-model="optInValue"
              label="Filter Optins"
              border
              size="mini"
            >
            </el-checkbox>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { State } from 'country-state-city';
import _ from 'lodash';
import ApiMILO from '@/lib/ApiMILO';

export default {
  name: 'WidgetFilterBar',
  props: ['config', 'renderComponent', 'allWidgetsLoaded'],
  data() {
    return {
      loading: false,
      searchString: '',
      debounceSearch: null,
      dateRangeValue: '',
      stateValue: '',
      optInValue: false,
      inputFilter: {},
      selectOptions: {},
    };
  },
  watch: {
    searchString() {
      this.debounceSearch();
    },
  },
  computed: {
    pickerOptions() {
      return {
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime());
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Yesterday',
            onClick(picker) {
              const today = new Date();
              const yesterday = new Date(today);
              yesterday.setDate(yesterday.getDate() - 1);
              picker.$emit('pick', [yesterday, yesterday]);
            },
          },
          {
            text: 'Current week',
            onClick(picker) {
              const curr = new Date();
              const first = curr.getDate() - curr.getDay();
              const last = first + 6;

              const firstDay = new Date(curr.setDate(first));
              const lastDay = new Date(curr.setDate(last));
              picker.$emit('pick', [firstDay, lastDay]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Current month',
            onClick(picker) {
              const date = new Date();
              const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
              const lastDay = new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                0,
              );
              picker.$emit('pick', [firstDay, lastDay]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      };
    },
    states() {
      const states = State.getStatesOfCountry('US');
      return states;
    },
    filters() {
      const categoryFound = this.config.widget_settings_categories.find(
        (cat) => cat.name.toLowerCase().trim() === 'general',
      );

      if (!categoryFound) {
        return null;
      }

      return categoryFound.widget_settings;
    },
  },
  methods: {
    emitSelectState(value) {
      this.$root.$emit('applyFilters', { type: 'search', value });
    },
    emitSelectDateRange(value) {
      this.$root.$emit('applyFilters', { type: 'dateRange', value });
    },
    emitSelectOptIn(value) {
      this.$root.$emit('applyFilters', { type: 'optIn', value });
    },
    emitSearch() {
      this.$root.$emit('applyFilters', {
        type: 'search',
        value: this.searchString,
      });
    },
    emitFilter(value, columns, type) {
      this.$root.$emit('applyFilters', { value, columns, type });
    },
    async setOptionsSelect() {
      this.loading = true;
      // eslint-disable-next-line
      /* eslint-disable */
      for (const category of this.config.widget_settings_categories) {
        for (const setting of category.widget_settings) {
          if (setting.name === "custom_filters") {
            const filters = JSON.parse(setting.value);
            for (const [index, filter] of filters.entries()) {
              if (filter.filterType === "dropDown") {
                const data = {
                  dataSource: filter.dataSource,
                  query: filter.query,
                };
                const result = await this.getSelectData(data);
                this.$set(
                  this.selectOptions,
                  `${filter.filterType}_${index}`,
                  result
                );
              }
            }
          }
        }
      }
      this.loading = false;
    },
    async getSelectData(data) {
      try {
        const res = await ApiMILO.post("/widget/getQuery", data);
        return res.data.data ?? [];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async handleAffectsTo(filter, selected) {
      this.loading = true;
      await this.setOptionsSelect();

      const { affectsTo } = filter;

      if (affectsTo && selected.length > 0) {
        const ArrayOptions =
          this.selectOptions[`${affectsTo}_before`] ??
          this.selectOptions[affectsTo];
        const newArrayAffects = ArrayOptions.filter((item) =>
          selected.includes(item.dependsOn)
        );
        this.$set(this.selectOptions, affectsTo, newArrayAffects);
      }
      this.loading = false;
    },
    currentWeek() {
      const curr = new Date();
      const first = curr.getDate() - curr.getDay();
      const last = first + 6;

      const firstDay = new Date(curr.setDate(first));
      const lastDay = new Date(curr.setDate(last));
      return [firstDay, lastDay];
    },
    formatDate(date) {
      const d = new Date(date);
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },
  async mounted() {
    this.debounceSearch = _.debounce(this.emitSearch, 500);
    await this.setOptionsSelect();
    this.$emit("dataRefresh", this.config.id);

    const currentWeek = this.currentWeek();

    this.dateRangeValue = currentWeek;

    // const [ startDate, endDate ] = currentWeek;
    // // Convertir las fechas a formato 'yyyy-MM-dd'
    // const formattedStartDate = this.formatDate(startDate);
    // const formattedEndDate = this.formatDate(endDate);
    // this.emitSelectDateRange([formattedStartDate, formattedEndDate]);
  },
};
</script>

<style  lang="scss">
.widget-filter-bar {
  .el-input--mini .el-input__inner {
    height: 32px;
  }

  .el-range-editor--mini.el-input__inner {
    height: 32px;
  }

  .el-checkbox.is-bordered.el-checkbox--mini {
    height: 32px;
  }

  @media (min-width: 720px) {
    display: flex;
    justify-content: center;
  }

  .content-container {
    display: flex;
    justify-content: space-around;
    column-gap: 10px;

    .search {
      width: 320px;
      max-width: 100%;
    }

    .opt_in {
      label {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          display: flex;
        }
      }
    }

    @media (max-width: 720px) {
      flex-direction: column;
      row-gap: 10px;

      .el-range-editor--mini.el-input__inner {
        flex-direction: column;
        height: 86px;
        width: 100%;
        align-items: center;
        gap: 2px;
      }

      .el-date-editor .el-range-separator {
        padding: 0;
        width: 100%;
      }

      .el-checkbox.is-bordered.el-checkbox--mini {
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
