<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="report-detail-container">
    <div>
      <div :style="`background:${widgetColor[0]}40;`" class="report-navbar" :gutter="20">
        <breadcrumb-view :items="breadcrumb"></breadcrumb-view>
        <report-bar :bgColor="widgetColor[0]" :data="{}" :btnData="btnData"></report-bar>
      </div>

      <el-row class="mx-auto container" :gutter="20">
        <el-col :span="24" class="col-widgets">
          <template>
            <h1
              :style="`color: ${fontColor};
            font-family: ${fontName};
            font-size: ${fontSizeText} !important`"
            >
              Filters
            </h1>
            <div class="filters">
              <template>
                <el-input
                  readonly
                  clearable
                  size="mini"
                  placeholder="Search by name, last name, email or lenguaje"
                >
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </template>

              <template>
                <el-date-picker
                  readonly
                  type="daterange"
                  size="mini"
                  align="right"
                  unlink-panels
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </template>
            </div>
          </template>
        </el-col>
      </el-row>
      <el-divider content-position="center" class="mb-4 mt-4"
        ></el-divider>
      <el-row class="mx-auto container" :gutter="20">
        <el-col :span="12" class="col-widgets">
          <template>
            <div class="widget-scalar" :style="`background: ${backgroundWidgetColor}`">
              <div class="content-container">
                <div class="icon">
                  <i
                    class="mdi mdi-account"
                    :style="`color: ${fontColor};`"
                  ></i>
                </div>
                <div class="text-container">
                  <h1
                    :style="`color: ${fontColor};
                    font-family: ${fontName};
                    font-size: ${fontSizeText} !important`"
                  >
                    11,443
                  </h1>
                  <span
                    :style="`font-family: ${fontName};`"
                    >Total Entries</span
                  >
                </div>
              </div>
            </div>
          </template>
        </el-col>
        <el-col :span="12" class="col-widgets">
          <template>
            <div class="widget-scalar" :style="`background: ${backgroundWidgetColor}`">
              <div class="content-container">
                <div class="icon">
                  <i
                    class="mdi mdi-account"
                    :style="`color: ${fontColor};`"
                  ></i>
                </div>
                <div class="text-container">
                  <h1
                    :style="`color: ${fontColor};
                  font-family: ${fontName};
                  font-size: ${fontSizeText} !important`"
                  >
                    3,976
                  </h1>
                  <span
                    :style="`font-family: ${fontName};`"
                    >Total Optins</span
                  >
                </div>
              </div>
            </div>
          </template>
        </el-col>
      </el-row>

      <el-row class="mx-auto container" :gutter="20">
        <el-col :span="12" class="col-widgets">
          <template>
            <div class="widget-scalar" :style="`background: ${backgroundWidgetColor}`">
              <div class="content-container">
                <div class="icon" :style="`color: ${fontColor};`">
                  <i class="mdi mdi-account"></i>
                </div>
                <div class="text-container">
                  <h1
                    :style="`color: ${fontColor};
                  font-family: ${fontName};
                  font-size: ${fontSizeText} !important`"
                  >
                    123K
                  </h1>
                  <span
                    :style="`font-family: ${fontName};`"
                    >Bonus Entries</span
                  >
                </div>
              </div>
            </div>
          </template>
        </el-col>
        <el-col :span="12" class="col-widgets">
          <template>
            <div class="widget-scalar" :style="`background: ${backgroundWidgetColor}`">
              <div class="content-container">
                <div class="icon">
                  <i
                    class="mdi mdi-account"
                    :style="`color: ${fontColor};`"
                  ></i>
                </div>
                <div class="text-container">
                  <h1
                    :style="`color: ${fontColor};
                  font-family: ${fontName};
                  font-size: ${fontSizeText} !important`"
                  >
                    55K
                  </h1>
                  <span
                    :style="`font-family: ${fontName};`"
                    >Referral Entries</span
                  >
                </div>
              </div>
            </div>
          </template>
        </el-col>
      </el-row>

      <el-row class="mx-auto container" :gutter="20">
        <el-col
          :style="`background: ${backgroundWidgetColor}`" :span="24"
          class="col-widgets paragraph">
          <template>
            <h1
              :style="`color: ${fontColor};
            font-family: ${fontName};
            font-size: ${fontSizeText} !important`"
            >
              Entries
            </h1>
            <p :style="`font-family: ${fontName}; font-size: ${fontSizeText} !important`">
              Laoreet neque in tellus sit. Volutpat et nec in duis urna tortor
              lorem a. Id amet dignissim.
            </p>
          </template>
        </el-col>
      </el-row>

      <el-row class="mx-auto container bar-chart" :gutter="20">
        <el-col :style="`background: ${backgroundWidgetColor}`" :span="24" class="col-widgets">
          <template>
            <h1
              :style="`color: ${fontColor};
            font-family: ${fontName};
            font-size: ${fontSizeText} !important`"
            >
              Top entries 10
            </h1>

            <bar-chart
              :stacked="true"
              :colors="[widgetColor]"
              :data="chartData"
            ></bar-chart>
          </template>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ReportBar from '@/components/Bars/ReportBar.vue';
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import ImgNotFound from '@/assets/img_not_found.png';
import Utils from '@/lib/Utils';

export default {
  name: 'ReportTemplate',
  props: ['brand'],
  components: {
    ReportBar,
    BreadcrumbView,
  },
  data() {
    return {
      Utils,
      report: null,
      widgets: [],
      ImgNotFound,
      // widgetBar,
      widgetTypes: [],
      dataBar: [
        {
          key: 'California',
          value: 938,
        },
        {
          key: 'Florida',
          value: 830,
        },
        {
          key: 'Texas',
          value: 816,
        },
        {
          key: 'New York',
          value: 732,
        },
        {
          key: 'Pennsylvania',
          value: 577,
        },
        {
          key: 'Illinois',
          value: 516,
        },
        {
          key: 'Ohio',
          value: 500,
        },
        {
          key: 'Michigan',
          value: 462,
        },
        {
          key: 'North Carolina',
          value: 425,
        },
        {
          key: 'Georgia',
          value: 348,
        },
      ],
      btnData: {
        show: true,
        classes: 'btn-milo',
        text: 'Copy Embed Code',
        icon: 'mdi-link-variant',
        actionType: 'openModal',
      },
      breadcrumb: [
        {
          imgUrl: '',
          active: false,
          type: 'image',
        },
        {
          string: 'The Home Edition',
          active: true,
        },
      ],
      automaticReloadTimer: null,
    };
  },
  computed: {
    widgetColor() {
      if (this.brand) {
        const defaultColor = Utils.getSettingBrand(
          'colors',
          'default_widget_color',
          this.brand.setting_categories,
        );
        return [defaultColor];
      }
      return '#000';
    },
    fontColor() {
      if (this.brand) {
        const defaultColor = Utils.getSettingBrand(
          'colors',
          'widget_title_font_color',
          this.brand.setting_categories,
        );
        return defaultColor;
      }
      return '#000';
    },
    backgroundWidgetColor() {
      if (this.brand) {
        const defaultColor = Utils.getSettingBrand(
          'colors',
          'default_widget_background',
          this.brand.setting_categories,
        );
        return defaultColor;
      }
      return '#000';
    },
    fontName() {
      if (this.brand) {
        const font = Utils.getSettingBrand(
          'fonts',
          'font_name',
          this.brand.setting_categories,
        );
        return `'${font}'`;
      }
      return 'Helvetica';
    },
    fontSizeText() {
      if (this.brand) {
        const size = Utils.getSettingBrand(
          'fonts',
          'font_size',
          this.brand.setting_categories,
        );
        return size;
      }
      return '14px';
    },
    chartData() {
      if (!this.dataBar) {
        return [];
      }
      const { dataBar } = this;

      if (!dataBar) {
        return [];
      }

      const key = 'key';
      const value = 'value';
      if (!key || !value) {
        return [];
      }

      const result = dataBar.map((row) => {
        const arr = [row[key], row[value]];

        return arr;
      });

      return result;
    },
  },
  methods: {},
  mounted() {
    if (this.brand) {
      this.breadcrumb[0].imgUrl = this.brand.logo;
      // this.breadcrumb[1].string = this.brand.name;
    }
  },
  beforeDestroy() {},
};
</script>

<style lang="scss">

.el-breadcrumb {
  &__inner {
    img {
      height: 80px;
      object-fit: contain;
    }
  }
}

.report-detail-container {
  margin: 0;
  padding-top: 88px;
  box-shadow: inset 0px 30px 25px rgba(194, 194, 194, 0.25);
  background: #FBFBFB;

  .mx-auto {
    margin: 20px auto 0 auto !important;
  }

  .el-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .report-navbar {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    height: 145px;
    align-content: center;
    padding: 0 50px;

    @media (min-width: 720px) {
      height: 150px;
      padding: 10px 70px;
      grid-template-rows: auto auto;
      grid-template-columns: auto ;
      justify-items: center;
      row-gap: 10px;
    }
    @media (min-width: 1100px){
      height: 93px;
      column-gap: 20px;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
    }

    .report-bar {
      justify-self: end;
      align-items: center;
      display: flex;
      @media (min-width: 720px){
        justify-self: center;
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
    grid-gap: 20px;
  }

  .widget-type-label {
    margin: 15px 0;
  }

  .content-draggable {
    background-color: #fafafa;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px dashed #313131;
    border-radius: 6px;
    margin-right: 15px;
    min-height: 540px;
    position: relative;
  }

  .dragArea.list-group {
    width: 100%;
    height: 540px;
  }

  .widgetadd,
  .widgetList {
    position: relative;
    background: #f8f8f8;
    padding: 15px;
    margin: 5px;
    border-radius: 15px;
    display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background: #fff;
    border: 1px solid #333;

    img {
      object-fit: contain;
      width: 100%;
    }

    button {
      position: absolute;
      top: 10px;
      right: 20px;
      background: transparent;
      border: 0;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .report-view {
    .el-col {
      padding: 10px;
    }
  }

  .widgetAddImage {
    cursor: move;
  }

  .tabs-widgets {
    display: inline-grid;
    position: fixed;
    top: 45%;
    overflow: hidden;
    width: 33.5%;

    .el-tabs {
      background: #f8f8f8;
      border-radius: 6px;
    }

    .el-tabs--card > .el-tabs__header .el-tabs__nav {
      width: 100%;
      border: none;
    }

    .el-tabs--card > .el-tabs__header {
      border: none;
      background: #313131;
      border-radius: 0px 6px;
    }

    .el-tabs--card > .el-tabs__header .el-tabs__item {
      border: none;
      color: #ffff;
      font-weight: 700;
      text-align: center;
    }

    .el-tabs__item {
      width: 50%;
      border: none;
    }

    .el-tabs__item.is-active {
      color: #313131 !important;
      background: #f8f8f8 !important;
    }
  }

  .form-edit-report {
    overflow: auto;
  }

  .report-edit {
    .el-divider {
      &__text {
        background-color: #f8f8f8 !important;
        text-align: center;
      }
    }

    .close-edit-widget {
      text-align: end;
      margin: 20px;

      span {
        cursor: pointer;
        font-size: 20px;
      }
    }
  }

  .col-widgets {
    @media (max-width: 424px) {
      width: 100% !important;
    }
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.widget-scalar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0.565953px solid #dde4fb;
  box-shadow: 0px 2.82977px 11.3191px rgba(128, 112, 221, 0.05);
  border-radius: 2.82977px;

  .content-container {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-flow: row wrap;
    flex: 1;
    padding: 15px;
    min-width: 100%;
    column-gap: 25.5px;
    row-gap: 15px;

    .icon {
      display: flex;
      justify-content: center;
      align-content: space-around;
      align-items: center;
      flex-flow: row wrap;
      font-size: var(--icon-size);
      background: #f5f7ff;
      border-radius: 6.85714px;
      padding: 2px;
      height: 36px;
      width: 36px;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .text-container {
      position: relative;

      h1 {
        font-weight: 400;
        font-size: 30px !important;
        // line-height: 49px;
        letter-spacing: 0.02em;
        margin: 0;

        label {
          color: #b1b4b7;
          font-size: 24px;
          position: absolute;
          top: -8px;
          width: 100%;
        }
      }

      span {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 18.77px;
        color: #6c6e6f !important;
      }
    }
  }
}
.bar-chart{
  .col-widgets{
    border: 0.387283px solid #DDE4FB;
    box-shadow: 0px 0px 5.80925px rgba(77, 112, 245, 0.08);
    border-radius: 1.93642px;
    padding: 27px !important;
    margin: 0 10px;
  }
}

.paragraph{
  margin: 0 10px;
}

.filters {
  display: flex;
  gap: 10px;
}
</style>
