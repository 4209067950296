var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-detail-container"},[_c('div',[_c('div',{staticClass:"report-navbar",style:(`background:${_vm.widgetColor[0]}40;`),attrs:{"gutter":20}},[_c('breadcrumb-view',{attrs:{"items":_vm.breadcrumb}}),_c('report-bar',{attrs:{"bgColor":_vm.widgetColor[0],"data":{},"btnData":_vm.btnData}})],1),_c('el-row',{staticClass:"mx-auto container",attrs:{"gutter":20}},[_c('el-col',{staticClass:"col-widgets",attrs:{"span":24}},[[_c('h1',{style:(`color: ${_vm.fontColor};
          font-family: ${_vm.fontName};
          font-size: ${_vm.fontSizeText} !important`)},[_vm._v(" Filters ")]),_c('div',{staticClass:"filters"},[[_c('el-input',{attrs:{"readonly":"","clearable":"","size":"mini","placeholder":"Search by name, last name, email or lenguaje"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})])],[_c('el-date-picker',{attrs:{"readonly":"","type":"daterange","size":"mini","align":"right","unlink-panels":"","range-separator":"To","start-placeholder":"Start date","end-placeholder":"End date","value-format":"yyyy-MM-dd"}})]],2)]],2)],1),_c('el-divider',{staticClass:"mb-4 mt-4",attrs:{"content-position":"center"}}),_c('el-row',{staticClass:"mx-auto container",attrs:{"gutter":20}},[_c('el-col',{staticClass:"col-widgets",attrs:{"span":12}},[[_c('div',{staticClass:"widget-scalar",style:(`background: ${_vm.backgroundWidgetColor}`)},[_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"mdi mdi-account",style:(`color: ${_vm.fontColor};`)})]),_c('div',{staticClass:"text-container"},[_c('h1',{style:(`color: ${_vm.fontColor};
                  font-family: ${_vm.fontName};
                  font-size: ${_vm.fontSizeText} !important`)},[_vm._v(" 11,443 ")]),_c('span',{style:(`font-family: ${_vm.fontName};`)},[_vm._v("Total Entries")])])])])]],2),_c('el-col',{staticClass:"col-widgets",attrs:{"span":12}},[[_c('div',{staticClass:"widget-scalar",style:(`background: ${_vm.backgroundWidgetColor}`)},[_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"mdi mdi-account",style:(`color: ${_vm.fontColor};`)})]),_c('div',{staticClass:"text-container"},[_c('h1',{style:(`color: ${_vm.fontColor};
                font-family: ${_vm.fontName};
                font-size: ${_vm.fontSizeText} !important`)},[_vm._v(" 3,976 ")]),_c('span',{style:(`font-family: ${_vm.fontName};`)},[_vm._v("Total Optins")])])])])]],2)],1),_c('el-row',{staticClass:"mx-auto container",attrs:{"gutter":20}},[_c('el-col',{staticClass:"col-widgets",attrs:{"span":12}},[[_c('div',{staticClass:"widget-scalar",style:(`background: ${_vm.backgroundWidgetColor}`)},[_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"icon",style:(`color: ${_vm.fontColor};`)},[_c('i',{staticClass:"mdi mdi-account"})]),_c('div',{staticClass:"text-container"},[_c('h1',{style:(`color: ${_vm.fontColor};
                font-family: ${_vm.fontName};
                font-size: ${_vm.fontSizeText} !important`)},[_vm._v(" 123K ")]),_c('span',{style:(`font-family: ${_vm.fontName};`)},[_vm._v("Bonus Entries")])])])])]],2),_c('el-col',{staticClass:"col-widgets",attrs:{"span":12}},[[_c('div',{staticClass:"widget-scalar",style:(`background: ${_vm.backgroundWidgetColor}`)},[_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"mdi mdi-account",style:(`color: ${_vm.fontColor};`)})]),_c('div',{staticClass:"text-container"},[_c('h1',{style:(`color: ${_vm.fontColor};
                font-family: ${_vm.fontName};
                font-size: ${_vm.fontSizeText} !important`)},[_vm._v(" 55K ")]),_c('span',{style:(`font-family: ${_vm.fontName};`)},[_vm._v("Referral Entries")])])])])]],2)],1),_c('el-row',{staticClass:"mx-auto container",attrs:{"gutter":20}},[_c('el-col',{staticClass:"col-widgets paragraph",style:(`background: ${_vm.backgroundWidgetColor}`),attrs:{"span":24}},[[_c('h1',{style:(`color: ${_vm.fontColor};
          font-family: ${_vm.fontName};
          font-size: ${_vm.fontSizeText} !important`)},[_vm._v(" Entries ")]),_c('p',{style:(`font-family: ${_vm.fontName}; font-size: ${_vm.fontSizeText} !important`)},[_vm._v(" Laoreet neque in tellus sit. Volutpat et nec in duis urna tortor lorem a. Id amet dignissim. ")])]],2)],1),_c('el-row',{staticClass:"mx-auto container bar-chart",attrs:{"gutter":20}},[_c('el-col',{staticClass:"col-widgets",style:(`background: ${_vm.backgroundWidgetColor}`),attrs:{"span":24}},[[_c('h1',{style:(`color: ${_vm.fontColor};
          font-family: ${_vm.fontName};
          font-size: ${_vm.fontSizeText} !important`)},[_vm._v(" Top entries 10 ")]),_c('bar-chart',{attrs:{"stacked":true,"colors":[_vm.widgetColor],"data":_vm.chartData}})]],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }