<template>
  <div class="multi-origin-data">
    <div class="add-elements">
      <el-button
        @click="addElement"
        size="mini"
        type="primary"
        plain
        icon="el-icon-plus"
      >
      </el-button>
    </div>
    <div class="elements">
      <div class="blocks-config" v-for="(conf, index) in data" :key="conf.uuid">
        <el-collapse accordion>
          <el-collapse-item :name="index">
            <div class="items-head" slot="title">
              <div class="title">
                {{ conf.name ? conf.name : `Series ${index + 1}` }}
              </div>
              <div class="delete">
                <el-button
                  v-if="index !== 0"
                  @click.stop="deleteElement(index)"
                  size="mini"
                  plain
                  type="text"
                  icon="el-icon-delete-solid"
                >
                </el-button>
              </div>
            </div>
            <div class="config">
              <span class="demo-input-label">Name</span>
              <el-input
                @change="handleChangeValue()"
                type="text"
                placeholder="Please input name"
                v-model="conf.name"
                autocomplete="off"
              ></el-input>

              <span class="demo-input-label">Data Source</span>
              <el-select
                v-model="conf.dataSource"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Data Source"
              >
                <el-option
                  v-for="item in dataSources"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <template>
                <span class="demo-input-label">Query</span>
                <div class="btn-test-query">
                  <template>
                    <el-button
                      v-loading="loading"
                      @click="handleTestQuery(conf)"
                      size="mini"
                      type="info"
                      >Test Query</el-button
                    >
                  </template>
                </div>
                <!--
                <el-input
                  @change="handleChangeValue()"
                  type="textarea"
                  placeholder="Please input query"
                  v-model="conf.query"
                  autocomplete="off"
                  :rows="5"
                ></el-input> -->
                <vue-monaco-editor
                  @change="handleChangeValue()"
                  :height="300"
                  v-model="conf.query"
                  @input="conf.query = $event"
                  theme="vs-dark"
                  language="sql"
                  :options="{
                    detectIndentation: true,
                    automaticLayout: true,
                    formatOnType: true,
                    formatOnPaste: true,

                    autoIndent: 'full',
                    automaticLayout: true,
                    contextmenu: true,
                    fontFamily: 'monospace',
                    fontSize: 13,
                    lineHeight: 24,
                    hideCursorInOverviewRuler: true,
                    matchBrackets: 'always',
                    renderIndentGuides: true,
                    minimap: {
                      enabled: false,
                    },
                    autoIndent: 'full',
                    tabSize: 4,
                    cursorStyle: 'underline',
                    trimAutoWhitespace: 'true',
                  }"
                  @mounted="handleMount"
                />
              </template>

              <span class="demo-input-label">Group By</span>
              <custom-tags
                @change="handleChangeValue()"
                v-model="conf.groupBy"
                type="tag_text"
              ></custom-tags>

              <span class="demo-input-label">Order By</span>
              <custom-tags
                @change="handleChangeValue()"
                v-model="conf.orderBy"
                type="tag_text"
              ></custom-tags>

              <span class="demo-input-label">Key (Column)</span>
              <el-input
                @change="handleChangeValue()"
                type="text"
                placeholder="Please input key"
                v-model="conf.key"
                autocomplete="off"
              ></el-input>

              <span class="demo-input-label">Value (Column)</span>
              <el-input
                @change="handleChangeValue()"
                type="text"
                placeholder="Please input value"
                v-model="conf.value"
                autocomplete="off"
              ></el-input>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <el-dialog
      class="show-query"
      :close-on-press-escape="false"
      :lock-scroll="false"
      title="Result"
      :visible.sync="showModalTestQuery"
      width="40%"
      :before-close="handleCloseModalTestQuery">
      <div class="showResultQuery scroll-gray">
        <highlight-code lang="json">
          {{ dataTestQuery }}
        </highlight-code>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showModalTestQuery = false">OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiMILO from '@/lib/ApiMILO';
import CustomTags from '@/components/Tags/CustomTags.vue';

export default {
  name: 'MultiOriginData',
  props: ['value', 'brandId'],
  components: {
    CustomTags,
  },
  data() {
    return {
      showModalTestQuery: false,
      dataTestQuery: {},
      editorRef: null,
      loading: false,
      currentVal: null,
      dataSources: {},
      data: [
        {
          name: '',
          dataSource: '',
          query: '',
          value: '',
          key: '',
          groupBy: '',
          orderBy: '',
        },
      ],
    };
  },
  watch: {
    currentVal(newValue) {
      this.$emit('input', newValue);
    },
    data(newValue) {
      this.currentVal = JSON.stringify(newValue);
    },
    showModalTestQuery(value) {
      if (value) {
        const modal = document.querySelector('.show-query');
        if (modal) {
          setTimeout(() => {
            const outerModal = document.querySelector('.v-modal');
            outerModal.style.zIndex = 8;
          }, 1000);
        }
      }
    },
  },
  methods: {
    handleCloseModalTestQuery(done) {
      this.dataTestQuery = {};
      done();
    },
    handleMount(editor) {
      this.editorRef = editor;
    },
    formatCode() {
      this.editorRef?.getAction('editor.action.formatDocument').run();
    },
    async handleTestQuery(data) {
      this.loading = true;

      const payload = {
        dataSource: data.dataSource,
        query: data.query,
      };

      this.dataTestQuery = await this.getQuery(payload);

      this.loading = false;

      this.showModalTestQuery = true;
    },
    async getQuery(data) {
      try {
        const res = await ApiMILO.post('/widgets/getQuery', data);
        return res.data ?? [];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getDataSources() {
      try {
        const res = await ApiMILO.get(`/brands/${this.brandId}/datasources`);
        return res.data ?? [];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    addElement() {
      this.data.push({
        name: '',
        dataSource: '',
        query: '',
        value: '',
        key: '',
        groupBy: '',
        orderBy: '',
      });
    },
    deleteElement(index) {
      this.data.splice(index, 1);
    },
    handleChangeValue() {
      this.currentVal = JSON.stringify(this.data);
    },
  },
  async mounted() {
    const { value } = this;
    this.currentVal = value;
    if (value) {
      this.data = JSON.parse(value);
    }

    this.dataSources = await this.getDataSources();
  },
};
</script>

<style lang="scss">
.multi-origin-data {
  .add-elements {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .elements {
    .blocks-config {
      padding-bottom: 5px;
      .el-collapse-item {
        border: solid 1px #dcdfe6;
        &__header {
          padding: 0 0 0 15px;
          height: auto;
          .items-head {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .delete {
              button {
                border: none;
                font-size: 15px;
                color: red;
                padding-right: 5px;
                :hover {
                  opacity: 0.5;
                }
              }
            }
          }
        }
        &__content {
          padding-bottom: 0;
        }
      }
      .config {
        display: grid;
        row-gap: 5px;
        padding: 15px;
      }
    }
  }
}
</style>
