<!-- eslint-disable no-param-reassign -->
<template>
  <div class="report-settings-config">
    <div class="add-elements">

      <el-button
        v-loading="loading"
        @click="showModalUpdateConfig = !showModalUpdateConfig"
        size="mini"
        type="primary"
      >Update Config</el-button>

      <el-button
        @click="addElement"
        size="mini"
        type="primary"
        plain
        icon="el-icon-plus">
      </el-button>
    </div>
    <div class="elements">
      <div class="blocks-config" v-for="(conf, index) in data" :key="conf.uuid">
        <el-collapse accordion>
          <el-collapse-item :name="index">
            <div class="items-head" slot="title">
              <div class="title">
                Config - {{ JSON.stringify(conf.columnDestination) ?? index }}
              </div>
              <div class="delete">
                <el-button
                  @click.stop="deleteElement(index)"
                  size="mini"
                  plain
                  type="text"
                  icon="el-icon-delete-solid">
                </el-button>
              </div>
            </div>
            <div class="config" >
              <span class="demo-input-label">Data Source Origin</span>
              <el-select
                v-model="conf.dataSourceOrigin"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Origin">
                  <el-option
                    v-for="item in dataSources"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>

              <span class="demo-input-label">Data Source Destination</span>
              <el-select
                v-model="conf.dataSourceDestination"
                @change="handleGetTables(conf, index)"
                clearable
                filterable
                placeholder="Select Destination">
                  <el-option
                    v-for="item in dataSources"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>

              <span class="demo-input-label">Table Destination</span>
              <el-select
                :loading="loading"
                v-model="conf.tableDestination"
                @change="handleGetColumns(conf, index)"
                clearable
                filterable
                placeholder="Select Destination">
                  <el-option
                    v-for="item in tables[index]"
                    :key="item.tables"
                    :label="item.tables"
                    :value="item.tables">
                  </el-option>
              </el-select>

              <span class="demo-input-label">Column Destination</span>
              <el-select
                :loading="loading"
                v-model="conf.columnDestination"
                @change="handleChangeValue()"
                clearable
                multiple
                filterable
                placeholder="Select Destination">
                  <el-option
                    v-for="item in columns[index]"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>

              <span class="demo-input-label">Query</span>

              <div class="btn-test-query">
                <el-button
                  v-loading="loading"
                  @click="handleTestQuery(conf)"
                  size="mini"
                  type="info"
                >Test Query</el-button>
              </div>

              <!-- <el-input
                @change="handleChangeValue()"
                type="textarea"
                :rows="4"
                placeholder="Please input query"
                v-model="conf.query">
              </el-input> -->

              <vue-monaco-editor
                @change="handleChangeValue()"
                :height="300"
                v-model="conf.query"
                @input="conf.query = $event"
                theme="vs-dark"
                language="sql"
                :options="{
                  detectIndentation: true,
                  automaticLayout: true,
                  formatOnType: true,
                  formatOnPaste: true,
                  minimap: {
                    enabled: false,
                  },
                  autoIndent: 'full',
                  tabSize: 4,
                  cursorStyle: 'underline',
                  trimAutoWhitespace: 'true',
                }"
                @mounted="handleMount"
              />

            </div>
            </el-collapse-item>
          </el-collapse>
      </div>
    </div>

    <el-dialog
      class="show-query"
      :close-on-press-escape="false"
      :lock-scroll="false"
      title="Result"
      :visible.sync="showModalTestQuery"
      width="40%"
      :before-close="handleCloseModalTestQuery">
      <div class="showResultQuery scroll-gray">
        <highlight-code lang="json">
          {{ dataTestQuery }}
        </highlight-code>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showModalTestQuery = false">OK</el-button>
      </div>
    </el-dialog>

    <el-dialog
      class="show-update-modal-config"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :lock-scroll="false"
      title="Update Config"
      :visible.sync="showModalUpdateConfig"
      width="40%"
      :before-close="handleCloseModalUpdateConfig">

      <div class="configuration">
        <span class="demo-input-label">Data Source Origin</span>
              <el-select
                v-model="confUpdate.dataSourceOrigin"
                @change="handleChangeConfUpdate('dataSourceOrigin')"
                clearable
                filterable
                placeholder="Select Origin">
                  <el-option
                    v-for="item in dataSources"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>

              <span class="demo-input-label">Data Source Destination</span>
              <el-select
                v-model="confUpdate.dataSourceDestination"
                @change="handleChangeConfUpdate('dataSourceDestination')"
                clearable
                filterable
                placeholder="Select Destination">
                  <el-option
                    v-for="item in dataSources"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
      </div>

      <div class="showModalUpdateConfig  scroll-gray">
        <highlight-code lang="json">
          {{ JSON.parse(currentVal) }}
        </highlight-code>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showModalUpdateConfig = false">Save</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiMILO from '@/lib/ApiMILO';

export default {
  name: 'ReportSettingsConfig',
  props: ['value', 'brandId'],
  data() {
    return {
      editorRef: null,
      confUpdate: {},
      showModalTestQuery: false,
      showModalUpdateConfig: false,
      dataTestQuery: {},
      loading: false,
      currentVal: null,
      dataSources: {},
      tables: [],
      columns: [],
      data: [
        {
          dataSourceOrigin: '',
          dataSourceDestination: '',
          tableDestination: '',
          columnDestination: '',
          query: '',
        },
      ],
    };
  },
  watch: {
    currentVal(newValue) {
      this.$emit('input', newValue);
    },
    data(newValue) {
      this.currentVal = JSON.stringify(newValue);
    },
    showModalTestQuery(value) {
      if (value) {
        const modal = document.querySelector('.show-query');
        if (modal) {
          setTimeout(() => {
            const outerModal = document.querySelector('.v-modal');
            outerModal.style.zIndex = 8;
          }, 1000);
        }
      }
    },
    showModalUpdateConfig(value) {
      if (value) {
        const modal = document.querySelector('.show-update-modal-config');
        if (modal) {
          setTimeout(() => {
            const outerModal = document.querySelector('.v-modal');
            outerModal.style.zIndex = 8;
          }, 1000);
        }
      }
    },
  },
  methods: {
    handleMount(editor) {
      this.editorRef = editor;
    },
    formatCode() {
      this.editorRef?.getAction('editor.action.formatDocument').run();
    },
    async handleChangeConfUpdate(field) {
      const { confUpdate, data } = this;

      const newObjUpdated = await Promise.all(data.map(async (item, index) => {
        const updatedItem = { ...item }; // Crear una copia del objeto original

        updatedItem[field] = confUpdate[field];

        if (field === 'dataSourceDestination' && confUpdate[field]) {
          updatedItem.tableDestination = null;
          updatedItem.columnDestination = [];
          await this.handleGetTables(updatedItem, index);
          await this.handleGetColumns(updatedItem, index);
        }

        return updatedItem;
      }));

      this.data = newObjUpdated;
    },
    async getDataSources() {
      try {
        const res = await ApiMILO.get(`/brands/${this.brandId}/datasources`);
        return res.data ?? [];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getQuery(data) {
      try {
        const res = await ApiMILO.post('/widgets/getQuery', data);
        return res.data ?? [];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async handleTestQuery(data) {
      this.loading = true;

      const payload = {
        dataSource: data.dataSourceOrigin,
        query: data.query,
      };

      this.dataTestQuery = await this.getQuery(payload);

      this.loading = false;

      this.showModalTestQuery = true;
    },
    handleCloseModalTestQuery(done) {
      this.dataTestQuery = {};
      done();
    },
    handleCloseModalUpdateConfig(done) {
      this.confUpdate = {};
      done();
    },
    addElement() {
      this.data.push({
        dataSourceOrigin: '',
        dataSourceDestination: '',
        tableDestination: '',
        query: '',
      });
    },
    deleteElement(index) {
      this.data.splice(index, 1);
    },
    handleChangeValue() {
      this.currentVal = JSON.stringify(this.data);
    },
    async handleGetTables(conf, index) {
      if (conf?.dataSourceDestination) {
        this.$set(this.tables, index, await this.getTables(conf));
      } else {
        this.$set(this.tables, index, null);
        this.data[index].tableDestination = '';
      }
      this.handleChangeValue();
    },
    async handleGetColumns(conf, index) {
      if (conf?.tableDestination) {
        const newColumns = await this.getColumns(conf);
        this.$set(this.columns, index, newColumns);
      } else {
        this.$set(this.columns, index, null);
        this.data[index].columnDestination = '';
      }
      this.handleChangeValue();
    },
    async getTables(conf) {
      this.loading = true;
      try {
        const res = await ApiMILO.get(`/brands/${this.brandId}/datasources/${conf.dataSourceDestination}/tables`);
        return res.data;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        this.loading = false;
      }
    },
    async getColumns(conf) {
      this.loading = true;
      try {
        const res = await ApiMILO.get(`/brands/${this.brandId}/datasources/${conf.dataSourceDestination}/table/${conf.tableDestination}/columns`);
        return res.data;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    const { value } = this;
    this.currentVal = value;
    if (value) {
      const data = JSON.parse(value);
      const cleanEtls = data.map((el) => {
        const copy = structuredClone(el);
        copy.columnDestination = (Array.isArray(copy.columnDestination))
          ? copy.columnDestination : [copy.columnDestination];
        return copy;
      });
      this.data = cleanEtls;
    }

    this.dataSources = await this.getDataSources();
  },
};
</script>

<style lang="scss">
  .report-settings-config {
    .add-elements {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }
    .elements {
      .blocks-config {
        padding-bottom: 5px;
        .el-collapse-item{
          border: solid 1px #DCDFE6;
          &__header{
            padding: 0 0 0 15px;
            height: auto;
            .items-head{
              display: flex;
              width: 100%;
              justify-content: space-between;
              .delete{
                button{
                  border: none;
                  font-size: 15px;
                  color: red;
                  padding-right: 5px;
                  :hover{
                    opacity: 0.5;
                  }
                }
              }
            }
          }
          &__content{
            padding-bottom: 0;
          }
        }
        .config {
          display: grid;
          row-gap: 5px;
          padding: 15px;
        }
      }
    }

    .el-dialog{
      padding: 40px !important;
    }

    .showResultQuery, .showModalUpdateConfig{
      max-height: 300px;
      overflow-x: scroll;
      color: #fff;
      // background: #202124;
      font-size: 12px;
      line-height: normal;
    }

    .dialog-footer{
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
