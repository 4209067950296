<template>
  <div class="widget-cohort" :style="cssVars">
    <div class="content-tooltip">
        <h1>{{label}}</h1>
        <el-tooltip
          v-if="tooltipContent.length > 0"
          popper-class="content-tooltip-width"
          class="item"
          effect="dark"
          :content="tooltipContent"
          placement="bottom">
          <img :src="`${URLSITE}/img/circle-question.svg`" alt="tooltip" />
        </el-tooltip>
      </div>

    <div v-loading="loading" class="widget-cohort-graph scroll-gray">
      <table v-if="cohortData?.hours">
        <thead>
        <tr>
          <th>Dates / Hours</th>
          <th v-for="index in 24" :key="index.uuid"> Hour {{ index - 1 }}</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(date, index) in cohortData.hours" :key="date.uuid">
          <td>{{ index }}</td>
          <td
              :style="'background-color: ' + shadeCellWithColor(count)"
              v-for="count in cohortData.hours[index]"
              :key="count.uuid"
          >{{ count }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Utils from '@/lib/Utils';
import ApiMILO from '@/lib/ApiMILO';
import CohortGraph from 'cohort-graph';
import chroma from 'chroma-js';

export default {
  name: 'WidgetCohort',
  props: ['config', 'brandSettings', 'initialFilterConfig'],
  data() {
    return {
      CohortGraph,
      data: null,
      loading: true,
      apiConfig: {},
      automaticListenerFunction: null,
      externalListenerFunction: null,
      URLSITE: process.env.VUE_APP_URL_SITE,
    };
  },
  computed: {
    tooltipContent() {
      return Utils.getSetting('general', 'tooltip', this.config.widget_settings_categories);
    },
    cssVars() {
      return {
        '--default-widget-background': this.default_widget_background,
      };
    },
    default_widget_background() {
      return Utils.getReportBrandSetting('default_widget_background', this.brandSettings) ?? 'transparent';
    },
    label() {
      return Utils.getSetting('general', 'label', this.config.widget_settings_categories);
    },
    height() {
      return Utils.getSetting('general', 'height', this.config.widget_settings_categories);
    },
    widgetColor() {
      const color = Utils.getSetting('data', 'widget_color', this.config.widget_settings_categories);

      if (!color) {
        const defaultColor = Utils.getReportBrandSetting('default_widget_color', this.brandSettings);
        return [defaultColor];
      }

      return color.split('||');
    },
    cohortData() {
      if (!this.data) {
        return this.defaultResponse;
      }
      const { data } = this.data;

      if (!data) {
        return this.defaultResponse;
      }

      const reducedData = data.reduce((accum, currentVal) => {
        if (!accum[currentVal.date]) {
          // eslint-disable-next-line no-param-reassign
          accum[currentVal.date] = [currentVal];
        } else {
          // eslint-disable-next-line no-param-reassign
          accum[currentVal.date] = [...accum[currentVal.date], currentVal];
        }
        return accum;
      }, {});

      const dataChart = Object.entries(reducedData).reduce((accum, [key, value]) => {
        const arrayAlgo = new Array(24).fill(0);

        value.forEach((element) => {
          const position = parseInt(element.hour.split(':')[0], 10);
          arrayAlgo[position] = element.total;
        });

        // eslint-disable-next-line no-param-reassign
        accum[key] = arrayAlgo;
        return accum;
      }, {});

      return { hours: { ...dataChart } };
    },
  },
  methods: {
    shadeCellWithColor(count) {
      // eslint-disable-next-line prefer-rest-params
      const color = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '#299bc9';

      const rate = count / (count < 100 ? 100 : 1000);

      const chromaColor = chroma(color);

      return chromaColor.alpha(rate);
    },
    async getWidgetData() {
      this.loading = true;
      try {
        const { id } = this.config;
        this.apiConfig.apiUrl = `/widgets/${id}/data?`;

        const apiUrl = Utils.queryStringGenerator(this.apiConfig);
        const result = await ApiMILO.get(apiUrl);

        const { success } = result;

        if (!success) {
          return this.defaultResponse;
        }

        return result;
      } catch (error) {
        console.error(error);
        this.loading = false;
        return this.defaultResponse;
      } finally {
        this.loading = false;
      }
    },
    async reloadData(conf) {
      if (conf.type === 'dropDown' || conf.type === 'check') {
        let fields = [];
        let s = '';
        if (conf.columns) {
          fields = conf.columns;
          if (Array.isArray(fields) && conf.value) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }
          this.apiConfig[conf.type] = s;
          this.data = await this.getWidgetData();
        }
      }

      if (conf.type === 'search') {
        const search = await Utils.getSetting('filters', 'search', this.config.widget_settings_categories);
        if (!search || search.length === 0) {
          console.warn('No valid search column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (search) {
          fields = search.split('||');
          if (Array.isArray(fields) && conf.value.length > 0) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }

          this.apiConfig.search = s;
          this.data = await this.getWidgetData();
        }
      }

      if (conf.type === 'dateRange') {
        const dateRange = Array.isArray(conf.value) ? conf.value.toString().replaceAll(',', '&&') : '';

        let dateRangeColumns = await Utils.getSetting('filters', 'date_range', this.config.widget_settings_categories);
        dateRangeColumns = conf?.columns ?? dateRangeColumns;
        if (!dateRangeColumns || dateRangeColumns.length === 0) {
          console.warn('No valid dateRange column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (dateRangeColumns) {
          if (!Array.isArray(dateRangeColumns)) {
            fields = dateRangeColumns.split('||');
          }
          if (conf?.columns) {
            fields = [...new Set([...fields, ...conf.columns])];
          } else {
            fields = [...new Set([...fields])];
          }

          if (Array.isArray(fields) && dateRange.length > 0) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${dateRange}${separator}`;

              return field;
            });
          }

          this.apiConfig.dateRange = s;
        }
        this.data = await this.getWidgetData();
      }

      if (conf.type === 'optIn') {
        const optIn = await Utils.getSetting('filters', 'opt_in', this.config.widget_settings_categories);
        if (!optIn || optIn.length === 0) {
          console.warn('No valid optIn column specified!');
          return;
        }
        let fields = [];
        let s = '';
        if (optIn) {
          fields = optIn.split('||');
          if (Array.isArray(fields) && conf.value) {
            fields.map((field, index) => {
              let separator = '';
              if (index < fields.length - 1) {
                separator = '||';
              }
              s += `${field}::${conf.value}${separator}`;

              return field;
            });
          }

          this.apiConfig.optIn = s;
          this.data = await this.getWidgetData();
        }
      }
    },
    async setupAutomaticRefresh() {
      const allow = Utils.getSetting('events', 'allow_automatic_refresh', this.config.widget_settings_categories);
      if (allow) {
        this.automaticListenerFunction = async () => {
          await this.getWidgetData();
        };
        this.$root.$on('reload-widget-data', this.automaticListenerFunction);
      }
    },
    async setupExternalEventListener() {
      const allow = Utils.getSetting('events', 'allow_external_refresh', this.config.widget_settings_categories);
      if (allow) {
        const self = this;
        this.externalListenerFunction = async function reload() {
          await self.getWidgetData();
        };
        this.$listen(window, 'reload-milo', this.externalListenerFunction);
      }
    },
  },
  beforeDestroy() {
    try {
      if (this.automaticListenerFunction != null) {
        this.$root.$off('reload-widget-data', this.automaticListenerFunction);
      }
      this.$root.$off('applyFilters', this.reloadData);
    } catch (e) {
      console.error(e);
    }
  },
  async mounted() {
    this.apiConfig.orderBy = await Utils.getSetting('data', 'orderBy', this.config.widget_settings_categories);
    this.apiConfig.groupBy = await Utils.getSetting('data', 'groupBy', this.config.widget_settings_categories);

    if (this.initialFilterConfig) {
      this.reloadData(this.initialFilterConfig);
    } else {
      this.data = await this.getWidgetData();
    }
    this.$emit('dataRefresh', this.config.id);
    // this.chartOptions.colorAxis.colors = this.widgetColor;

    this.$root.$on('applyFilters', this.reloadData);
    this.setupAutomaticRefresh();
    this.setupExternalEventListener();
  },
};
</script>

<style lang="scss">
.widget-cohort-graph {
  overflow: auto;
  height: 300px;

  table, th, td {
    border: 1px solid #E1E7FF;
    color: #6C6E6F;
  }

  thead {
    background: #F8FDFF;
  }

  th {
    width: fit-content;
    padding: 5px 15px;
    min-width: 100px;
  }

  td {
    text-align: center;
    padding: 5px;
  }
}
</style>
