<template>
  <div class="profile">
    <div class="profile-navbar">
      <breadcrumb-view :items="breadcrumb"></breadcrumb-view>
    </div>
    <div class="el-container container">
      <!-- <el-tabs v-model="activeName">
        <el-tab-pane label="General" name="general">
          <div class="content-upload">
            <edit-profile></edit-profile>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Authentication" name="authentication">
          <manage-twofa></manage-twofa>
        </el-tab-pane>
      </el-tabs> -->
      <iframe
        :src="urlProfile"
        width="100%"
        height="1000px"
        frameborder="0"
        title="Profile Information"
      ></iframe>
    </div>
  </div>
</template>

<script>
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
// import ManageTwofa from '@/components/user/ManageTwofa.vue';
// import EditProfile from '@/components/user/EditProfile.vue';
import SSOApi from '@debrain/sso-api-integration';
import { mapState } from 'vuex';

export default {
  name: 'ProfileView',
  components: {
    BreadcrumbView,
    // ManageTwofa,
    // EditProfile,
  },
  data() {
    return {
      urlProfile: null,
      breadcrumb: [
        {
          string: 'Profile',
          active: true,
        },
      ],
      activeName: 'general',
    };
  },
  computed: {
    ...mapState(['token']),
  },
  methods: {
    // handleClick(tab, event) {
    //   console.log(tab, event);
    // },
  },
  mounted() {
    SSOApi.configure({
      appId: process.env.VUE_APP_ID_SSO,
      apiKey: process.env.VUE_APP_API_KEY,
      baseSSOURL: process.env.VUE_APP_BASE_SSO_URL,
      baseSSOAPIURL: process.env.VUE_APP_API_SSO,
    });

    this.urlProfile = SSOApi.getProfileURL({
      state: window.btoa(window.location),
      redirecturl: process.env.VUE_APP_REDIRECT_URL,
      token: this.token,
    });
  },
};
</script>

<style lang="scss">
.profile {
  .profile-navbar {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    height: 130px;
    align-content: center;
    padding: 0 80px;
    @media (min-width: 720px) {
      padding: 0 60px;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
    }

    .container-breadcrumb span.el-breadcrumb__item.active span {
      color: #3FB5E5 !important;
    }
  }

  .el-tabs.el-tabs--top {
    width: 100%;
    @media (min-width: 720px) {
      width: 697px;
    }
  }

  .el-tabs--top .el-tabs__item.is-top {
    padding-left: 30px;
    padding-right: 30px;
  }

  .el-tabs__item.is-active {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    color: #3FB5E5;
    border-bottom: 4px solid #3FB5E5;
  }

  .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #D3D3D3;
  }

  .el-tabs__active-bar {
    background: transparent;
  }

  .el-tabs--top .el-tabs__item.is-top:last-child {
    padding: 0 20px;
  }

  .custom-upload {
    background: #ECECEC;
    padding: 15px;
    border-radius: 4.3445px;
  }
}
</style>
